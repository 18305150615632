import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'

@Injectable({
    providedIn: 'root'
})
export class BookmarkService {
    private readonly myWorkOrderUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/WorkOrders/Me`
    private readonly bookmarkUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Bookmarks`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getMyToDos(): Observable<WorkOrderListItem[]> {
        return this.queryBuilderService
            .get<WorkOrderListItem[]>(`${this.myWorkOrderUrl}`)
            .pipe(map(response => response.body))
    }

    public addBookmark(workOrderNumber: string): Observable<boolean> {
        return this.queryBuilderService
            .post<boolean>(`${this.bookmarkUrl}(${workOrderNumber})`)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }

    public addAllBookmarks(workOrderNumbers: string[]): Observable<boolean> {
        return this.queryBuilderService
            .post<boolean>(`${this.bookmarkUrl}/BulkAdd`, workOrderNumbers)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }

    public deleteBookmark(workOrderNumber: string): Observable<boolean> {
        return this.queryBuilderService
            .delete<boolean>(`${this.bookmarkUrl}(${workOrderNumber})`)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }

    public deleteAllBookmarks(workOrderNumbers: string[]): Observable<boolean> {
        return this.queryBuilderService
            .deleteBookmarks<boolean>(`${this.bookmarkUrl}/BulkDelete`, workOrderNumbers)
            .pipe(map(response => {
                if (response.status === 204) {
                    return true
                }
                return false
            }))
    }
}
