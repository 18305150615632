import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators'

import { APIPagedListDataSource } from '@app/modules/shared/components/api-paged-list/api-paged-list.component'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { AppState } from '@app/store/app.store'
import { GetEquipmentListAction } from '@app/store/equipment/actions/equipment-list.actions'
import { equipmentListQuery } from '@app/store/equipment/selectors/equipment-list.selectors'
import { deepCopy } from '@app/utils/app-utils.function'
import { AppSettings } from '@settings/app.settings'
import { equipmentListDefaultQuery as defaultEquipmentListQuery } from '../constants/equipment-default-query.constant'
import { EquipmentQueryParameter } from '../models/equipment-query-parameter.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentListService {
    public query: EquipmentQueryParameter
    public currentPage: number
    public itemPerPage: number
    private currentApiPage = 0
    private previousQuery: EquipmentQueryParameter
    private getEquipmentList$ = new Subject<{ apiQuery: any, queryChanged: any }>()

    constructor(private store: Store<AppState>) {
        this.query = deepCopy(defaultEquipmentListQuery)
        this.previousQuery = deepCopy(this.query)
        this.getEquipmentList$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            throttleTime(1000)
        ).subscribe(({ apiQuery, queryChanged }) => {
            this.store.dispatch(new GetEquipmentListAction(apiQuery, queryChanged))
        })
    }

    public clearQuery(): void {
        this.query = deepCopy(defaultEquipmentListQuery)
    }

    public updateSorting(column: string): void {
        if (this.query.sortBy === column) {
            this.query.isDesc = !this.query.isDesc
        } else {
            this.query.sortBy = column
            this.query.isDesc = false
        }
    }

    public compareEquipmentFetch(query: EquipmentQueryParameter, currentPage: number, itemPerPage: number): void {
        const newQuery: QuerySummary<EquipmentQueryParameter> = {
            queryParameters: query,
            currentPage,
            itemPerPage: itemPerPage?.toString()
        }
        this.store.dispatch(new GetEquipmentListAction(newQuery, false))
    }

    public pagedListDataSource: APIPagedListDataSource = (offset, pageSize) => {
        this.currentPage = Math.floor(offset / pageSize) + 1
        this.itemPerPage = pageSize

        const query = deepCopy(this.query)
        const queryChanged = JSON.stringify(this.query) !== JSON.stringify(this.previousQuery)
        const expectedApiPage = Math.ceil(this.currentPage * this.itemPerPage / AppSettings.equipmentListTotalRowPerPage)

        const cacheQuery: QuerySummary<EquipmentQueryParameter> = {
            queryParameters: query,
            currentPage: this.currentPage,
            itemPerPage: this.itemPerPage?.toString()
        }

        if (expectedApiPage > this.currentApiPage || queryChanged) {
            const apiQuery: QuerySummary<EquipmentQueryParameter> = {
                queryParameters: query,
                currentPage: expectedApiPage,
                itemPerPage: AppSettings.equipmentListTotalRowPerPage.toString()
            }

            this.previousQuery = query
            this.currentApiPage = expectedApiPage
            this.getEquipmentList$.next({ apiQuery, queryChanged })
        }

        return this.store.select(equipmentListQuery, cacheQuery)
    }

    public isUsingDefaultQuery(): boolean {
        return JSON.stringify(this.query) === JSON.stringify(defaultEquipmentListQuery)
    }
}
