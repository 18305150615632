import { Injectable } from '@angular/core'
import { ModalType, ModalContentType } from '../models/modal.model'

@Injectable({
    providedIn: 'root'
})
export class ModalContainerService {
    private modalRegistry: ModalContentType[] = []
    public registerComponent(component: any, modalType: ModalType): void {
        if (!this.modalRegistry.find(currentModal => currentModal.modalType === modalType)) {
            this.modalRegistry.push({
                component,
                modalType
            } as ModalContentType)
        }
    }

    public getComponent(modalType: ModalType): any {
        return this.modalRegistry.find(type => type.modalType === modalType).component
    }
}
