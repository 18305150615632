import { AcknowledgeTipAction, SkipFeatureTipsAction, TooltipAction } from './tooltip.actions'

export interface TooltipAcknowledgeState {
    [tipName: string]: number
}

const initialState: TooltipAcknowledgeState = {}

export function reducer(state = initialState, action: TooltipAction): TooltipAcknowledgeState {
    switch (action.type) {
        case AcknowledgeTipAction.type: {
            return {
                ...state,
                [action['featureName']]: action['tipIndex']
            }
        }
        case SkipFeatureTipsAction.type: {
            return {
                ...state,
                // just a number to make it impossible to reach this index, so it no longer renders any tip of this feature
                [action['featureName']]: -2
            }
        }
        default:
            return state
    }
}
