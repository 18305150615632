import { IdentityAction, ActionType } from '@app/store/identity/identity.actions'
import { UserProfile } from '@app/models/user.model'

export interface IdentityState {
    currentUser: UserProfile
    isAuthenticated: boolean
}

const initialState: IdentityState = {
    currentUser: undefined,
    isAuthenticated: undefined
}

export function reducer(state = initialState, action: IdentityAction): IdentityState {
    switch (action.type) {
        case ActionType.GetCurrentUserSuccess:
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: true
            }
        case ActionType.GetCurrentUserFailure:
            return {
                ...state,
                currentUser: undefined,
                isAuthenticated: false
            }
        default:
            return state
    }
}
