import { Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { AdminFormRowComponent } from '../admin-form-row/admin-form-row.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe, NgClass } from '@angular/common'


@Component({
    selector: 'app-pass-fail-check',
    templateUrl: './pass-fail-check.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        AdminFormRowComponent,
        DropdownComponent,
        AsyncPipe,
        NgClass
    ]
})
export class PassFailCheckComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public zeroPointTemplateForm: UntypedFormGroup
    public processes$: Observable<Process[]>
    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails

    constructor(private store: Store<AppState>, private formBuilder: UntypedFormBuilder) {
        super()
    }

    ngOnInit(): void {
        this.processes$ = this.store.select(process)
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initTemplateData()
        this.watchMainFormControl()
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.zeroPointTemplateForm.valueChanges.subscribe(form => {
                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(form))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    this.templateDetails,
                    true
                ))
            })
        )
    }

    private initTemplateData(): void {
        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )
            this.zeroPointTemplateForm = this.formBuilder.group({
                process: {
                    id: (this.templateDetails?.processId ?? 1),
                    name: (this.templateDetails?.processName ?? 'Generic')
                },
                alias: (this.templateDetails?.detail?.alias ?? ''),
                procedure: (this.templateDetails?.detail?.procedure ?? '')
            })

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.zeroPointTemplateForm.value
            ))

            if (!this.isAdmin) {
                this.zeroPointTemplateForm.disable()
            }
        })
    }
}
