export class UnitOfMeasurement {
    uomCode: string
    uomName: string
    uomCodeForTech: string
    uomGroupName: string
}

/**
 * Alias of UnitOfMeasurement
 */
export type UOM = UnitOfMeasurement
