import { Injectable } from '@angular/core'

import { isEmptyObject, isNotAValue } from '@app/utils/app-utils.function'
import { MultiPointAdminFormRawData } from '../../models/multi-point-admin-form-raw-data'

@Injectable({
    providedIn: 'root'
})
export class MultiPointValidationService {

    public isValid(model: MultiPointAdminFormRawData): boolean {
        if (!model.process ||
            !model.numberOfPoints.point ||
            !model.setPoints) {
            return false
        }

        if (model.isSetPointAdjustable === 'true' && model.isExpectedReadingEdited === 'true') {
            return false
        }

        const isInvalid = model.setPoints.some(_setPoint => {

            if (!_setPoint) {
                return true
            }

            const { isSetPointAdjustable, isExpectedReadingEdited } = _setPoint
            if (isSetPointAdjustable && isExpectedReadingEdited) {
                // These two can't be enabled at the same time
                return true
            }

            const { setPoint, expectedReading, tolerance } = _setPoint

            return (
                !setPoint ||
                isNotAValue(setPoint.value) ||
                !setPoint.unitOfMeasurement ||
                isEmptyObject(setPoint.unitOfMeasurement) ||

                !expectedReading ||
                isNotAValue(expectedReading.value) ||
                !expectedReading.unitOfMeasurement ||
                isEmptyObject(expectedReading.unitOfMeasurement) ||

                !tolerance ||
                isNotAValue(tolerance.lowerRange) ||
                isNotAValue(tolerance.higherRange) ||
                !tolerance.unitOfMeasurement ||
                isEmptyObject(tolerance.unitOfMeasurement)
            )

        })

        return !isInvalid
    }
}
