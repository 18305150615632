import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { ActionType, UOMAction } from '../actions/uom.actions'

export interface UOMState {
    UOMList: UOM[]
}

export const initialState: UOMState = {
    UOMList: [{
        uomCode: '',
        uomName: '',
        uomGroupName: '',
        uomCodeForTech: ''
    }]
}

export function reducer(state = initialState, action: UOMAction): UOMState {
    switch (action.type) {
        case ActionType.GetUOMListSuccess:
            return {
                ...state,
                UOMList: [...action.payload]
            }
        default:
            return state
    }
}
