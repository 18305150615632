import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { EquipmentIntervalSetting } from '@app/modules/equipment/models/equipment.model'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { FileDownloadService } from '../../../services/file-download.service'
import { MaintenancePlan } from '../../shared/models/maintenance-plan.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentPMOService {

    private readonly BASE_API_URL = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private queryBuilderService: QueryBuilderService, private fileDownloadService: FileDownloadService) { }

    public getMaintenancePlans(equipmentId: string | number): Observable<MaintenancePlan[]> {
        return this.queryBuilderService
            .get<MaintenancePlan[]>(this.api(equipmentId, 'MaintenancePlans'))
            .pipe(map(res => res.body))
    }

    public getTestIntervalSetting(equipmentId: string | number): Observable<EquipmentIntervalSetting> {
        return this.queryBuilderService
            .get<EquipmentIntervalSetting>(this.api(equipmentId, 'TestIntervalSetting'))
            .pipe(map(res => res.body))
    }

    public updateTestIntervalSetting(
        equipmentId: string | number,
        testIntervalSetting: EquipmentIntervalSetting
    ): Observable<EquipmentIntervalSetting> {
        return this.queryBuilderService
            .put<EquipmentIntervalSetting>(
                this.api(equipmentId, 'TestIntervalSetting'), testIntervalSetting
            )
            .pipe(map(res => res.body))
    }

    public getPMOReport(): Observable<string> {
        return this.queryBuilderService
            .get<string>(`${this.BASE_API_URL}/Report/PlantMaintenanceOptimizationReport`)
            .pipe(map(response => response.body))
    }


    /** return API url given the equipmentId and endpoint */
    private api(equipmentId: string | number, endpoint: string): string {
        return `${this.BASE_API_URL}/Equipments(${equipmentId})/${endpoint}`
    }

}
