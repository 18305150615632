import { Action } from '@ngrx/store'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { SapEquipmentQueryParameter } from '@app/modules/equipment/models/sap-equipment-query-parameter.model'

export enum ActionType {
    GetSAPEquipmentList = '[SAP Equipment] Get SAP Equipment List',
    GetSAPEquipmentListSuccess = '[SAP Equipment] Get SAP Equipment List Success'
}

export class GetSapEquipmentListAction implements Action {
    public readonly type = ActionType.GetSAPEquipmentList
    constructor(
        public payload: SapEquipmentQueryParameter) { }
}

export class GetSapEquipmentListSuccessAction implements Action {
    public readonly type = ActionType.GetSAPEquipmentListSuccess

    constructor(public payload: {
        querySummary: QuerySummary<SapEquipmentQueryParameter>,
        result: SapEquipment[]
    }) { }
}

export type SapEquipmentAction = GetSapEquipmentListAction | GetSapEquipmentListSuccessAction
