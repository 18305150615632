import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms'
import { get, isEmpty, isEqual, isFunction, isNil, isString, some } from 'lodash'
import { NgClass, NgStyle, NgIf, NgFor } from '@angular/common';

type MappingOptionFunc = (option: any) => string

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgStyle, NgIf, ReactiveFormsModule, NgFor]
})
export class DropdownComponent implements OnChanges {

    @Input() options: any[]
    @Input() form: UntypedFormGroup
    @Input() formControlPath: string
    @Input() key = 'id'
    @Input() value: string | MappingOptionFunc
    @Input() disabledIndexes: number[]
    @Input() displayValue: string = null
    @Input() injectedStyles: { [key: string]: any } = {}

    public noValueOption = '-- select an option --'

    constructor() {
        this.getDisplayedTitle = this.getDisplayedTitle.bind(this)
    }
    // TODO: Make this a CVA so that it work with ngModel
    // (right now, we have to always create a reactive form even
    // if we only need one single dropdown like in multi-point)

    public get styles(): { [key: string]: any } {
        return { ...this.injectedStyles }
    }

    public get formControl(): UntypedFormControl {
        return this.form.get(this.formControlPath) as UntypedFormControl
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEmpty(changes.options?.currentValue)) {
            this.replaceSelectionDataIfIncluded(changes.options?.currentValue)
        }
    }

    public replaceSelectionDataIfIncluded(currentOptions: any[]): void {
        if (this.formControl.value && this.isValueIncludesInList(currentOptions, this.formControl.value)) {
            this.formControl.setValue(
                this.options.find(option => this.isOptionEqualToControlValue(option, this.formControl.value)),
                { emitEvent: false })
        }
    }

    public isDisabled(index: number): boolean {
        if (!this.disabledIndexes) {
            return false
        }

        return this.disabledIndexes.includes(index)
    }

    public isValueIncludesInList(optionList: any[], value: any): boolean {
        return some(optionList, option =>
            this.isOptionEqualToControlValue(option, value))
    }

    public getDisplayedTitle(): string {
        if (isNil(this.formControl.value)) {
            return this.noValueOption
        }
        if (this.displayValue) {
            return get(this.formControl.value, this.displayValue)
        }
        return this.getDisplayedOption(this.formControl.value)
    }

    public getDisplayedOption(option: any): string {
        if (isNil(option)) {
            return ''
        }
        if (isFunction(this.value)) {
            return this.value(option)
        }
        return get(option, this.value, '')
    }

    private isOptionEqualToControlValue(option: any, value: any): boolean {
        if (isString(option)) {
            return value === option
        }
        return isEqual(get(value, this.key), get(option, this.key))
    }
}
