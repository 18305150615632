import { throwError } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'

import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'

export class RethrowErrorStrategy extends ResponseHandlingStrategy {
    constructor(next: ResponseHandlingStrategy) {
        super(next)
    }

    public catchError(error: HttpErrorResponse): any {
        return throwError(error)
    }
}
