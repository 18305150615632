import { Action } from '@ngrx/store'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'

export enum ActionType {
    GetEquipmentTemplateDetails = '[Equipment Template] Load Equipment Template',
    GetEquipmentTemplateDetailSuccess = '[Equipment Template] Extract Equipment TemplateDetails',
    AddEquipmentTemplate = '[Equipment Template] Add Equipment Template',
    RemoveEquipmentTemplate = '[Equipment Template] Remove Equipment Template',
    UpdateEquipmentTemplate = '[Equipment Template] Update Equipment Template',
    UpdateEquipmentTemplateSuccess = '[Equipment Template] Update Equipment Template to store',
    EquipmentTemplateWasModified = '[Equipment Template] Equipment Template has been modified',
    SelectTemplateType = '[Equipment Template] Update selected template',
    CacheEquipmentTemplate = '[EquipmentTemplate] Cache Equipment data to store',
    ClearAllTemplateDetail = '[EquipmentTemplate] Clear All Template Detail'
}

export class GetEquipmentTemplateDetailsAction implements Action {
    public readonly type = ActionType.GetEquipmentTemplateDetails
    constructor(public payload: string) { }
}

export class GetEquipmentTemplateDetailSuccessAction implements Action {
    public readonly type = ActionType.GetEquipmentTemplateDetailSuccess
    constructor(public payload: EquipmentTemplateCoreDetails[]) { }
}

export class AddEquipmentTemplateAction implements Action {
    public readonly type = ActionType.AddEquipmentTemplate
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class RemoveEquipmentTemplateAction implements Action {
    public readonly type = ActionType.RemoveEquipmentTemplate
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class UpdateEquipmentTemplateAction implements Action {
    public readonly type = ActionType.UpdateEquipmentTemplate
    constructor(
        public payload: EquipmentTemplateCoreDetails,
        public selectedTemplateId: number
    ) { }
}

export class UpdateEquipmentTemplateSuccessAction implements Action {
    public readonly type = ActionType.UpdateEquipmentTemplateSuccess
    constructor(
        public payload: EquipmentTemplateCoreDetails,
        public selectedTemplateId: number
    ) { }
}

export class EquipmentTemplateWasModifiedAction implements Action {
    public readonly type = ActionType.EquipmentTemplateWasModified
    constructor(
        public payload: EquipmentTemplateCoreDetails,
        public isValid: boolean,
        public discardChanges = false
    ) { }
}

export class SelectTemplateTypeAction implements Action {
    public readonly type = ActionType.SelectTemplateType
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class CacheEquipmentTemplateAction implements Action {
    public readonly type = ActionType.CacheEquipmentTemplate
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class ClearAllTemplateDetailAction implements Action {
    public readonly type = ActionType.ClearAllTemplateDetail
    constructor() { }
}

export type EquipmentTemplateAction = GetEquipmentTemplateDetailsAction
    | GetEquipmentTemplateDetailSuccessAction
    | AddEquipmentTemplateAction
    | RemoveEquipmentTemplateAction
    | UpdateEquipmentTemplateAction
    | UpdateEquipmentTemplateSuccessAction
    | EquipmentTemplateWasModifiedAction
    | SelectTemplateTypeAction
    | CacheEquipmentTemplateAction
    | ClearAllTemplateDetailAction
