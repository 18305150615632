import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators'

import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { UsersService } from '@app/modules/user/services/users.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { AppSettings } from '@settings/app.settings'
import { UpdateUserListCacheStatusAction } from '../offline/offline.actions'
import {
    ActionType, GetAllUserAction, GetAllUserFailureAction, GetAllUserSuccessAction, GetUserListFetchingFetchingProcessAction, LogoutUserAction,
    UpdateUserCertificationAction, UpdateUserCertificationFailureAction, UpdateUserCertificationSuccessAction
} from './user.actions'
import { SavePmPerformedByFilterOptionsAction } from '@app/store/report/report.actions'

@Injectable()
export class UsersEffects {

    
    public updateUserCertification: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.UpdateUserCertification),
        switchMap((action: UpdateUserCertificationAction) => {
            return this.responseHandlerService.query(
                () => this.usersService.update(action.payload), this.updateUserStrategy
            ).pipe(
                map(user => new UpdateUserCertificationSuccessAction(user)),
                catchError(_ => of(new UpdateUserCertificationFailureAction()))
            )
        })
    ))

    
    public getAllUser: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetAllUserAction>(ActionType.GetAllUser),
        switchMap((action: GetAllUserAction) => {
            return this.responseHandlerService.query(
                () => this.usersService.getAll(action.forceFetch), this.getAllUserStrategy
            ).pipe(
                map(response => {
                    if (response.status === 204) {
                        return new GetUserListFetchingFetchingProcessAction()
                    }
                    this.store.dispatch(new SavePmPerformedByFilterOptionsAction(
                        response.body.map(u => ({ id: u.guid, name: u.displayName }))
                    ))
                    const lastSyncDateString = response.headers.get('X-User-List-Last-Sync-Date')
                    this.store.dispatch(new UpdateUserListCacheStatusAction({ cacheStatus: CacheStatusEnum.CACHED }))
                    return new GetAllUserSuccessAction(response.body, lastSyncDateString)
                }),
                catchError(_ => of(new GetAllUserFailureAction()))
            )
        })
    ))

    
    public getUserListFetchingFetchingProcessAction: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetUserListFetchingFetchingProcessAction>(ActionType.GetUserListFetchingFetchingProcess),
        switchMap(() => {
            return this.responseHandlerService.query(
                () => this.usersService.getUserListFetchingProgress(), this.simpleStrategy
            ).pipe(
                map(inProgress => {
                    if (inProgress) {
                        return new GetUserListFetchingFetchingProcessAction()
                    }
                    return new GetAllUserAction()
                }),
                delay(AppSettings.userListProgressCheckDelay)
            )
        })
    ))

    
    public clearIndividualUserCache = createEffect(() => this.actions$.pipe(
        ofType<LogoutUserAction>(ActionType.LogoutUser),
        tap(() => {
            this.usersService.clearIndividualUserCache()
        })
    ), { dispatch: false })

    private updateUserStrategy: ResponseHandlingStrategy
    private getAllUserStrategy: ResponseHandlingStrategy
    private simpleStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private usersService: UsersService,
        private toastr: ToastrService
    ) {
        this.updateUserStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.PagedList)
            .responseStrategy

        this.getAllUserStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy

        this.simpleStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy
    }
}
