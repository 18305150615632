import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import { UnityIconComponent } from '../unity-icon/unity-icon.component';
import { NgTemplateOutlet, NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, NgFor, NgClass, NgIf, UnityIconComponent]
})

export class PaginationComponent {
    @Input() itemsPerPage: number = 10
    @Input() totalItems: number = 0
    @Input() scrollToTop: boolean = true
    @Input() currentPage: number = 1
    @Input() notshow: boolean = false
    @Output() pageChanged = new EventEmitter<number>()
    @ContentChild('content') content: TemplateRef<any>

    constructor() {}

    public get isFirstPage(): boolean {
        return this.currentPage === 1
    }

    public get isLastPage(): boolean {
        return this.currentPage * this.itemsPerPage >= this.totalItems
    }

    public get firstItemIndex(): number {
        return (this.currentPage - 1) * this.itemsPerPage + 1
    }

    public get lastItemIndex(): number {
        return Math.min(this.firstItemIndex + this.itemsPerPage - 1, this.totalItems);
    }

    public nextPage(): void {
        if (!this.isLastPage) {
            ++this.currentPage
            this.pageChange(this.currentPage)
        }
    }

    public previousPage(): void {
        if (!this.isFirstPage) {
            --this.currentPage
            this.pageChange(this.currentPage)
        }
    }

    private pageChange(currentPage: number): void {
        this.pageChanged.next(currentPage)
        if (this.scrollToTop) {
            document.getElementById("first").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start"
            })
        }
    }
}
