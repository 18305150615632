import { Action } from '@ngrx/store'

import { Process, ProcessConfiguration } from '@app/modules/equipment/models/process.model'

export enum ActionType {
    GetProcesses = '[Process] Load Processes',
    GetProcessesSuccess = '[Process] Load Processes Success',
    GetProcessConfigurations = '[Process] Load Process Configurations',
    GetProcessConfigurationsSuccess = '[Process] Load Process Configuration Success'
}

export class GetProcessesAction implements Action {
    public readonly type = ActionType.GetProcesses
}

export class GetProcessesSuccessAction implements Action {
    public readonly type = ActionType.GetProcessesSuccess
    constructor(public payload: Process[]) { }
}

export class GetProcessConfigurationAction implements Action {
    public readonly type = ActionType.GetProcessConfigurations
}

export class GetProcessConfigurationSuccessAction implements Action {
    public readonly type = ActionType.GetProcessConfigurationsSuccess
    constructor(public payload: ProcessConfiguration[]) { }
}

export type ProcessAction = GetProcessesAction
    | GetProcessesSuccessAction
    | GetProcessConfigurationAction
    | GetProcessConfigurationSuccessAction
