import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { defer, Observable } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'

import { AppState } from '@app/store/app.store'
import { ResponseHandlingStrategy } from './response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from './response-handling-strategy.builder'

@Injectable({
    providedIn: 'root'
})
export class ResponseHandlerService {
    private defaultStrategy: ResponseHandlingStrategy

    constructor(private store: Store<AppState>, private toastr: ToastrService) {
        this.defaultStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowLoader(this.store)
            .responseStrategy
    }

    public query<TItem>(apiCall: () => Observable<TItem>, strategy: ResponseHandlingStrategy = this.defaultStrategy): Observable<TItem> {
        return defer(() => {
            strategy.init()
            return apiCall().pipe(
                finalize(() => strategy.finalize()),
                catchError(error => strategy.catchError<TItem>(error))
            )
        })
    }
}
