import * as ToDoAction from './../to-do/to-do.actions'
import * as SearchAction from './search.actions'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity'

export enum SearchType {
    WO = 'wo',
    EQ = 'eq'
}

export interface SearchState extends EntityState<WorkOrderListItem> {
    searchType: SearchType
    selectedWorkOrderNumber: string
}

export const adapter: EntityAdapter<WorkOrderListItem>
    = createEntityAdapter<WorkOrderListItem>({
        selectId: workOrder => workOrder.workOrderNumber
    })

const initialState: SearchState = adapter.getInitialState({
    searchType: SearchType.WO,
    selectedWorkOrderNumber: undefined
})

export function reducer(
    state = initialState,
    action: SearchAction.CalibrationSearchAction | ToDoAction.ToDoAction
): SearchState {
    switch (action.type) {
        case SearchAction.ActionType.Search:
            return adapter.removeAll(state)
        case SearchAction.ActionType.SearchSuccess:
            return adapter.setAll(action.payload, state)
        case SearchAction.ActionType.ClearSearch:
            return adapter.removeAll(state)
        case SearchAction.ActionType.SetSearchType:
            return {
                ...state,
                searchType: action.payload
            }
        case SearchAction.ActionType.SelectWorkOrder:
            return {
                ...state,
                selectedWorkOrderNumber: action.workOrderNumber
            }
        case ToDoAction.ActionType.AddToDo:
            return adapter.updateOne({
                id: action.payload.workOrderNumber,
                changes: { isBookmarked: true }
            }, state)
        case ToDoAction.ActionType.AddAllToDosSuccess:
            const newUpdate = action.payload.map(wo => {
                return {
                    id: wo.workOrderNumber,
                    changes: { isBookmarked: true }
                }
            })
            return adapter.updateMany(newUpdate, state)
        case ToDoAction.ActionType.AddToDoFailure:
            return adapter.updateOne({
                id: action.payload.workOrderNumber,
                changes: { isBookmarked: false }
            }, state)
        case ToDoAction.ActionType.DeleteToDo:
            return adapter.updateOne({
                id: action.payload.workOrderNumber,
                changes: { isBookmarked: false }
            }, state)
        case ToDoAction.ActionType.DeleteToDos:
            return adapter.updateMany(
                action.payload.map(wo => ({
                    id: wo.workOrderNumber,
                    changes: { isBookmarked: false }
                })),
                state)
        case ToDoAction.ActionType.DeleteToDoFailure:
            return adapter.updateOne({
                id: action.payload.workOrderNumber,
                changes: { isBookmarked: true }
            }, state)
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()
