/*
 * This file store the app's special or `reserved` values.
 *
 * These are value that are reserved for special meaning and
 * normally cannot be interpreted using the same semantic as
 * their siblings.
 */

export enum SpecialTemplateId {
    NoTemplateTemplateId = -999
}
