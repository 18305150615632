export enum ModalType {
    Generic = 1,
    TemplateSelection = 2,
    EquipmentSelection = 3,
    CalibrationTemplateSelection = 4,
    UserUpdate = 5,
    TestEquipmentAdd = 6,
    TestEquipmentUpdate = 7,
    DeferredNotTested = 8,
    FormSelection = 9,
    ReportNewFilterPreset = 10,
    ReportRenameFilterPreset = 11,
    ReportRemoveFilterPreset = 12,
    CreateEmailSubscription = 13,
    DeleteEmailSubscription = 14,
    CalibrationHyperLinkList = 15,
    ExternalLinkOpen = 16
}

export class ModalContentType {
    component: any
    modalType: ModalType
}
export class Modal {
    title: string
    icon: string
    body?: string
    confirmLabel?: string
    closeLabel?: string
    warningOverlay?: boolean
    footer: boolean
    close: boolean
    type: ModalType
    validator?: () => boolean
    closeCallback?: () => void
    confirmCallback?: () => void
    params?: any
}

