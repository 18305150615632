import { Injectable } from '@angular/core'

import { AppSettings } from '@settings/app.settings'
import { EquipmentTemplateCoreDetails } from '../models/equipment-template-core-details.model'
import { EquipmentTemplateDetail } from '../models/equipment-template-details.model'
import { TemplateTypeFilter } from '../models/template-type-filter.model'
import { templateTypeMapped } from '../models/template-type-mapping.model'
import { TemplateTypeEnum } from '../models/template-type.enum'
import { TemplateType } from '../models/template-type.model'

@Injectable({
    providedIn: 'root'
})
export class TemplateTypeUtilService {

    public isDynamicTemplateType(templateTypeId: number): boolean {
        return templateTypeId === TemplateTypeEnum[TemplateTypeEnum.sequential]
            || templateTypeId === TemplateTypeEnum[TemplateTypeEnum.temperature]
            || templateTypeId === TemplateTypeEnum[TemplateTypeEnum.repeatability]
            || templateTypeId === TemplateTypeEnum[TemplateTypeEnum.multiPoint]
            || templateTypeId === TemplateTypeEnum[TemplateTypeEnum.analyzer]
    }

    public transformTemplateTypeFilter(templateTypes: TemplateType[]): TemplateTypeFilter[] {
        return templateTypes
            .reduce((combined, type) => {
                const dynamicTemplate: TemplateTypeFilter = {
                    searchKey: type.id.toString(),
                    name: type.name,
                    selected: false,
                    id: type.id,
                    numberOfPoint: type.numberOfPoint,
                    isActive: type.isActive
                }
                combined.push(dynamicTemplate)
                return combined
            }, [] as TemplateTypeFilter[])
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }

    public getTemplateTypeName(templateType: TemplateType): string {
        const templateTypeName =
            this.isDynamicTemplateType(templateType.id)
                ? `${templateType.name} (${templateType.numberOfPoint} point)`
                : templateType.name

        return templateTypeName
    }

    public refactorTemplateTypeList(templateTypes: TemplateType[]): TemplateType[] {
        const availableTemplate: TemplateType[] = []
        templateTypes.forEach(currentTemplate => {
            if (!this.isDynamicTemplateType(currentTemplate.id)) {
                availableTemplate.push(currentTemplate)
            } else {
                availableTemplate.push({
                    id: currentTemplate.id,
                    name: currentTemplate.name.replace(/\s\((.*?)\)/g, ''),
                    numberOfPoint: currentTemplate.numberOfPoint,
                    isActive: currentTemplate.isActive
                } as TemplateTypeFilter)
            }
        })

        return availableTemplate
    }

    public getDefaultEquipmentTemplate(
        plantCode: string,
        equipmentId: string,
        newTemplateType: TemplateType
    ): EquipmentTemplateCoreDetails {
        const sortedNumberOfPoint = newTemplateType.numberOfPoint.slice().sort((a, b) => a - b)
        const equipmentDetail: EquipmentTemplateDetail = {
            numberOfPoint: newTemplateType.name === TemplateTypeEnum.analyzer ? 3 : sortedNumberOfPoint[0],
            notSaved: true,
            isValid: this.templateRequiredNoValidation(newTemplateType.id)
        }
        return {
            id: Math.floor((Math.random() * AppSettings.newTemplateIdRange.max) + AppSettings.newTemplateIdRange.min),
            detail: equipmentDetail,
            equipmentId,
            isActive: true,
            plantCode,
            templateTypeId: newTemplateType.id,
            templateTypeName: newTemplateType.name
        } as EquipmentTemplateCoreDetails
    }

    public getTemplateTypeComponent(templateTypes: TemplateTypeEnum): any {
        return templateTypeMapped.find(template => template.type === templateTypes).component
    }

    private templateRequiredNoValidation(templateTypeId): boolean {
        return templateTypeId === TemplateTypeEnum[TemplateTypeEnum.thirdParty] ||
            templateTypeId === TemplateTypeEnum[TemplateTypeEnum.leakCheck] ||
            templateTypeId === TemplateTypeEnum[TemplateTypeEnum.passFailCheck]
    }
}
