import { createSelector } from '@ngrx/store'

import { IdentityState } from '@app/store/identity/identity.reducer'

export const selectAuthenticationState = (state): IdentityState => state.identity

export const isAuthenticated = createSelector(selectAuthenticationState, identity => identity.isAuthenticated)
export const currentUser = createSelector(selectAuthenticationState, identity => identity.currentUser)
export const plantCodes = createSelector(selectAuthenticationState, identity => identity.currentUser?.plantCodes)
export const plants = createSelector(selectAuthenticationState, identity => identity.currentUser?.plants)
export const selectedPlant = createSelector(selectAuthenticationState, identity => identity.currentUser?.preferredPlantCode)
