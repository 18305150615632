import { Action } from '@ngrx/store'

import { Equipment } from '@app/modules/equipment/models/equipment.model'

export enum ActionType {
    GetEquipmentDetails = '[Equipment] Get Equipment Details',
    GetEquipmentDetailsSuccess = '[Equipment] Get Equipment Details Success',
    CreateEquipment = '[Equipment] Create New Equipment',
    UpdateEquipmentNote = '[Equipment] Update Equipment Note',
    UpdateEquipmentNoteSuccess = '[Equipment] Update Equipment Note Success'
}

export class GetEquipmentDetailsAction implements Action {
    public readonly type = ActionType.GetEquipmentDetails
    constructor(public payload: string) { }
}

export class GetEquipmentDetailsSuccessAction implements Action {
    public readonly type = ActionType.GetEquipmentDetailsSuccess
    constructor(public payload: Equipment) { }
}

export class CreateEquipmentAction implements Action {
    public readonly type = ActionType.CreateEquipment
    constructor(public payload: Equipment) { }
}

export class UpdateEquipmentNoteAction implements Action {
    public readonly type = ActionType.UpdateEquipmentNote
    constructor(public payload: Equipment) { }
}

export class UpdateEquipmentNoteSuccessAction implements Action {
    public readonly type = ActionType.UpdateEquipmentNoteSuccess
    constructor(public payload: Equipment) { }
}

export type EquipmentAction = CreateEquipmentAction
    | GetEquipmentDetailsAction
    | GetEquipmentDetailsSuccessAction
    | UpdateEquipmentNoteAction
    | UpdateEquipmentNoteSuccessAction
