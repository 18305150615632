import { createSelector } from '@ngrx/store'

import { deepCopy } from '@app/utils/app-utils.function'
import { AppState } from './app.store'

export const storeState = (state: AppState) => state

export const loggableStoreState = createSelector(storeState, (state: AppState) => {
    // IMPORTANT: This payload need to be kept small or AppInsight will reject it.
    const { loader, connection, calibration, customForm } = deepCopy(state)
    const resultState = {
        loader,
        connection,
        calibration,
        customForm
    }

    return resultState
})
