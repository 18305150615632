import { Action } from '@ngrx/store'

import { EquipmentIntervalSetting } from '@app/modules/equipment/models/equipment.model'
import { MaintenancePlan } from '@app/modules/shared/models/maintenance-plan.model'

export enum ActionType {
    // ---- Get Plan ----
    GetMaintenancePlansStart = '[Equipment PMO] GET Eq. MaintenancePlans Start 📨',
    GetMaintenancePlansSuccess = '[Equipment PMO] GET Eq. MaintenancePlans Success ✅',
    GetMaintenancePlansFail = '[Equipment PMO] GET Eq. MaintenancePlans Fail ❌',
    // ---- Get Setting ----
    GetTestIntervalSettingStart = '[Equipment PMO] GET Eq. TestIntervalSetting Start 📨',
    GetTestIntervalSettingSuccess = '[Equipment PMO] GET Eq. TestIntervalSetting Success ✅',
    GetTestIntervalSettingFail = '[Equipment PMO] GET Eq. TestIntervalSetting Fail ❌',
    // ---- Update Setting ----
    UpdateTestIntervalSettingStart = '[Equipment PMO] UPDATE Eq. TestIntervalSetting Start 📨',
    UpdateTestIntervalSettingSuccess = '[Equipment PMO] UPDATE Eq. TestIntervalSetting Success ✅',
    UpdateTestIntervalSettingFail = '[Equipment PMO] UPDATE Eq. TestIntervalSetting Fail ❌'
}

export class GetMaintenancePlansStartAction implements Action {
    public readonly type = ActionType.GetMaintenancePlansStart
    constructor(public equipmentId: string) { }
}

export class GetMaintenancePlansSuccessAction implements Action {
    public readonly type = ActionType.GetMaintenancePlansSuccess
    constructor(public maintenancePlans: MaintenancePlan[]) { }
}

export class GetMaintenancePlansFailAction implements Action {
    public readonly type = ActionType.GetMaintenancePlansFail
    constructor(public error: any) { }
}

export class GetTestIntervalSettingStartAction implements Action {
    public readonly type = ActionType.GetTestIntervalSettingStart
    constructor(public equipmentId: string) { }
}

export class GetTestIntervalSettingSuccessAction implements Action {
    public readonly type = ActionType.GetTestIntervalSettingSuccess
    constructor(public testIntervalSetting: EquipmentIntervalSetting) { }
}

export class GetTestIntervalSettingFailAction implements Action {
    public readonly type = ActionType.GetTestIntervalSettingFail
    constructor(public error: any) { }
}

export class UpdateTestIntervalSettingStartAction implements Action {
    public readonly type = ActionType.UpdateTestIntervalSettingStart
    constructor(public payload: { equipmentId: string, testIntervalSetting: EquipmentIntervalSetting }) { }
}

export class UpdateTestIntervalSettingSuccessAction implements Action {
    public readonly type = ActionType.UpdateTestIntervalSettingSuccess
}

export class UpdateTestIntervalSettingFailAction implements Action {
    public readonly type = ActionType.UpdateTestIntervalSettingFail
    constructor(public error: any) { }
}

export type EquipmentPMOAction =
    GetMaintenancePlansStartAction
    | GetMaintenancePlansSuccessAction
    | GetMaintenancePlansFailAction
    | GetTestIntervalSettingStartAction
    | GetTestIntervalSettingSuccessAction
    | GetTestIntervalSettingFailAction
    | UpdateTestIntervalSettingStartAction
    | UpdateTestIntervalSettingSuccessAction
    | UpdateTestIntervalSettingFailAction
