import { Component, Input, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { OnePointCalibrationService } from '@app/modules/calibration/services/one-point-calibration.service'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { TestingDirection } from '@app/modules/equipment/models/testing-direction.enum'
import { ToleranceFormValidationsService } from '@app/modules/equipment/services/template-validations/tolerance-form-validations.service'
import { ToleranceValidationService } from '@app/modules/equipment/services/tolerance-validation.service'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy, findOptionWithKey, isNotAValue, safeDefaultObjGet } from '@app/utils/app-utils.function'
import { mapUomObjToUomDisplay } from '@app/utils/uom/uom.util'
import { AdminFormRowComponent } from '../admin-form-row/admin-form-row.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe, NgClass, NgFor } from '@angular/common'
import { FilterPercentUom } from '@app/modules/shared/pipes/filter-percent-uom.pipe'
import { NumericInputComponent } from '@app/modules/mrma-ui-components/controls/numeric-input/numeric-input.component'
import { UomPresentationPipePipe } from '@app/modules/equipment/pipes/uom-desc-with-short-name-pipe'
import { MathAbsolutePipe } from '@app/modules/shared/pipes/math-absolute.pipe'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'

@Component({
    selector: 'app-one-point',
    templateUrl: './one-point.component.html',
    styleUrls: ['./one-point.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        AdminFormRowComponent,
        DropdownComponent,
        AsyncPipe,
        FilterPercentUom,
        NumericInputComponent,
        UomPresentationPipePipe,
        MathAbsolutePipe,
        FormsModule,
        NgClass,
        NgFor,
        OptionsComponent
    ]
})
export class OnePointComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public toleranceUoMsIsDisable = true
    public uomList$: Observable<UOM[]>
    public toleranceUoMs: UOM[] = []
    public processes$: Observable<Process[]>
    public isRequiredOption = TemplateOptions.isRequiredOption
    public testingDirectionOptions = TemplateOptions.testingDirectionOptions
    public form: UntypedFormGroup
    public getOption = findOptionWithKey
    public mapUomObjToUomDisplay = mapUomObjToUomDisplay

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private percentageUOM: UOM

    constructor(
        protected store: Store<AppState>,
        protected formBuilder: UntypedFormBuilder,
        protected validationService: ToleranceFormValidationsService,
        protected onePointCalibrationService: OnePointCalibrationService,
        protected toleranceValidationService: ToleranceValidationService
    ) {
        super()
    }

    public get expectedReadingRange(): string {
        const tolerance = (this.form.get('tolerance') as UntypedFormGroup).getRawValue() as Tolerance
        const expectedReading: UnitValue = {
            value: this.form.get('expectedReading.value').value as number,
            unitOfMeasurement: this.form.get('expectedReading.unitOfMeasurement').value as UOM,
        }

        if (!this.toleranceValidationService.isValid(tolerance) ||
            isNotAValue(expectedReading.value) ||
            isNotAValue(expectedReading.unitOfMeasurement) ||
            isNotAValue(expectedReading.unitOfMeasurement.uomCode)) {
            return 'N/A'
        }

        const range = this.onePointCalibrationService.toleranceToNumericRange(
            tolerance, expectedReading
        )

        return `${range.min} to ${range.max}`
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.initPercentageUOM()
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initTemplateData()
        this.watchMainFormControl()
    }

    public updateLowerRange(val: number | null): void {
        const patch: any = {
            tolerance: {
                lowerRange: 0 - val // Convert to number
            }
        }
        this.form.patchValue(patch)
    }

    public updateHigherRange(val: number | null): void {
        const patch: any = {
            tolerance: {
                higherRange: +val   // Convert to number
            }
        }
        this.form.patchValue(patch)
    }

    private initPercentageUOM(): void {
        this.addSubscription(
            this.uomList$.subscribe(uoms => {
                this.percentageUOM = uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage)
            })
        )
    }

    private initTemplateData(): void {
        // build an empty form first to prevent race condition when form.get() is called else where
        this.form = this.buildForm()

        const subscription = this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.form = this.buildForm()

            this.bindFormDependencies()
            this.disableExpectedReadingValueIfNeeded()

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.form.getRawValue()
            ))

            if (!this.isAdmin) {
                this.form.disable()
            }
        })
        this.addSubscription(subscription)
    }

    private bindFormDependencies(): void {
        this.bindSetPointUoMChange()
        this.bindSetPointValueChange()
        this.bindExpectedReadingUoMChange()
        this.bindToleranceLowerRangeChange()
    }

    private bindSetPointUoMChange(): void {
        const setPointGroup = this.form.get('setPoint.unitOfMeasurement')
        const uomChange = setPointGroup.valueChanges.subscribe(val => {
            const setPointValue = this.form.get('setPoint.value').value
            const patch: any = {
                expectedReading: {
                    unitOfMeasurement: val,
                    value: setPointValue
                }
            }
            this.form.patchValue(patch)
            this.form.get('expectedReading.value').disable()
        })
        this.addSubscription(uomChange)
    }

    private bindSetPointValueChange(): void {
        const setPointGroup = this.form.get('setPoint.value')
        const valueChange = setPointGroup.valueChanges.subscribe(val => {
            if (this.areSetPointAndExpectedReadingUoMsTheSame()) {
                this.form.patchValue({
                    expectedReading: {
                        value: val || 0
                    }
                })
            }
        })
        this.addSubscription(valueChange)
    }

    private bindExpectedReadingUoMChange(): void {
        const expectedReadingGroup = this.form.get('expectedReading.unitOfMeasurement')
        const uomChange = expectedReadingGroup.valueChanges.subscribe((val: UOM) => {
            const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
            const setPointValue = this.form.get('setPoint.value').value
            const expectedReadingControl = this.form.get('expectedReading.value')
            this.setToleranceUoMs(val)

            const patch: any = {
                tolerance: {
                    unitOfMeasurement: val
                }
            }

            expectedReadingControl.enable()
            if (setPointUoM?.uomCode === val?.uomCode) {
                patch.expectedReading = {
                    value: setPointValue
                }
                expectedReadingControl.disable()
            }
            this.form.patchValue(patch, { emitEvent: false })
        })
        this.addSubscription(uomChange)
    }

    private bindToleranceLowerRangeChange(): void {
        const lowerRangeControl = this.form.get('tolerance.lowerRange')
        const higherRangeControl = this.form.get('tolerance.higherRange')
        const lowerRangeChange = lowerRangeControl.valueChanges.subscribe(val => {
            if (isNotAValue(higherRangeControl.value)) {
                higherRangeControl.setValue(Math.abs(val))
            }
        })
        this.addSubscription(lowerRangeChange)
    }

    private disableExpectedReadingValueIfNeeded(): void {
        if (this.areSetPointAndExpectedReadingUoMsTheSame()) {
            this.form.get('expectedReading.value').disable()
        } else {
            this.form.get('expectedReading.value').enable()
        }
    }

    private areSetPointAndExpectedReadingUoMsTheSame(): boolean {
        const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
        const expectedReadingUoM = this.form.get('expectedReading.unitOfMeasurement').value as UOM
        const areSetPointAndExpectedReadingUoMsTheSame = setPointUoM && expectedReadingUoM && setPointUoM.uomCode === expectedReadingUoM.uomCode
        return areSetPointAndExpectedReadingUoMsTheSame
    }

    private buildForm(): UntypedFormGroup {
        const expectedReadingUoM = safeDefaultObjGet(this.templateDetails, 'detail.expectedReading.unitOfMeasurement', null)
        if (expectedReadingUoM) {
            this.setToleranceUoMs(expectedReadingUoM)
        }

        const form = this.formBuilder.group({
            process: {
                id: (this.templateDetails?.processId ?? 1),
                name: (this.templateDetails?.processName ?? 'Generic')
            },
            alias: (this.templateDetails?.detail?.alias ?? ''),
            setPoint: this.formBuilder.group({
                unitOfMeasurement: safeDefaultObjGet(this.templateDetails, 'detail.setPoint.unitOfMeasurement', null),
                value: safeDefaultObjGet(this.templateDetails, 'detail.setPoint.value', null),
            }),
            expectedReading: this.formBuilder.group({
                unitOfMeasurement: expectedReadingUoM,
                value: safeDefaultObjGet(this.templateDetails, 'detail.expectedReading.value', null),
            }),
            tolerance: this.formBuilder.group({
                unitOfMeasurement: {
                    value: (this.templateDetails?.detail?.tolerance?.unitOfMeasurement ?? null),
                    disabled: this.toleranceUoMsIsDisable
                },
                lowerRange: safeDefaultObjGet(this.templateDetails, 'detail.tolerance.lowerRange', null),
                higherRange: safeDefaultObjGet(this.templateDetails, 'detail.tolerance.higherRange', null),
            }),
            testingDirection: safeDefaultObjGet(this.templateDetails, 'detail.testingDirection', TestingDirection.NotAvailable),
            isTestEquipmentRequired: (this.templateDetails?.detail?.isTestEquipmentRequired ?? false) + '',
            procedure: (this.templateDetails?.detail?.procedure ?? ''),
        })

        return form
    }

    private setToleranceUoMs(val: UOM): void {
        if (this.percentageUOM?.uomCode && val?.uomCode !== this.percentageUOM?.uomCode) {
            this.toleranceUoMs = [val, this.percentageUOM]
        } else {
            this.toleranceUoMs = [val]
        }
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.form.valueChanges.subscribe(_form => {
                const raw = this.form.getRawValue()

                const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
                eqCoreDetails.detail.numberOfPoint = 1

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(raw))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    eqCoreDetails,
                    this.validationService.isOnePointFormValid(raw)
                ))
            })
        )
    }
}
