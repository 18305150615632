import { deepCopy } from '@app/utils/app-utils.function'
import { SelectionDrawerState } from '../../modules/shared/models/selection-drawer.model'
import { ActionType, ToggleSelectionDrawer } from './selection-drawer.actions'

export interface SelectionDrawerCollectionState {
    selectionDrawerCollectionState: SelectionDrawerState[]
}

const initialState: SelectionDrawerCollectionState = {
    selectionDrawerCollectionState: []
}

export function reducer(state = initialState, action: ToggleSelectionDrawer): SelectionDrawerCollectionState {
    const tempDrawerState = deepCopy(state.selectionDrawerCollectionState) as SelectionDrawerState[]
    let arrIndex = 0
    switch (action.type) {
        case ActionType.ToggleSelectionDrawer:
            arrIndex = tempDrawerState.findIndex(x => x.name === action.payload.name)
            if (arrIndex >= 0) {
                tempDrawerState[arrIndex].visibility = action.payload.visibility
            } else {
                tempDrawerState.push(action.payload)
            }
            return {
                ...state,
                selectionDrawerCollectionState: tempDrawerState
            }
        default:
            return state
    }
}
