import { SelectionDrawerState } from '../../modules/shared/models/selection-drawer.model'

export enum ActionType {
    ToggleSelectionDrawer = '[SelectionDrawer] Toggle Selection Drawer'
}

export class ToggleSelectionDrawerAction {
    readonly type = ActionType.ToggleSelectionDrawer
    constructor(public payload: SelectionDrawerState) { }
}

export type ToggleSelectionDrawer = ToggleSelectionDrawerAction
