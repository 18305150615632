import { Component } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'

import { MultiPointComponent } from '@app/modules/equipment/components/equipment-template/template-forms/multi-point/multi-point.component'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { MultiPointValidationService } from '@app/modules/equipment/services/template-validations/multi-point-validation.service'
import { AppState } from '@app/store/app.store'
import { InlineLabelComponent } from '@app/modules/mrma-ui-components/layouts/inline-label/inline-label.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe } from '@angular/common'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'
import { TabularSetpointsSelectorComponent } from '../multi-point/tabular-setpoints-selector/tabular-setpoints-selector.component'

@Component({
    selector: 'app-analyzer',
    templateUrl: '../multi-point/multi-point.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InlineLabelComponent,
        DropdownComponent,
        AsyncPipe,
        OptionsComponent,
        TabularSetpointsSelectorComponent
    ]
})
export class AnalyzerComponent extends MultiPointComponent {
    public numberOfPointOptions = TemplateOptions.numberOfPointAnalyzerMultiPointOptions;
    public defaultSetPointNames = [['Low Point', 'Zero Point'], ['High Point', 'Span'], ['Response Time']]

    constructor(
        protected store: Store<AppState>,
        protected formBuilder: UntypedFormBuilder,
        protected validationService: MultiPointValidationService
    ) {
        super(store, formBuilder, validationService)
    }
}
