import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

import { MaintenancePlan, MaintenancePlanExpanded } from '@app/modules/shared/models/maintenance-plan.model'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { AppState } from '@app/store/app.store'
import { selectedPlant } from '@app/store/identity/identity.selectors'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { MaintenancePlanQuery } from '../models/maintenance-plan-query.model'

@Injectable({ providedIn: 'root' })
export class MaintenancePlanService {
    private readonly baseApiUrl =
        `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(
        private store: Store<AppState>,
        private queryBuilderService: QueryBuilderService
    ) { }

    public getMaintenancePlanList({
        search = '',
        sortBy = '',
        currentPage = 1,
        itemPerPage = AppSettings.maintenancePlanListTotalRowPerPage,
        isDesc = false
    }: MaintenancePlanQuery = {}): Observable<HttpResponse<MaintenancePlan[]>> {
        return this.queryBuilderService
            .get<MaintenancePlan[]>(
                this.baseApiUrl + '/MaintenancePlans',
                { search, sortBy, currentPage, itemPerPage, isDesc }
            )
    }

    public getMaintenancePlanDetail(maintenancePlanNumber: string | number): Observable<HttpResponse<MaintenancePlanExpanded>> {
        return this.queryBuilderService
            .get<MaintenancePlanExpanded>(
                this.baseApiUrl + `/MaintenancePlan(${maintenancePlanNumber})`
            )
    }

    public getMaintenancePlanLongText(maintenancePlanNumber: string | number): Observable<HttpResponse<string>> {
        return this.store.select(selectedPlant).pipe(
            filter(preferredPlantCode => !!preferredPlantCode),
            switchMap(plantCode => {
                return this.queryBuilderService.get<string>(
                    this.baseApiUrl + '/MaintenancePlan/LongText',
                    { plantCode, maintenancePlanNumber }
                )
            })
        )
    }

    public getMaintenanceItemLongText(maintenanceItemNumber: string | number): Observable<HttpResponse<string>> {
        return this.store.select(selectedPlant).pipe(
            filter(preferredPlantCode => !!preferredPlantCode),
            switchMap(plantCode => {
                return this.queryBuilderService.get<string>(
                    this.baseApiUrl + '/MaintenanceItem/LongText',
                    { plantCode, maintenanceItemNumber }
                )
            })
        )
    }
}
