<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="PROCESS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="compareTemplateForm"
                      [formControlPath]="'process'"
                      [options]="(processes$ | async)"
                      [key]="'id'"
                      [value]="'name'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="TEMPLATE ALIAS">
    <div class="em-l-grid__item admin-input-width">
        <input formControlName="alias"
               type="text"
               class="em-c-input em-u-font-style-regular"
               maxlength="20">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="TOLERANCE *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="compareTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async | uomPresentationPipe | filterPercentUom)"
                      [key]="'uomCode'"
                      [value]="'uomDisplayValue'">
        </app-dropdown>
    </div>
    <div formGroupName="tolerance"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': compareTemplateForm.disabled}">
        <app-numeric-input formControlName="value"
                           [clearZeroOnFocus]="true">
        </app-numeric-input>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="PROCEDURE NUMBER">
    <div class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': compareTemplateForm.disabled}">
        <input formControlName="procedure"
               type="text"
               placeholder="B4P-RXG-XXX.doc"
               class="em-c-input em-u-font-style-regular">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="EQUIPMENT 1*">
    <div class="em-l-grid__item admin-wide-input-width">
        <app-test-equipment-picker [masterTestEquipments]="(compareEquipment$ | async)"
                                   [testEquipmentControl]="compareEquipmentForm.controls[0]"
                                   [isDisabled]="compareEquipmentForm.disabled"
                                   [placeHolder]="'Equipment Id, Tag, Description, FLOC, TechId'"
                                   [isCompareEquiment]="true">
        </app-test-equipment-picker>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="compareTemplateForm"
                    label="EQUIPMENT 2*"
                    *ngIf="selectedTemplate.id === compare3PointTemplateId">
    <div class="em-l-grid__item admin-wide-input-width">
        <app-test-equipment-picker [masterTestEquipments]="(compareEquipment$ | async)"
                                   [testEquipmentControl]="compareEquipmentForm.controls[1]"
                                   [isDisabled]="compareEquipmentForm.disabled"
                                   [placeHolder]="'Equipment Id, Tag, Description, FLOC, TechId'"
                                   [isCompareEquiment]="true">
        </app-test-equipment-picker>
    </div>
    <div class="em-l-grid__item admin-input-width">
        <p *ngIf="equipmentTagIsDuplicated">You cannot have two of the same equipment tag!</p>
    </div>
</app-admin-form-row>
