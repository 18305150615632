import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity'

import { BackgroundSyncStatusEnum, CacheStatusEnum } from '@app/models/offline-status.enum'
import {
    CalibrationResultStatusEnum,
    CalibrationResultStatusTextEnum
} from '@app/modules/calibration/models/calibration-result-status.enum'
import {
    CalibrationStatusEnum,
    CalibrationStatusTextEnum
} from '@app/modules/calibration/models/calibration-status.enum'
import { CalibrationActionRequired } from '@app/modules/calibration/services/calibration-action-required.service'
import { UploadQueue } from '@app/modules/shared/models/upload-queue.model'
import { deepCopy } from '@app/utils/app-utils.function'
import { ActionType, OfflineActionUnion } from './offline.actions'

export interface EquipmentCache {
    equipmentId: string
    flamingoFormId: string
    calibrationStatusId: CalibrationStatusEnum
    finalPMResultStatusId: CalibrationResultStatusEnum
    actionRequired: string[]
    isTemplateMapped: boolean
    isFlamingoFormMapped: boolean
    backgroundSyncStatus?: BackgroundSyncStatusEnum
    cacheTemplateStatus?: CacheStatusEnum
    cacheCalibrationStatus?: CacheStatusEnum
    cacheFlamingoStatus?: CacheStatusEnum
}

export interface WorkOrderCache {
    workOrderNumber: string
    equipments: EquipmentCache[]
}

export interface OfflineState extends EntityState<WorkOrderCache> {
    testEquipmentListCacheStatus: CacheStatusEnum
    userListCacheStatus: CacheStatusEnum
    dataWaitingForUpload: UploadQueue[]
    isAutoDownloadEnabled: boolean
}

export const adapter: EntityAdapter<WorkOrderCache>
    = createEntityAdapter<WorkOrderCache>({
        selectId: workOrderCache => workOrderCache.workOrderNumber
    })

const initialState: OfflineState = adapter.getInitialState({
    testEquipmentListCacheStatus: undefined,
    userListCacheStatus: undefined,
    dataWaitingForUpload: [],
    isAutoDownloadEnabled: undefined
})

export function reducer(state = initialState, action: OfflineActionUnion): OfflineState {
    const allCacheWorkOrders = adapter.getSelectors().selectAll(state)

    switch (action.type) {
        case ActionType.UpdateTestEquipmentListCacheStatus:
            return {
                ...state,
                testEquipmentListCacheStatus: action.payload.cacheStatus
            }
        case ActionType.UpdateUserListCacheStatus:
            return {
                ...state,
                userListCacheStatus: action.payload.cacheStatus
            }
        case ActionType.UpdateWorkOrderDetailCacheStatus:
            return adapter.updateMany(action.payload.workOrderCaches, state)
        case ActionType.UpdateWorkOrderEquipmentCalibrationStatus:
            const { workOrderNumber, equipmentId, calibrationStatus, finalPMResultStatus } = action.calibrationDetails

            const workOrder = deepCopy(allCacheWorkOrders.find(it => it.workOrderNumber === workOrderNumber))
            const woEquipment = workOrder?.equipments.find(it => it.equipmentId === equipmentId)

            if (!woEquipment) {
                return {
                    ...state
                }
            }

            woEquipment.calibrationStatusId = calibrationStatus?.id
            woEquipment.finalPMResultStatusId = finalPMResultStatus?.id
            woEquipment.actionRequired = CalibrationActionRequired.getRequiredAction(action.calibrationDetails)

            const newChanges = {
                id: workOrderNumber,
                changes: {
                    equipments: workOrder.equipments
                }
            }
            return adapter.updateOne(newChanges, state)
        case ActionType.RemoveWorkOrderCacheStatus:
            return adapter.removeOne(action.payload, state)
        case ActionType.RemoveAllWorkOrdersCacheStatus:
            return adapter.removeMany(action.payload, state)

        case ActionType.CacheUserWoItems:
            const woCacheList: WorkOrderCache[] = action.payload.map(it => ({
                workOrderNumber: it.workOrderNumber,
                equipments: it.equipmentCalibrations.map(eq => ({
                    equipmentId: eq.equipmentId,
                    flamingoFormId: eq.flamingoFormIds[0],
                    calibrationStatusId: CalibrationStatusTextEnum[eq?.status?.toLowerCase()] ?? '',
                    finalPMResultStatusId: CalibrationResultStatusTextEnum[eq?.result?.toLowerCase()] ?? '',
                    actionRequired: eq.actionRequired,
                    isTemplateMapped: eq.isTemplateMapped,
                    isFlamingoFormMapped: eq.flamingoFormIds.length > 0,
                    cacheTemplateStatus: eq.isTemplateMapped ? CacheStatusEnum.LOADING : CacheStatusEnum.UNDEFINED,
                    cacheFlamingoStatus: eq.flamingoFormIds.length > 0 ? CacheStatusEnum.LOADING : CacheStatusEnum.UNDEFINED,
                    cacheCalibrationStatus: CacheStatusEnum.UNDEFINED
                }))
            }))
            return adapter.addMany(woCacheList, state)
        case ActionType.UpdateEquipmentTemplateCacheStatus:
            const updateTemplateCache: Update<WorkOrderCache>[] = []
            const workOrderTemplateCache = deepCopy(allCacheWorkOrders)
            action.payload.eqTemplates.forEach(eqt => {
                const eqId = eqt[0].equipmentId
                workOrderTemplateCache.forEach(wo => {
                    const templateEquipment = wo.equipments.find(eq => eq.equipmentId === eqId)
                    if (templateEquipment) {
                        templateEquipment.cacheTemplateStatus = CacheStatusEnum.CACHED
                    }
                })
            })

            workOrderTemplateCache.forEach(wo => {
                updateTemplateCache.push({
                    id: wo.workOrderNumber,
                    changes: {
                        equipments: wo.equipments
                    }
                })
            })

            return adapter.updateMany(updateTemplateCache, state)
        case ActionType.UpdateEquipmentCalibrationCacheStatus:
            const updateCalibrationCache: Update<WorkOrderCache>[] = []
            const workOrderCalibrationCache = deepCopy(allCacheWorkOrders)
            action.payload.eqCalibrations.forEach(eqc => {
                workOrderCalibrationCache.forEach(wo => {
                    const calibrationEquipment = wo.equipments.find(eq => eq.equipmentId === eqc.equipmentId)
                    if (calibrationEquipment) {
                        calibrationEquipment.cacheCalibrationStatus = CacheStatusEnum.CACHED
                    }
                })
            })

            workOrderCalibrationCache.forEach(wo => {
                updateCalibrationCache.push({
                    id: wo.workOrderNumber,
                    changes: {
                        equipments: wo.equipments
                    }
                })
            })

            return adapter.updateMany(updateCalibrationCache, state)
        case ActionType.UpdateEquipmentFlamingoCacheStatus:
            const updateFlamingoCache: Update<WorkOrderCache>[] = []
            const workOrderFlamingoCache = deepCopy(allCacheWorkOrders)
            workOrderFlamingoCache.forEach(wo => {
                wo.equipments.forEach(eq => {
                    const flamingoFormIdFound = action.payload.eqFlamingos.some(fs => fs.id === eq.flamingoFormId)
                    if (flamingoFormIdFound) {
                        eq.cacheFlamingoStatus = CacheStatusEnum.CACHED
                    }
                })
            })

            workOrderFlamingoCache.forEach(wo => {
                updateFlamingoCache.push({
                    id: wo.workOrderNumber,
                    changes: {
                        equipments: wo.equipments
                    }
                })
            })

            return adapter.updateMany(updateFlamingoCache, state)
        case ActionType.AddPMToUploadQueue:
            const cachePMUploadAlreadyExist = state.dataWaitingForUpload.find(c =>
                c.workOrderNumber === action.payload.workOrderNumber &&
                c.equipmentId === action.payload.equipmentId
            )

            if (cachePMUploadAlreadyExist) {
                return { ...state }
            }

            return {
                ...state,
                dataWaitingForUpload: [...state.dataWaitingForUpload, action.payload]
            }
        case ActionType.UpdatePMUploadLoadQueue:
            if (action.payload.status === BackgroundSyncStatusEnum.NOT_QUEUED) {
                return { ...state }
            }

            const queueState = deepCopy(state.dataWaitingForUpload) as UploadQueue[]
            const updateQueue = queueState.find(q =>
                q.workOrderNumber === action.payload.workOrderNumber &&
                q.equipmentId === action.payload.equipmentId
            )

            updateQueue.status = action.payload?.status || updateQueue.status

            return {
                ...state,
                dataWaitingForUpload: queueState
            }
        case ActionType.UpdatePMSyncStatus:
            const workOrderCache = deepCopy(allCacheWorkOrders.find(it => it.workOrderNumber === action.payload.workOrderNumber))
            const equipmentCache = workOrderCache?.equipments.find(it => it.equipmentId === action.payload.equipmentId)
            equipmentCache.backgroundSyncStatus = action.payload.status

            const workOrderEquipmentChanges = {
                id: action.payload.workOrderNumber,
                changes: {
                    equipments: workOrderCache.equipments
                }
            }
            return adapter.updateOne(workOrderEquipmentChanges, state)
        case ActionType.RemovedPMFromUploadQueue:
            if (!action.payload) {
                return {
                    ...state,
                    dataWaitingForUpload: []
                }
            }

            return {
                ...state,
                dataWaitingForUpload: state.dataWaitingForUpload.filter(q =>
                    q.workOrderNumber !== action.payload.workOrderNumber &&
                    q.equipmentId !== action.payload.equipmentId
                )
            }
        case ActionType.ClearOfflineCache:
            return {
                ...initialState,
                isAutoDownloadEnabled: state.isAutoDownloadEnabled
            }
        case ActionType.LoadAutoDownloadEnabledSuccess:
            return {
                ...state,
                isAutoDownloadEnabled: action.isAutoDownloadEnabled
            }
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()
