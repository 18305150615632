import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'

export const hyperlinkListModalObject = {
    title: 'HYPERLINK',
    icon: 'circle-exclamation-mark',
    footer: false,
    close: true,
    type: ModalType.CalibrationHyperLinkList
} as Modal


export const externalLinkWarningModalObject = {
    title: 'OPEN EXTERNAL LINK',
    icon: 'circle-exclamation-mark',
    confirmLabel: 'Proceed',
    closeLabel: 'Cancel',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.ExternalLinkOpen
} as Modal
