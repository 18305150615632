import { animate, style, transition, trigger } from '@angular/animations'
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { isNotAValue } from '@app/utils/app-utils.function'
import { NgClass, NgStyle, NgIf } from '@angular/common';

@Component({
    selector: 'app-numeric-input',
    templateUrl: './numeric-input.component.html',
    styleUrls: ['./numeric-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericInputComponent),
            multi: true
        }
    ],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(1rem)'
                }),
                animate('300ms ease-out', style({
                    opacity: 1,
                    transform: 'translateY(0)'
                }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('700ms ease-in', style({ opacity: 0 }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgClass, NgStyle, NgIf]
})
export class NumericInputComponent implements ControlValueAccessor {

    @Input() disabled = false

    // There is no better way to limit the range of a number at the moment
    @Input() decimalPlaces: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 5
    @Input() allowNegative = true

    /** Max value **inclusive**. Set to `null` for no limit. */
    @Input() max: number | null = null
    /** Min value **inclusive**. Set to `null` for no limit. */
    @Input() min: number | null = null

    /**
     * Set this to `true` if you don't want the max value limit message
     * to pop-up when the user is trying to type over the limit
     */
    @Input() silentMaxWarning = false

    @Input() placeholder = ''
    @Input() suffix = ''

    @Input() injectedClass = ''
    @Input() textAlign: 'left' | 'center' | 'right' = 'right'
    @Input() injectedStyles: { [key: string]: any } = {}

    @Input() tabIndex: number

    @Input() blurToOriginalValue = false
    @Input() clearZeroOnFocus = false
    @Input() clearLeadingZero = true
    @Input() clearLeadingPlusSign = true

    // this is for help PlayWright find the input to interact during test.
    @Input() inputId = ''

    /**
     * Will format number to the given decimal places.
     * E.g. '92' will be formatted to '92.00' if decimalPlaces is set to 2
     */
    @Input() enforceDecimalOnBlur = false

    @Input() errorMessage = ''
    @Input() warningMessage = ''
    @Input() normalMessage = ''

    /**
     * If set to `true` will apply absolute positioning to the messages.
     * This prevent messages from pushing the input box up and moving
     * the overall form around but at a risk of text overlapping other
     * component below.
     *
     * Should only be use if the messages will fit in one line (
     * and only one type of message will be shown at a time).
     */
    @Input() useAbsolutePositionMessages = false

    /**
     * Will fire upon <input> losing focus (before blur event)
     * **if and only if** the input value differ from last blur.
     */
    @Output() emitWhenInputLosingFocus: EventEmitter<number | null> = new EventEmitter()
    @Output() inputFocus: EventEmitter<FocusEvent> = new EventEmitter()
    @Output() inputBlur: EventEmitter<FocusEvent> = new EventEmitter()

    /**
     * For now, this will NOT return key pressed. That require some
     * more validation to see if key is valid.
     */
    @Output() inputUpdate: EventEmitter<number> = new EventEmitter()

    /**
     * A closure that encapsulate and control popup box logic
     */
    public readonly popupController = (() => {
        let message = ''
        let shouldDisplayPopup = false
        let timer

        return {
            getMessage: () => message,
            shouldDisplayPopup: () => shouldDisplayPopup,
            displayPopup: (msg: string, duration = 3000) => {
                message = msg
                shouldDisplayPopup = true
                // Reset timer if one already exist
                if (timer) { clearTimeout(timer) }
                timer = setTimeout(() => {
                    shouldDisplayPopup = false
                }, duration)
            },
            dismissPopup: () => {
                shouldDisplayPopup = false
            }
        }

    })()

    protected readonly MAX_CHAR_LIMIT = 15

    @ViewChild('inputBox', { static: true })
    private inputElementRef: ElementRef
    /**
     * (ViewModel) The raw string being displayed by <input>.
     * This should never be return to the outside as the control
     * expect a type of number.
     */
    private currentString: string | null = null
    private _originalStringValue: string | null

    private _didClearZero = false

    /**
     * A flag to indicate that current change to the input
     * is caused by a pasting action.
     */
    private _isFromPaste = false

    constructor(private renderer: Renderer2) { }

    public get styles(): { [key: string]: any } {
        return { 'text-align': this.textAlign, ...this.injectedStyles }
    }

    /**
     * Getter for getting the float value that is to be returned
     * to the outside (through Angular's CVA interface).
     *
     * DO NOT bind to this through the HTML.
     * Use [ngModel], (ngModel), [(ngModel)] instead.
     */
    private get value(): number | null {
        return this.currentString && parseFloat(this.currentString)
    }

    // ----------------------------------------------------------------
    // Control Value Accessor Interfaces
    // ----------------------------------------------------------------
    // Will be called by Angular upon model value changed
    // either programmatically by Reactive Form OR by ngModel binding
    writeValue(val: number): void {
        if (isNotAValue(val)) {
            this.updateValue(null, false)
        } else {
            this.updateValue(val.toString(), false)
        }
        if (typeof this._originalStringValue === 'undefined') {
            this._originalStringValue = this.currentString
        }

        this.renderSuffix()

    }

    // The onChange() fn will be used to notify Angular to update
    // regitering ReactiveForm and NgModel binding
    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    // ----------------------------------------------------------------
    // <input> event binding functions
    // ----------------------------------------------------------------
    /**
     * Intercept the <input>'s onInput event and forward it to
     * our handleInput() function
     */
    onInput(event: InputEvent): void {
        event.stopPropagation()
        if (this.disabled) {
            // Force reverse the inner box value to last known value
            this.updateInnerInputBoxValue(this.currentString, 0)
            return
        }
        const input = event.target as HTMLInputElement
        const val = input.value
        this.updateValue(val)
        this.inputUpdate?.emit(parseFloat(val))
    }

    /**
     * This is fired *before* (blur) event **if and only if**
     * the value has changed from last (blur) event.
     */
    onInputChange(event: Event): void {
        event.stopPropagation()
        if (this.disabled) {
            // Force reverse the inner box value to last known value
            this.updateInnerInputBoxValue(this.currentString, 0)
            return
        }

        const rawString = (event.target as HTMLInputElement).value

        // Sole '-' or '.' doesn't have a value
        if (rawString === '-' || rawString === '.') {
            this.currentString = null
        }

        // If end with '.', remove it
        if (this.currentString && this.currentString.endsWith('.')) {
            this.currentString = this.currentString.slice(0, -1)
        }

        if (this.enforceDecimalOnBlur && this.decimalPlaces > 0) {
            if (this.currentString !== null && this.currentString.length > 0) {
                const [whole, decimal] = this.currentString.split('.')
                // This works since we limit our decimal places to 6 or less.
                const formattedString = '' + whole + '.' + ('' + (decimal || '') + '000000').substring(0, this.decimalPlaces)
                this.currentString = formattedString
            }
        }

        // Remove leading '+'
        if (this.clearLeadingPlusSign &&
            this.currentString && this.currentString.startsWith('+')) {
            this.currentString = this.currentString.substring(1)
        }

        // Add '0' in front of '.' if no number exist
        if (this.currentString) {
            this.currentString = this.currentString.replace(/^(-?)\.([0-9])/, '$10.$2')
        }

        // Remove leading zero except the one right in front of '.'
        if (this.clearLeadingZero && this.currentString) {
            this.currentString = this.currentString.replace(/^(-?)0*([0-9])/, '$1$2')
        }

        this.updateInnerInputBoxValue(this.currentString)
        // If min is set and value is least than min, empty the box and
        // pop up a message to the user.
        if (!isNotAValue(this.min) && !isNotAValue(this.value) && this.value < this.min) {
            this.popupController.displayPopup(`The value must be at least ${this.min}`)
            this.updateValue('')
        } else {
            this.popupController.dismissPopup()
        }

        this.onChange(this.value)
        this.emitWhenInputLosingFocus.emit(this.value)
    }

    onFocus(event: FocusEvent): void {
        // Remove suffix if focus is on the box
        this.removeSuffix()

        if (this.clearZeroOnFocus && this.value === 0) {
            this.updateValue('')
            this._didClearZero = true
        }
        this.inputFocus.emit(event)
    }

    onBlur(event: FocusEvent): void {
        if (this.clearZeroOnFocus && this.value === null && this._didClearZero) {
            this.updateValue('0')
            this._didClearZero = false
        }

        if (this.blurToOriginalValue &&
            !this.clearZeroOnFocus &&
            this.value === null &&
            !isNotAValue(this._originalStringValue)
        ) {
            this.updateValue(this._originalStringValue)
        }

        this.renderSuffix()

        this.inputBlur.emit(event)
        this.onTouched()
    }

    onPaste(event: FocusEvent): void {
        event.stopPropagation()
        this._isFromPaste = true
    }

    // ----------------------------------------------------------------

    /**
     * Handle logics for updating both the UI value and model value.
     * Will perform check to see if the input string is allow, and if not,
     * it will revert the UI back to allowed value.
     *
     * @param notifyModelOfChange - whether to call callbacks. Should be set to
     * false if we are updating the value programmatically.
     */
    private updateValue(newString: string, notifyModelOfChange = true): void {
        // Wrap the whole function call into a closure so we can augment it
        // at the end to add default return behaviour (clear `isFromPaste`).
        const _ = (() => {
            if (isNotAValue(newString)) {
                this.currentString = null
                this.updateInnerInputBoxValue(this.currentString)
                if (notifyModelOfChange) {
                    this.onChange(this.value)
                    this.onTouched()
                }
                return
            }

            // Allow leading '.'
            if (this.decimalPlaces > 0 && newString === '.') {
                return
            }

            // Handle cases where the user is trying to type "-" or "+" sign
            // when the field is still empty
            if ((this.allowNegative && newString.match(/^(-0?\.?)$/)) ||
                newString.match(/^(\+0?\.?)$/)
            ) {
                this.currentString = newString
                return
            }

            // Only allow number, '.' with proper format to be typed
            if (!newString.match(/^[+-]?[\d]{0,50}(\.|\.[\d]{0,50})?$/)) {
                // The inner <input> has already allow the change so we
                // need to reverse the value back to our last stored value
                this.updateInnerInputBoxValue(this.currentString, 0)
                return
            }

            // JS cannot handle long floating value so we want to limit the length
            // to be no longer than `MAX_CHAR_LIMIT` characters including '.' but not '-'
            // -
            // We are not doing this in the regex to allow copying and pasting of
            // a number longer than allow (the field will simply truncate overflow
            // instead of preventing the paste)

            // Remove negative sign if present
            const isNegative = newString.startsWith('-')
            const absString = isNegative ? newString.substring(1) : newString

            let truncatedString = absString

            // Truncate (not round) to the right decimal places
            const [whole, decimal] = absString.split('.')
            if (this.decimalPlaces === 0) {
                truncatedString = whole
            } else if (decimal && decimal.length > this.decimalPlaces) {
                truncatedString = '' + whole + '.' + decimal.substring(0, this.decimalPlaces)
            }

            if (truncatedString && (truncatedString.length > this.MAX_CHAR_LIMIT)) {
                if (!this._isFromPaste) {
                    // The user is trying to type the 16th character. We want to block this.
                    this.updateInnerInputBoxValue(this.currentString, 0)
                    return
                } else {
                    // In this case, the user is pasting a long string in,
                    // we want to truncate and not block the pasting
                    truncatedString = truncatedString.substring(0, this.MAX_CHAR_LIMIT)
                }
            }

            // Add back the negative sign if allow
            if (this.allowNegative && isNegative) {
                truncatedString = '-' + truncatedString
            }

            let floatValue = parseFloat(truncatedString)

            // Number above ~1e309 will turn into Infinity
            if (isNaN(floatValue) || floatValue > 1e100) {
                this.updateInnerInputBoxValue(this.currentString, 0)
                return
            }

            // Check MAX
            if (!isNotAValue(this.max) && floatValue > this.max) {
                this.updateInnerInputBoxValue(this.currentString, 0)
                if (!this.silentMaxWarning) {
                    this.popupController.displayPopup(`The value cannot exceed ${this.max}`)
                }
                return
            }

            // Finally, update inner string value
            if (!this.allowNegative && floatValue < 0) {
                floatValue = Math.abs(floatValue)
                this.currentString = floatValue && floatValue.toString()
            } else {
                this.currentString = truncatedString
            }

            this.updateInnerInputBoxValue(this.currentString)

            if (notifyModelOfChange) {
                this.onChange(this.value)
                this.onTouched()
            }
        })()

        this._isFromPaste = false

    }

    /**
     * Append suffix at the end if applicable.
     * This only affect the text displayed, not value
     * stored by the CVA.
     */
    private renderSuffix(): void {
        // Add suffix if given
        if (this.currentString && this.suffix) {
            this.updateInnerInputBoxValue(this.currentString + this.suffix)
        }
    }

    /**
     * Remove the suffix by patching the actual value stored by CVA
     * back into the field. Should be run first thing in onFocus().
     */
    private removeSuffix(): void {
        if (this.suffix) {
            // There is a bug that cause onFocus to run before selectionStart is set.
            // This push it back slightly so the value is set first
            setTimeout(() => {
                this.updateInnerInputBoxValue(this.currentString, 0)
            })
        }
    }

    /**
     * Update the <input> value and its UI.
     *
     * When the user type in the box, the box update its own value.
     * So, only use this in case you want to force the value to differ
     * programmatically. (e.g. when the user input an invalid char
     * and we want to delete it)
     *
     * @param offsetCaret set the offset, relative to current caret position,
     * to offset the caret to after the update (default to 1, meaning to move
     * it back by 1)
     */
    private updateInnerInputBoxValue(val: string, offsetCaret = 1): void {

        const caretStart = this.inputElementRef.nativeElement.selectionStart
        const caretEnd = this.inputElementRef.nativeElement.selectionEnd

        this.renderer.setProperty(this.inputElementRef.nativeElement, 'value', val)

        if (caretStart === caretEnd) {
            // The current caret will be forward by 1 already since we are getting position
            // before we enforce our own update
            const finalCaretPosition = caretStart - 1 + offsetCaret
            this.renderer.setProperty(this.inputElementRef.nativeElement, 'selectionStart', finalCaretPosition)
            this.renderer.setProperty(this.inputElementRef.nativeElement, 'selectionEnd', finalCaretPosition)
        } else {
            // If selection is a range (highlight), keep it highlighted
            this.renderer.setProperty(this.inputElementRef.nativeElement, 'selectionStart', caretStart)
            this.renderer.setProperty(this.inputElementRef.nativeElement, 'selectionEnd', caretEnd)
        }
    }

    private onChange: (...arg: any) => any = () => {
    }
    private onTouched: (...arg: any) => any = () => {
    }
}
