import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { SapDataSync } from '@app/models/sap-data-sync.model'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'

@Injectable({
    providedIn: 'root'
})
export class SapDataSyncService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/SapDataSync`

    constructor(
        private queryBuilderService: QueryBuilderService
    ) {
    }

    public getLatestSyncDate(): Observable<HttpResponse<SapDataSync>> {
        return this.queryBuilderService
            .get<SapDataSync>(this.url)
            .pipe(map(response => response))
    }
}
