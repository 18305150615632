import { Injectable } from '@angular/core'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { SetPoint, SetPointValue, ToleranceCalculationType } from '@app/modules/shared/models/engineering-units/setpoint.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { deepCopy, isNotAValue } from '@app/utils/app-utils.function'
import { EngineeringCalculationService } from './engineering-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class SetPointService {

    /**
     * Return formatted UOM string of the interested section from a given `setPoint`
     * @param section - `'setPoint' | 'expectedReading' | 'tolerance'`
     */
    public static getFormattedUOMBySection(
        setPoint: SetPointValue,
        section: 'setPoint' | 'expectedReading' | 'tolerance' = 'setPoint'
    ): string {
        if (isNotAValue(setPoint)) {
            return ''
        }

        switch (section) {
            case 'setPoint':
            case 'expectedReading':
                return setPoint[section]?.unitOfMeasurement?.uomCodeForTech ?? ''

            case 'tolerance':
                const {
                    setPoint: setPointValue,
                    expectedReading,
                    tolerance,
                    toleranceCalculationType,
                    measurementRange: span
                } = setPoint
                // TODO: [Span-based] fallback for old calculation style, should remove at later version
                if (isNotAValue(toleranceCalculationType)) {
                    return tolerance.unitOfMeasurement?.uomCodeForTech ?? ''
                }

                switch (toleranceCalculationType) {
                    case ToleranceCalculationType.PercentageOfSpan:
                        return `% of ${span} (span)`
                    default:
                        return expectedReading?.unitOfMeasurement?.uomCodeForTech ??
                            setPointValue?.unitOfMeasurement?.uomCodeForTech ?? ''
                }
        }
    }

    /**
     * @deprecated This function is only here to support old model before we switch to ToleranceCalculationType
     *
     * ----
     *
     * If you need to call this function, you are likely looking for `getAcceptableRange()` function,
     * which will infer the type for you.
     */
    public static inferToleranceCalculationType(setPoint: SetPoint): ToleranceCalculationType {
        const {
            setPoint: setPointUnitValue, expectedReading,
            tolerance, toleranceCalculationType,
            isExpectedReadingEdited
        } = setPoint

        if (!isNotAValue(toleranceCalculationType)) {
            return toleranceCalculationType
        }

        const { unitOfMeasurement: { uomCode: toleranceUOMCode } } = tolerance
        const { unitOfMeasurement: { uomCode: refUOMCode } }
            = isExpectedReadingEdited ? expectedReading : setPointUnitValue

        if (toleranceUOMCode === SpecialUomCode.Percentage && refUOMCode !== SpecialUomCode.Percentage) {
            return ToleranceCalculationType.Multiplicative
        }
        return ToleranceCalculationType.Additive
    }

    /**
     * Get acceptable or _valid_ range of a given setPoint
     * @param setPoint a complete SetPoint object with relevant tolerance for calculation
     */
    public static getAcceptableRange(setPoint: SetPoint, adjustedSetPointValue?: number): UnitRange {
        const {
            setPoint: setPointUnitValue,
            expectedReading,
            tolerance,
            toleranceCalculationType,
            measurementRange,
            isExpectedReadingEdited,
            isSetPointAdjustable
        } = setPoint

        let _toleranceCalculationType = toleranceCalculationType

        // Support old model data
        if (isNotAValue(_toleranceCalculationType)) {
            _toleranceCalculationType = SetPointService.inferToleranceCalculationType(setPoint)
        }

        let refSetPoint: UnitValue = isExpectedReadingEdited ? expectedReading : setPointUnitValue
        refSetPoint = deepCopy(refSetPoint)

        if (isSetPointAdjustable) {
            if (isNotAValue(adjustedSetPointValue)) {
                throw new Error('`isSetPointAdjustable` for this setPoint is set to `true` but `adjustedSetPointValue` was not provided!')
            }
            refSetPoint = {
                ...refSetPoint,
                value: adjustedSetPointValue
            }
        }

        return EngineeringCalculationService.calculateAcceptableRange(
            refSetPoint,
            tolerance,
            _toleranceCalculationType,
            { measurementRange }
        )
    }

    /**
     * @param value - the value input by technician to be checked.
     * @param refSetPoint - the reference setPoint. Most of the time this is the setPoint set by admin and is
     * contained in the template's model.
     * @param adjustedSetPointValue - in case the ref setPoint is adjustable by technician, the adjusted value
     * should be provided here.
     */
    public static isValueInAcceptableRange(value: number, refSetPoint: SetPoint, adjustedSetPointValue?: number): boolean {
        const acceptableRange = SetPointService.getAcceptableRange(refSetPoint, adjustedSetPointValue)
        return EngineeringCalculationService.isInRange(value, acceptableRange)
    }
}
