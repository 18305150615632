import { Modal } from '@app/modules/modal-container/models/modal.model'

export enum ActionType {
    DestroyModal = '[Modal] Destroy the modal by removing all reference',
    ShowModal = '[Modal] Show modal',
    HideModal = '[Modal] Hide modal'
}

export class HideModalAction {
    readonly type = ActionType.HideModal
}

export class DestroyModalAction {
    readonly type = ActionType.DestroyModal
}

export class ShowModalAction {
    readonly type = ActionType.ShowModal

    constructor(public payload: Modal) { }
}

export type ModalAction = HideModalAction
    | ShowModalAction
    | DestroyModalAction
