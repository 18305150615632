import { Injectable } from '@angular/core'

import { PolicyCheckerService } from './policy-checker.service'

@Injectable()
export class PolicyCheckerServiceInjectorHelper {
    private static policyCheckerService: PolicyCheckerService

    public constructor(service: PolicyCheckerService) {
        PolicyCheckerServiceInjectorHelper.policyCheckerService = service
    }

    public static getPolicyCheckerService(): PolicyCheckerService {
        return PolicyCheckerServiceInjectorHelper.policyCheckerService
    }
}
