import { Component, forwardRef, Input, OnChanges, OnInit, AfterViewInit, SimpleChanges } from '@angular/core'
import {
    AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule, FormsModule
} from '@angular/forms'
import { filter } from 'rxjs/operators'
import { SetPoint } from '@app/modules/shared/models/engineering-units/setpoint.model'
import { UnitOfMeasurement } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { SetPointService } from '@app/services/engineering-logic-services/setpoint.service'
import { deepCopy, round } from '@app/utils/app-utils.function'
import { scw } from '@app/utils/classes/simple-changes-wrapper'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '@app/store/app.store'
import { SpecialUomCode } from '@app/models/special-uom.model'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common'
import { CopyToInputDirective } from '@app/modules/directives/common/copy-to-input.directive'
import { NumericInputComponent } from '@app/modules/mrma-ui-components/controls/numeric-input/numeric-input.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { UomPresentationPipePipe } from '@app/modules/equipment/pipes/uom-desc-with-short-name-pipe'
import { FilterPercentUom } from '@app/modules/shared/pipes/filter-percent-uom.pipe'
import { MathAbsolutePipe } from '@app/modules/shared/pipes/math-absolute.pipe'

@Component({
    selector: 'app-setpoint-selector-row',
    templateUrl: './setpoint-selector-row.component.html',
    styleUrls: ['./setpoint-selector-row.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SetpointSelectorRowComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SetpointSelectorRowComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgStyle,
        NgIf,
        CopyToInputDirective,
        NumericInputComponent,
        DropdownComponent,
        UomPresentationPipePipe,
        FilterPercentUom,
        MathAbsolutePipe,
        FormsModule,
        AsyncPipe
    ]
})
export class SetpointSelectorRowComponent extends SafeUnsubscriberComponent
    implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, Validator {

    @Input() defaultSetPointName: string[]
    @Input() rowIndex: number
    @Input() isSharedSetPoint = 'true'
    @Input() isSetPointAdjustable = false
    @Input() isExpectedReadingEdited = false
    @Input() displayRow = false

    @Input() setPointUOM: UnitOfMeasurement
    @Input() expectedReadingUOM: UnitOfMeasurement
    @Input() toleranceUOM: UnitOfMeasurement

    public disabled = false
    public innerFormGroup: UntypedFormGroup

    public toleranceUomList: UnitOfMeasurement[]
    public uomList$: Observable<UnitOfMeasurement[]>

    private percentageUOMRef: UnitOfMeasurement

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected store: Store<AppState>
    ) {
        super()
    }

    public get rowStyle(): { [key: string]: any } {
        if (this.rowIndex % 2 === 0) {
            return { 'background-color': '#fbfbfd', 'padding-bottom': '30px', 'padding-top': '30px' }
        }
        if (this.rowIndex % 2 !== 0) {
            return { 'background-color': '#ffffff', 'padding-bottom': '30px', 'padding-top': '30px' }
        }
    }

    onTouched: any = () => { }

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeInAny(['setPointUOM', 'expectedReadingUOM', 'toleranceUOM'])
            .do(() => {
                // First row doesn't really need sync
                // we exclude first row to make sure that patchUOM doesn't run and broke first row dropdown UI
                if (this.rowIndex > 1) {
                    this.patchUOM()
                }
            })

            .forChangeIn('isSharedSetPoint')
            .do(change => {
                if (change.currentValue === 'true') {
                    // First row doesn't really need sync
                    // we exclude first row to make sure that patchUOM doesn't run and broke first row dropdown UI
                    if (this.rowIndex > 1) {
                        this.patchUOM()
                    }
                }
            })

            .forChangeIn('isSetPointAdjustable')
            .do(change => {
                if (change.firstChange) {
                    return
                }
                const isSetPointAdjustable = change.currentValue
                this.innerFormGroup.patchValue({ isSetPointAdjustable })
            })

            .forChangeIn('isExpectedReadingEdited')
            .do(change => {
                if (change.firstChange) {
                    return
                }
                const isExpectedReadingEdited = change.currentValue

                this.innerFormGroup.patchValue({ isExpectedReadingEdited })

                if (isExpectedReadingEdited === false) {

                    // Sync expected reading UOM with setpoint UOM
                    this.syncExpectedReadingWithSetPoint()

                    // Sync tolerance UOM with setpoint UOM
                    const uom = this.innerFormGroup.get('setPoint.unitOfMeasurement').value
                    this.patchToleranceUOMIfNotPercentage(uom, true)
                    this.updateToleranceUOMList(uom)
                } else {

                    // Sync tolerance UOM with expected reading UOM
                    const uom = this.innerFormGroup.get('expectedReading.unitOfMeasurement').value
                    this.patchToleranceUOMIfNotPercentage(uom, true)
                    this.updateToleranceUOMList(uom)
                }
            })

    }

    validate(control: AbstractControl): ValidationErrors {
        return this.innerFormGroup.valid ? null : { invalidSetPoint: { message: `The setpoint at ${this.rowIndex} is invalid.` } }
    }

    writeValue(v: SetPoint): void {
        if (!v) { return }
        this.innerFormGroup.patchValue(v, { emitEvent: false })
    }

    registerOnChange(fn: any): void {
        this.addSubscription(
            this.innerFormGroup.valueChanges.subscribe(fn)
        )
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
        this.disabled ? this.innerFormGroup?.disable({ emitEvent: false }) : this.innerFormGroup?.enable({ emitEvent: false })
    }

    ngOnInit(): void {

        this.initTemplateData()
        this.uomList$ = this.store.select(uomList)

        // Sync expectedReading with setPoint if the former is not in edit mode
        this.addSubscription(
            this.innerFormGroup.controls['setPoint'].valueChanges.pipe(
                filter(() => !this.isExpectedReadingEdited)
            ).subscribe(this.syncExpectedReadingWithSetPoint.bind(this))
        )

        this.initUOMList()
        
    }

    ngAfterViewInit(): void {
        // Update ToleranceUOMList again just in case where ngOnInit subscription return null
        this.updateToleranceUOMList(this.innerFormGroup.get('expectedReading.unitOfMeasurement').value as UnitOfMeasurement, false)
    }

    public expectedRange(): UnitRange {
        if (this.innerFormGroup.status === 'DISABLED' || this.innerFormGroup.status === 'VALID') {
            try {
                const mockAdjustedSetPoint = (this.innerFormGroup.value as SetPoint).setPoint.value
                const expectedRange = SetPointService.getAcceptableRange(this.innerFormGroup.value, mockAdjustedSetPoint)
                expectedRange.minimumRange = round(expectedRange.minimumRange)
                expectedRange.maximumRange = round(expectedRange.maximumRange)
                return expectedRange
            } catch (e) {
                // Sometime we will run into UnitMismatch error due to RACE a condition in UOM setting.
                // It should be corrected in the next CD so this should not crash the app.
                console.warn(e)
            }
        }
    }

    public updateLowerRange(val: number | null): void {
        const patch: any = {
            tolerance: {
                lowerRange: 0 - val // Convert to number
            }
        }
        this.innerFormGroup.patchValue(patch)
    }

    public updateHigherRange(val: number | null): void {
        const patch: any = {
            tolerance: {
                higherRange: +val   // Convert to number
            }
        }
        this.innerFormGroup.patchValue(patch)
    }

    private initTemplateData(): void {
        this.innerFormGroup = this.formBuilder.group(
            {
                name: '',
                pointNumber: this.formBuilder.control({ value: this.rowIndex, disabled: this.disabled }, Validators.required),
                setPoint: this.formBuilder.group({
                    value: this.formBuilder.control({ value: null, disabled: this.disabled }, Validators.required),
                    unitOfMeasurement: this.formBuilder.control(this.setPointUOM, Validators.required),
                }),
                expectedReading: this.formBuilder.group({
                    // no validator for value as it can be null when isExpectedReadingEdited is false
                    value: this.formBuilder.control({ value: null, disabled: this.disabled }),
                    unitOfMeasurement: this.formBuilder.control(this.expectedReadingUOM, Validators.required)
                }),
                tolerance: this.formBuilder.group({
                    higherRange: this.formBuilder.control({ value: null, disabled: this.disabled }, Validators.required),
                    lowerRange: this.formBuilder.control({ value: null, disabled: this.disabled }, Validators.required),
                    unitOfMeasurement: this.formBuilder.control({ value: this.toleranceUOM, disabled: this.disabled }, Validators.required)
                }),
                isSetPointAdjustable: this.formBuilder.control({ value: this.isSetPointAdjustable, disabled: this.disabled }, Validators.required),
                isExpectedReadingEdited: this.formBuilder.control(
                    { value: this.isExpectedReadingEdited, disabled: this.disabled },
                    Validators.required
                )
            }
        )
    }

    private syncExpectedReadingWithSetPoint(): void {
        if (!this.innerFormGroup) {
            return
        }
        const setPoint = this.innerFormGroup.get('setPoint').value
        this.innerFormGroup.patchValue({
            expectedReading: deepCopy(setPoint)
        })
    }

    private initUOMList(): void {
        this.addSubscriptions([

            this.store.select(uomList).subscribe(uoms => {
                const percentageUOMRef = deepCopy(uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage))
                this.percentageUOMRef = percentageUOMRef
                this.updateToleranceUOMList(this.innerFormGroup.get('expectedReading.unitOfMeasurement').value as UnitOfMeasurement, false)
            }),

            this.innerFormGroup.get('setPoint.unitOfMeasurement').valueChanges.subscribe(uom => {
                if (!this.isExpectedReadingEdited) {
                    this.updateToleranceUOMList(uom)
                }
            }),

            this.innerFormGroup.get('expectedReading.unitOfMeasurement').valueChanges.subscribe(uom => {
                if (this.isExpectedReadingEdited) {
                    this.updateToleranceUOMList(uom)
                }
            }),
        ])
    }

    private updateToleranceUOMList(uom: UnitOfMeasurement, patchvalue = true): void {
        const _uom = deepCopy(uom)

        if (!_uom) {
            this.toleranceUomList = []
        } else if (_uom.uomCode === SpecialUomCode.Percentage) {
            this.toleranceUomList = [this.percentageUOMRef]
        } else {
            this.toleranceUomList = [_uom, this.percentageUOMRef]
        }

        if (patchvalue) {
            this.patchToleranceUOMIfNotPercentage(_uom)
        }
    }

    private patchToleranceUOMIfNotPercentage(uom: UnitOfMeasurement, force = false): void {
        if (force || this.innerFormGroup.get('tolerance.unitOfMeasurement')?.value?.uomCode !== SpecialUomCode.Percentage) {
            this.innerFormGroup.get('tolerance.unitOfMeasurement').patchValue(uom)
        }
    }

    private patchUOM({ emitEvent = true } = {}): void {
        if (!this.innerFormGroup) {
            return
        }

        this.innerFormGroup.patchValue({
            setPoint: {
                unitOfMeasurement: this.setPointUOM
            },
            expectedReading: {
                unitOfMeasurement: this.expectedReadingUOM
            },
            tolerance: {
                unitOfMeasurement: this.toleranceUOM
            }
        }, { emitEvent })

        if (emitEvent && this.onTouched) {
            this.onTouched()
        }
    }
}
