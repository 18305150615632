import { Injectable } from '@angular/core'

import { getRandomItemFromList } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class LaunchScreenLoaderService {

    private readonly baseURI = './assets/images/loaders/'
    private readonly genericLoaders = ['drawing-loader.svg', 'engineer.svg']

    private readonly halloweenLoaders = ['halloween-pumpkin-girl.svg']
    private readonly christmasLoaders = ['snowman.svg', 'snowglobe.svg']

    public getLoaderImgSrc(): string {
        return this.baseURI + this.getRandomImg()
    }

    private getRandomImg(): string {
        const now = new Date()
        const month = now.getMonth() + 1
        const date = now.getDate()

        switch (month) {

            case 10: // Oct
                if (date >= 30) {
                    return getRandomItemFromList(this.halloweenLoaders)
                }
                break

            case 12: // Dec
                if (date > 20) {
                    return getRandomItemFromList(this.christmasLoaders)
                }
                break

            default:
                return getRandomItemFromList(this.genericLoaders)
        }

        return getRandomItemFromList(this.genericLoaders)
    }

}
