import { ProcessConfiguration } from '@app/modules/equipment/models/process.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { ProcessAction, ActionType } from '../actions/process.actions'

export interface ProcessState {
    process: Process[],
    configuration: ProcessConfiguration[]
}

export const initialState: ProcessState = {
    process: [],
    configuration: []
}

export function reducer(state = initialState, action: ProcessAction): ProcessState {
    switch (action.type) {
        case ActionType.GetProcessesSuccess:
            return {
                ...state,
                process: [...action.payload]
            }
        case ActionType.GetProcessConfigurationsSuccess:
            return {
                ...state,
                configuration: [...action.payload]
            }
        default:
            return state
    }
}
