import { Route } from '@angular/router'

export const routes: Route[] = [
    {
        // Needed for hash routing
        path: 'code',
        loadChildren: () => import('@app/modules/home/home-routing.module').then(m => m.HomeRoutingModule)
    },
    {
        path: '',
        loadChildren: () => import('@app/modules/home/home-routing.module').then(m => m.HomeRoutingModule)
    }
]
