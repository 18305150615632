import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { EquipmentTemplateCoreDetails } from '../models/equipment-template-core-details.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentTemplateService {
    private readonly equipmentTypesURL = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments`
    private readonly equipmentTemplateURL = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Templates`

    constructor(private baseApiService: QueryBuilderService) { }

    public getEquipmentTemplateDetails(equipmentId: string): Observable<EquipmentTemplateCoreDetails[]> {
        const urlQuery = this.equipmentTypesURL + `(${equipmentId})/Templates?isActive=true`
        return this.baseApiService
            .get<EquipmentTemplateCoreDetails[]>(urlQuery)
            .pipe(map(response => response.body))
    }

    public updateEquipmentTemplate(equipmentTemplate: EquipmentTemplateCoreDetails): Observable<any> {
        const urlQuery = this.equipmentTemplateURL + `(${equipmentTemplate.id})`
        return this.baseApiService
            .put<EquipmentTemplateCoreDetails>(urlQuery, equipmentTemplate)
            .pipe(map(response => response.body))
    }

    public addEquipmentTemplate(equipmentTemplate: EquipmentTemplateCoreDetails): Observable<any> {
        const urlQuery = this.equipmentTemplateURL
        return this.baseApiService
            .post<EquipmentTemplateCoreDetails>(urlQuery, equipmentTemplate)
            .pipe(map(response => response.body))
    }
}
