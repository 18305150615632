<div class="relative">
    <input class="em-c-input"
           type="text"
           [ngModel]="searchText$ | async"
           (ngModelChange)="searchText.next($event)"
           (focus)="setFocusState()"
           [ngClass]="{'red-box': control.errors }"
           [disabled]="control.disabled"
           [placeholder]="placeholder"
           [id]="inputId" />
    <div *ngIf="dataSource$ | async as items"
         class="dropdown em-c-field__menu"
         [ngClass]="{'em-is-active': selectionFocus}">
        <ul class="em-c-typeahead-list">
            <li class="em-c-typeahead-list__item"
                *ngFor="let item of items"
                [ngClass]="{'em-is-disabled': item.disabled}"
                (click)="select(item)">
                {{item[displayField]}}
            </li>
        </ul>
    </div>
    <div class="remove-button"
         *ngIf="showRemoveButton()"
         (click)="unselect()">
        <svg class="em-c-icon em-c-icon--small">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#icon-x"></use>
        </svg>
    </div>
</div>
