<app-admin-form-row [formGroup]="weightTemplateForm"
                    label="PROCESS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="weightTemplateForm"
                      [formControlPath]="'process'"
                      [options]="(processes$ | async)"
                      [key]="'id'"
                      [value]="'name'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="weightTemplateForm"
                    label="TEMPLATE ALIAS">
    <div class="em-l-grid__item admin-input-width">
        <input formControlName="alias"
               type="text"
               class="em-c-input em-u-font-style-regular"
               maxlength="20">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="weightTemplateForm"
                    label="WEIGHT *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="weightTemplateForm.controls.input"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async | filterPercentUom)"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
    </div>
    <div formGroupName="input"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': weightTemplateForm.disabled}">
        <app-numeric-input formControlName="maximumRange"
                           [clearZeroOnFocus]="true">
        </app-numeric-input>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="weightTemplateForm"
                    label="TOLERANCE *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="weightTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async)"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
    </div>
    <div formGroupName="tolerance"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': weightTemplateForm.disabled}">
        <app-numeric-input formControlName="value"
                           [clearZeroOnFocus]="true">
        </app-numeric-input>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="weightTemplateForm"
                    label="PROCEDURE NUMBER">
    <div class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': weightTemplateForm.disabled}">
        <input formControlName="procedure"
               type="text"
               placeholder="B4P-RXG-XXX.doc"
               class="em-c-input em-u-font-style-regular">
    </div>
</app-admin-form-row>
