import { Action } from '@ngrx/store'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'

export enum ActionType {
    GetToDo = '[To-do] Get To-do list',
    GetToDoSuccess = '[To-do] Get to-do list success',
    AddToDo = '[To-do] Add To-do item',
    AddToDoSuccess = '[To-do] Add to-do item success',
    AddToDoFailure = '[To-do] Add to-do item failure',
    AddAllToDos = '[To-do] Add all To-do items',
    AddAllToDosSuccess = '[To-do] Add all to-do items success',
    AddAllToDosFailure = '[To-do] Add all to-do items failure',
    DeleteToDo = '[To-do] Delete To-do item',
    DeleteToDoSuccess = '[To-do] Delete to-do item success',
    DeleteToDoFailure = '[To-do] Delete to-do item failure',
    DeleteToDos = '[To-do] Delete To-do items',
    DeleteAllToDosSuccess = '[To-do] Delete all to-do items success',
    DeleteAllToDosFailure = '[To-do] Delete all to-do items failure',
    UpdateToDoWorkOrderStatus = '[To-do] Update Work Order to-do status'
}

export class GetToDoAction implements Action {
    public readonly type = ActionType.GetToDo
}

export class GetToDoSuccessAction implements Action {
    public readonly type = ActionType.GetToDoSuccess

    constructor(public payload: WorkOrderListItem[]) { }
}

export class AddToDoAction implements Action {
    public readonly type = ActionType.AddToDo

    constructor(public payload: WorkOrderListItem) { }
}

export class AddToDoActionSuccess implements Action {
    public readonly type = ActionType.AddToDoSuccess

    constructor(public payload: WorkOrderListItem) { }
}

export class AddToDoActionFailure implements Action {
    public readonly type = ActionType.AddToDoFailure

    constructor(public payload: WorkOrderListItem) { }
}

export class AddAllToDosAction implements Action {
    public readonly type = ActionType.AddAllToDos

    constructor(public payload: WorkOrderListItem[]) { }
}

export class AddAllToDosActionSuccess implements Action {
    public readonly type = ActionType.AddAllToDosSuccess

    constructor(public payload: WorkOrderListItem[]) { }
}

export class AddAllToDosActionFailure implements Action {
    public readonly type = ActionType.AddAllToDosFailure

    constructor(public payload: WorkOrderListItem[]) { }
}

export class DeleteToDoAction implements Action {
    public readonly type = ActionType.DeleteToDo

    constructor(public payload: WorkOrderListItem) { }
}

export class DeleteToDoActionSuccess implements Action {
    public readonly type = ActionType.DeleteToDoSuccess

    constructor(public payload: WorkOrderListItem) { }
}

export class DeleteToDoActionFailure implements Action {
    public readonly type = ActionType.DeleteToDoFailure

    constructor(public payload: WorkOrderListItem) { }
}

export class DeleteToDosAction implements Action {
    public readonly type = ActionType.DeleteToDos

    constructor(public payload: WorkOrderListItem[]) { }
}

export class DeleteAllToDosActionSuccess implements Action {
    public readonly type = ActionType.DeleteAllToDosSuccess

    constructor(public payload: WorkOrderListItem[]) { }
}

export class DeleteAllToDosActionFailure implements Action {
    public readonly type = ActionType.DeleteAllToDosFailure

    constructor(public payload: WorkOrderListItem[]) { }
}

export class UpdateWorkOrderStatusInTodoListAction implements Action {
    public readonly type = ActionType.UpdateToDoWorkOrderStatus

    constructor(public calibrationDetails: CalibrationDetails) { }
}

export type ToDoAction = GetToDoAction
    | GetToDoSuccessAction
    | AddToDoAction
    | AddToDoActionSuccess
    | AddToDoActionFailure
    | AddAllToDosAction
    | AddAllToDosActionSuccess
    | AddAllToDosActionFailure
    | DeleteToDoAction
    | DeleteToDoActionSuccess
    | DeleteToDoActionFailure
    | DeleteToDosAction
    | DeleteAllToDosActionSuccess
    | DeleteAllToDosActionFailure
    | UpdateWorkOrderStatusInTodoListAction

