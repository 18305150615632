import { PageComponentState } from '@app/models/page-component-state.model'
import { ActionType, PageHistoryAction } from './page-history.actions'
import { deepCopy } from '@app/utils/app-utils.function'

export interface PageHistoryState {
    pageState: PageComponentState[]
}

export const initialState: PageHistoryState = {
    pageState: []
}

export function reducer(state = initialState, action: PageHistoryAction): PageHistoryState {
    switch (action.type) {
        case ActionType.LogToPageHistory:
            const tempPageState = deepCopy(state.pageState) as PageComponentState[]
            const arrIndex = tempPageState.findIndex(x => x.name === action.payload.name)
            if (arrIndex >= 0) {
                tempPageState[arrIndex] = action.payload
            } else {
                tempPageState.push(action.payload)
            }
            return {
                ...state,
                pageState: tempPageState
            }
        default:
            return state
    }
}
