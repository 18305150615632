import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'

import { HyperlinkService } from '@app/modules/calibration/services/hyperlink.service'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { modal } from '@app/store/modal/modal.selectors'
import { UnityIconComponent } from '../unity-icon/unity-icon.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-equipment-external-link-modal',
    templateUrl: './equipment-external-link-modal.component.html',
    styleUrls: ['./equipment-external-link-modal.component.scss'],
    standalone: true,
    imports: [NgIf, UnityIconComponent]
})
export class EquipmentExternalLinkModalComponent extends SafeUnsubscriberComponent implements OnInit {

    public title: string
    public externalUrl: string
    public isBrowserSupportedLink = true

    constructor(private store: Store<AppState>, private toastrService: ToastrService, private hyperlinkService: HyperlinkService) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(modal).subscribe((modalState: Modal) => {
                if (modalState) {
                    this.title = modalState.title
                    this.externalUrl = modalState.params.externalLink
                    this.isBrowserSupportedLink = this.hyperlinkService.isBrowserSupportedLink(modalState.params.externalLink)
                }
            })
        )
    }

    public async copyClipboardLink(): Promise<void> {
        await navigator.clipboard.writeText(this.externalUrl).catch(e => {
            this.toastrService.error('Something went wrong. Please try again or copy the URL manually.')
            console.error(e)
        })
        this.toastrService.info(`"${this.externalUrl}" copied to clipboard.`)
    }
}
