import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { IExceptionTelemetry } from '@microsoft/applicationinsights-common'

import { AppMonitoringService } from '@app/services/app-monitoring.service'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any): void {
        if (error['__ErrorHandled']) {
            return
        }
        const appMonitoringService = this.injector.get(AppMonitoringService)

        appMonitoringService?.logErrorMessageToConsole(error, 'GLOBAL_ERROR_HANDLER')

        if (!appMonitoringService) {
            return
        }
        const exceptionTelemetry: IExceptionTelemetry = {
            error,
            properties: {
                source: 'GLOBAL_ERROR_HANDLER'
            }
        }
        appMonitoringService.instance?.trackException(exceptionTelemetry)
    }
}
