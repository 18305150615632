import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

import { RectGhostLoadingComponent } from '@app/modules/mrma-ui-components/ghost-loading/rect-ghost-loading/rect-ghost-loading.component'

@Directive({
    selector: '[appRectGhostLoadingIf]',
    standalone: true
})
export class RectGhostLoadingIfDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private vcRef: ViewContainerRef
    ) { }

    @Input() set appRectGhostLoadingIf(shouldShowLoading: boolean) {
        if (!shouldShowLoading) {
            this.vcRef.clear()
            this.vcRef.createEmbeddedView(this.templateRef)
        } else {
            this.vcRef.clear()
            this.vcRef.createComponent(RectGhostLoadingComponent)
        }
    }

}
