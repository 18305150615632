import { Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationResultStatus, calibrationResultStatusValue } from '../models/calibration-result-status.model'

@Injectable({
    providedIn: 'root'
})
export class CalibrationResultStatusService {

    constructor() { }

    public isCalibrationDeferredOrNotTested(resultStatus: string | CalibrationResultStatus): boolean {
        return this.isCalibrationDeferred(resultStatus) || this.isCalibrationNotTested(resultStatus)
    }

    public isCalibrationDeferred(resultStatus: string | CalibrationResultStatus): boolean {
        if (isNotAValue(resultStatus)) {
            return false
        }
        if (typeof resultStatus === 'string') {
            return resultStatus === mrmaStatus.resultStatus.deferred.name
        } else if (!isNotAValue(resultStatus.id)) {
            return resultStatus.id === mrmaStatus.resultStatus.deferred.id
        } else {
            throw Error('Unknown calibrationResultStatus type.')
        }
    }

    public isCalibrationNotTested(resultStatus: string | CalibrationResultStatus): boolean {
        if (isNotAValue(resultStatus)) {
            return false
        }
        if (typeof resultStatus === 'string') {
            return resultStatus === mrmaStatus.resultStatus.notTested.name
        } else if (!isNotAValue(resultStatus.id)) {
            return resultStatus.id === mrmaStatus.resultStatus.notTested.id
        } else {
            throw Error('Unknown calibrationResultStatus type.')
        }
    }

    /**
     * Assign value of `calibrationResultStatusEnum` to the appropriate `calibrationResultStatusForm` FormControl
     * @param calibrationResultStatusFormControl the form control to set this value to (the calibrationResultStatus field)
     * @param calibrationResultStatusEnum is the status to assign to the given form control
     */
    public updateCalibrationResultStatus(
        calibrationResultStatusFormControl: UntypedFormControl,
        calibrationResultStatusEnum: CalibrationResultStatusEnum
    ): void {
        switch (calibrationResultStatusEnum) {
            case CalibrationResultStatusEnum.Passed:
                calibrationResultStatusFormControl.setValue(calibrationResultStatusValue.passed)
                break
            case CalibrationResultStatusEnum.Failed:
                calibrationResultStatusFormControl.setValue(calibrationResultStatusValue.failed)
                break
            case CalibrationResultStatusEnum.FailedAdjustedPassed:
                calibrationResultStatusFormControl.setValue(calibrationResultStatusValue.failedAdjustedPassed)
                break
            default:
                calibrationResultStatusFormControl.setValue(undefined)
        }
    }
}
