import { ApplicationRef, InjectionToken, NgModule, NgZone } from '@angular/core'
import { ActionReducerMap } from '@ngrx/store'
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'
import { AppState } from '@app/store/app.store'
import { environment } from '@environments/environment'
import { AppMonitoringService } from './services/app-monitoring.service'
import { PolicyCheckerServiceInjectorHelper } from './services/policy-checker-injector-helper.service'
import { dateToLocalISOString } from './utils/date.utils'

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer')

@NgModule()
export class AppModule {

    constructor(
        applicationRef: ApplicationRef,
        private ngZone: NgZone,
        toastrService: ToastrService,
        appMonitoringService: AppMonitoringService,
        // Do not remove @AccessibleByPolicy() use this behind the scene
        // This is an injector method that allow angular DI to be use statically anywhere
        private policyCheckerInjectorHelper: PolicyCheckerServiceInjectorHelper
    ) {
        const envName = environment.name
        const toastrErrorFunc = toastrService.error.bind(toastrService)
        toastrService.error = (
            message?: string,
            title?: string,
            override?: Partial<IndividualConfig>,
            includeSessionInfo = true
        ): ActiveToast<any> => {

            if (!includeSessionInfo) {
                return toastrErrorFunc(message, title, override)
            }

            const dateTime = dateToLocalISOString(new Date())
            const shortSessionId = appMonitoringService.sessionId?.substring(0, 6) ?? '-'

            const messageWithSessionInfo = `
            ${message}

            <span class="toaster-error-meta">
            (${dateTime}) SessionId: ${shortSessionId} - ${envName}
            </span>
            `
            return toastrErrorFunc(messageWithSessionInfo, title, {
                ...override,
                enableHtml: true
            })
        }

        if (environment.development && environment?.debug?.logCDTime) {
            // Monkey Patch Angular's CD to print CD time
            const originalTick = applicationRef.tick

            const logLevel = environment?.debug?.logLevel
            const logTemplate = ['%c🚀 CD TIME %c %s ms', 'color: #5BBB96; background-color: #47458B;']
            const warningTextStyle = 'color: #E30505'
            const normalTextStyle = 'color: #9966FF'

            let count = 0
            let culmulativeTimeMs = 0
            this.ngZone.runOutsideAngular(() => {
                setInterval(() => {
                    if (count > 50 || culmulativeTimeMs > 1000) {
                        console.warn('CD ran', count, 'times totalling', culmulativeTimeMs, 'ms in the last 10 seconds')
                    }
                    count = 0
                    culmulativeTimeMs = 0
                }, 10000)
            })

            // Arrow function will not work here since we need "this"
            // eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types, space-before-function-paren
            applicationRef.tick = function () {
                const windowsPerfomance = window.performance
                const before = windowsPerfomance.now()
                const retValue = originalTick.apply(this, arguments)
                const after = windowsPerfomance.now()
                const runTime = after - before

                count++
                culmulativeTimeMs += runTime

                if (runTime > 100) {
                    window.console.warn(...logTemplate, warningTextStyle, runTime)
                } else if (logLevel === 'INFO') {
                    window.console.info(...logTemplate, normalTextStyle, runTime)
                } else {
                    // On Chrome, this is `verbose`
                    window.console.debug(...logTemplate, normalTextStyle, runTime)
                }

                return retValue
            }
        }
    }
}
