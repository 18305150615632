import { Injectable } from '@angular/core'

import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { Policy } from '@app/models/app-policy/policy.model'
import { PermissionService } from '@app/modules/shared/services/permission.service'
import { UserProfile } from '../models/user.model'

@Injectable({
    providedIn: 'root'
})
export class AppPolicy {
    private policies: Policy[] = []

    constructor(protected permissionService: PermissionService) { }

    public addPolicy(appPolicy: Policy): void {
        this.policies.push(appPolicy)
    }

    public checkPolicy(policyType: AppPolicyType, user: UserProfile, plantCode: string): boolean {
        if (!this.policies || this.policies.length === 0) {
            return false
        }

        const policy = this.policies.find(p => p.type === policyType)
        return policy?.hasPolicy(this.permissionService, user, plantCode)
    }
}
