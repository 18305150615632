import { Tolerance } from './tolerance.model'
import { UnitRange } from './unit-range.model'
import { UnitValue } from './unit-value.model'

/**
 * Contain numeric or quantitative value of the setpoint.
 */
export interface SetPointValue {
    setPoint: UnitValue
    expectedReading: UnitValue
    tolerance: Tolerance
    toleranceCalculationType: ToleranceCalculationType
    measurementRange?: UnitRange
}

/**
 * Contain App Layer's configurations configured by the admin.
 */
export interface SetPointConfig {
    /**
     * Indicate whether the setPoint value set by admin is overridable in
     * the calibration screen. (aka injected/adjustable)
     */
    isSetPointAdjustable: boolean

    /**
     * Indicate whether an expected reading is set by admin.
     */
    isExpectedReadingEdited: boolean
}

/**
 * Contain Data Layer's meta data.
 */
export interface SetPointMeta {
    /**
     * @deprecated - seems like pointNumber was used instead...
     * To strictly keep the point's index in template like
     * multipoint.
     */
    index?: number

    /**
     * Name given to a setpoint by admin
     */
    name?: string

    /**
     * Like the index but start at 1
     */
    pointNumber?: number
}

/**
 * An object representing a SetPoint's definition by MRMA.
 * It combines the Quantitative Part (SetPointValue),
 * App Layer Configuration (SetPointConfig),
 * and Meta Data (SetPointMeta) into a single type/object
 * representing MRMA's setpoint.
 *
 * If a function only work with value of the set point
 * (e.g. calculation logics) you should use the SetPointValue
 * interface instead.
 */
export type SetPoint = SetPointValue & SetPointConfig & SetPointMeta

/**
 * Define the calculation method that should be used to calculate
 * acceptable range for a given setpoint.
 */
export enum ToleranceCalculationType {
    /**
     * Add/subtract tolerance to the setpoint
     */
    Additive = 'Additive',

    /**
     * Treat the tolerance as Percentage **(the tolerance WILL be divided by 100)**
     * then multiply it with setpoint to get the final value
     */
    Multiplicative = 'Multiplicative',

    /**
     * Like `Multiplicative` but will multiply the tolerance with a given `measurementRange`
     * instead of to the `setPoint` itself. Use this for those setpoints that are part of a bigger _span_.
     */
    PercentageOfSpan = 'PercentageOfSpan'
}
