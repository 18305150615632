import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { User, UserOrder } from '@app/models/user.model'
import { FileDownloadService } from '@app/services/file-download.service'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { MsalService } from '@azure/msal-angular'

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Users`

    constructor(
        private queryBuilderService: QueryBuilderService,
        private fileDownloadService: FileDownloadService,
        private toastrService: ToastrService,
        private authService: MsalService
    ) {
    }

    public getMe(): Observable<User> {
        return this.queryBuilderService
            .get<User>(`${this.url}/Me`)
            .pipe(map(response => response.body))
            .pipe(catchError((err: HttpErrorResponse, caught) => {
                if (err.status === 404) {
                    return of(null as User)
                }
                throw err
            }))
    }

    public update(user: User): Observable<User> {
        return this.queryBuilderService
            .put<User>(`${this.url}(${user.guid})`, user)
            .pipe(map(response => response.body))
    }

    public getAll(forceFetch: boolean): Observable<HttpResponse<User[]>> {
        return this.queryBuilderService
            .get<User[]>(this.url, {
                includeExpired: true,
                includeInactive: true,
                isAttentionNeeded: false,
                sortBy: 'DisplayName',
                perPage: 'all',
                forceFetch
            })
            .pipe(map(response => response))
    }

    public sortUserOrder(userList: UserOrder[]): UserOrder[] {
        return [...userList].sort((a, b) => a.displayOrder - b.displayOrder)
    }

    public getUserReportExcel(): void {
        this.toastrService.info('Downloading user report... please wait a few seconds!')
        this.queryBuilderService
            .getFile(`${this.url}/Reports`, {
                includeExpired: true,
                includeInactive: true,
                isAttentionNeeded: false,
                sortBy: 'DisplayName',
                perPage: 'all'
            })
            .subscribe(response => this.fileDownloadService.downloadFile(response))
    }

    public getUserListFetchingProgress(): Observable<boolean> {
        return this.queryBuilderService
            .get<boolean>(`${this.url}/IsUserListFetchingInProgress`)
            .pipe(map(response => response.body))
    }

    public clearIndividualUserCache(): void {
        this.queryBuilderService
            .get(`${this.url}/Me/ClearCache`)
            .subscribe(() => {
                this.authService.logout()
            })
    }
}
