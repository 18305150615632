import { Store } from '@ngrx/store'

import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { AppState } from '@app/store/app.store'
import { SetLoaderAction, RemoveLoaderAction } from '@app/store/loader/loader.actions'

export class ShowLoaderStrategy extends ResponseHandlingStrategy {
    constructor(next: ResponseHandlingStrategy, private store: Store<AppState>) {
        super(next)
    }

    public init(): void {
        this.store.dispatch(new SetLoaderAction())
        super.init()
    }

    public finalize(): void {
        this.store.dispatch(new RemoveLoaderAction())
        super.finalize()
    }
}
