import { createSelector } from '@ngrx/store'

import { SpecialTemplateId } from '@app/constants/app-special.constants'
import { isEmptyObject } from '@app/utils/app-utils.function'
import { CalibrationState } from './calibration.reducer'

export const calibrationState = (state): CalibrationState => state.calibration

export const calibrationDetails = createSelector(calibrationState, calibration => calibration.currentCalibration)
export const cacheCalibrations = createSelector(calibrationState, calibration => calibration.cacheCalibrations)
export const selectedTemplateId = createSelector(calibrationState, calibration => {
    if (!calibration?.currentCalibration || isEmptyObject(calibration.currentCalibration)) {
        return null
    }

    return calibration?.currentCalibration?.templateId ?? SpecialTemplateId.NoTemplateTemplateId
})

export const reopenNotification = createSelector(calibrationState, calibration => calibration.isReopenNotificationSuccess)
export const hyperlinkState = createSelector(calibrationState, calibration => calibration.hyperlinkState)
