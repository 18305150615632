export enum AlertType {
    CalibrationError = 'CalibrationError',
    NetworkError = 'NetworkError',
    Validation = 'Validation',
    WorkflowInfo = 'WorkflowInfo',
    WorkflowSuccess = 'WorkflowSuccess',
    WorkflowRequire = 'WorkflowRequire',
    WorkflowWarning = 'WorkflowWarning',
    WorkflowError = 'WorkflowError'
}

export const alertTypeToastrRef = {
    CalibrationError: 'toast-error',
    NetworkError: 'toast-error',
    Validation: 'toast-info',
    WorkflowInfo: 'toast-info',
    WorkflowSuccess: 'toast-success',
    WorkflowRequire: 'toast-error',
    WorkflowWarning: 'toast-warning',
    WorkflowError: 'toast-error'
}
