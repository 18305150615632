import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators'

import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateService } from '@app/modules/equipment/services/equipment-template.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import {
    ActionType, GetEquipmentTemplateDetailsAction, GetEquipmentTemplateDetailSuccessAction, RemoveEquipmentTemplateAction, SelectTemplateTypeAction,
    UpdateEquipmentTemplateAction, UpdateEquipmentTemplateSuccessAction
} from '@app/store/equipment/actions/equipment-template.actions'
import {
    RemoveTemplateFromEquipmentListSuccessAction, UpdateTemplateToEquipmentListSuccessAction as AddToTemplateEquipmentListSuccessAction
} from '../actions/equipment-list.actions'

@Injectable()
export class EquipmentTemplateEffects {

    
    getEquipmentTemplateDetails: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.GetEquipmentTemplateDetails),
            switchMap((action: GetEquipmentTemplateDetailsAction) =>
                this.responseHandlerService.query(() =>
                    this.equipmentTemplateService.getEquipmentTemplateDetails(action.payload),
                    // using page loader to ensure that the template data loading is complete before rendering calibration page
                    this.pageLoaderStrategy
                ).pipe(
                    map((equipmentTemplate: EquipmentTemplateCoreDetails[]) =>
                        new GetEquipmentTemplateDetailSuccessAction(equipmentTemplate)),
                    catchError(_ => of({ type: 'NO_ACTION' }))
                )
            )
        ))

    
    removeEquipmentTemplate: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.RemoveEquipmentTemplate),
            exhaustMap((action: RemoveEquipmentTemplateAction) => {
                if (!action.payload.isNew) {
                    const equipmentTemplate = JSON.parse(JSON.stringify(action.payload)) as EquipmentTemplateCoreDetails
                    equipmentTemplate.isActive = false
                    return this.responseHandlerService.query(
                        () => this.equipmentTemplateService.updateEquipmentTemplate(equipmentTemplate), this.sectionLoaderStrategy
                    ).pipe(
                        map(() => {
                            const { equipmentId, id } = action.payload
                            return new RemoveTemplateFromEquipmentListSuccessAction(equipmentId, id)
                        }),
                        catchError(_ => of({ type: 'NO_ACTION' }))
                    )
                }
                return of({ type: 'NO_ACTION' })
            })
        ))

    
    updateEquipmentTemplate: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.UpdateEquipmentTemplate),
            concatMap((action: UpdateEquipmentTemplateAction) => {
                let templateQuery: Observable<any>
                if (action.payload.isNew) {
                    templateQuery = this.responseHandlerService.query(
                        () => this.equipmentTemplateService.addEquipmentTemplate(
                            action.payload as EquipmentTemplateCoreDetails
                        ), this.sectionLoaderStrategy
                    )
                } else {
                    templateQuery = this.responseHandlerService.query(
                        () => this.equipmentTemplateService.updateEquipmentTemplate(
                            action.payload as EquipmentTemplateCoreDetails
                        ), this.sectionLoaderStrategy
                    )
                }

                return templateQuery.pipe(
                    map((equipmentTemplate: EquipmentTemplateCoreDetails) => {
                        equipmentTemplate.isNew = false
                        this.store.dispatch(new SelectTemplateTypeAction(equipmentTemplate))
                        this.store.dispatch(new AddToTemplateEquipmentListSuccessAction(equipmentTemplate))
                        return new UpdateEquipmentTemplateSuccessAction(equipmentTemplate, action.selectedTemplateId)
                    }),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
            })
        ))

    private sectionLoaderStrategy: ResponseHandlingStrategy
    private pageLoaderStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private equipmentTemplateService: EquipmentTemplateService,
        private responseHandlerService: ResponseHandlerService,
        private toastr: ToastrService
    ) {
        this.sectionLoaderStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.EquipmentAdminTemplate)
            .responseStrategy

        this.pageLoaderStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useHandle404(null)
            .useShowLoader(this.store)
            .responseStrategy
    }
}
