import { Action } from '@ngrx/store'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { HyperlinkState } from '@app/modules/calibration/models/hyperlink.model'

export enum ActionType {
    GetCalibrationDetails = '[Calibration] Get details',
    CreateCalibrationDetails = '[Calibration] Create details',
    UpdateCalibrationDetails = '[Calibration] Update details',
    ReopenCalibration = '[Calibration] Reopen calibration',
    ReopenNotification = '[Calibration] Reopen notification',
    ReopenNotificationResult = '[Calibration] Reopen notification result ',
    LoadCalibrationSuccess = '[Calibration] Load details success',
    UpdateCalibrationFailure = '[Calibration] Update calibration failure',
    RemoveCachedCalibration = '[Calibration] Remove cached calibration',
    ResumeCalibration = '[Calibration] Resume Calibration',
    ReSyncCalibration = '[Calibration] Re-sync calibration',
    UpdateCurrentCalibrationToStore = '[Calibration] Update current calibration to store',
    GetHyperlinkInfo = '[Calibration] Get hyperlink info',
    GetHyperlinkInfoSuccess = '[Calibration] Get hyperlink info success',
    GetHyperlinkInfoFailed = '[Calibration] Get hyperlink info failed',
}

export class GetCalibrationAction implements Action {
    readonly type = ActionType.GetCalibrationDetails

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationId?: number
    ) { }
}

export class CreateCalibrationDetailsAction implements Action {
    readonly type = ActionType.CreateCalibrationDetails

    constructor(public calibrationDetails: CalibrationDetails) { }
}

export class UpdateCalibrationDetailsAction implements Action {
    readonly type = ActionType.UpdateCalibrationDetails

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationDetails: CalibrationDetails
    ) { }
}

export class UpdateCurrentCalibrationToStore implements Action {
    readonly type = ActionType.UpdateCurrentCalibrationToStore

    constructor(public calibrationDetails: CalibrationDetails) { }
}

export class UpdateCalibrationFailureAction implements Action {
    readonly type = ActionType.UpdateCalibrationFailure

    constructor(public calibrationDetail: CalibrationDetails, public statusCode: number) { }
}

export class ReopenCalibration implements Action {
    readonly type = ActionType.ReopenCalibration

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationDetails: CalibrationDetails
    ) { }
}

export class ReopenNotification implements Action {
    readonly type = ActionType.ReopenNotification

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationDetails: CalibrationDetails
    ) { }
}

export class ReopenNotificationResult implements Action {
    readonly type = ActionType.ReopenNotificationResult

    constructor(
        public isReopenNotificationSuccess: boolean
    ) { }
}

export class LoadCalibrationSuccessAction implements Action {
    readonly type = ActionType.LoadCalibrationSuccess

    /**
     * @param payload - The Calibration Detail to Load into Store
     * @param forceOverride - If the app is currently offline, the reducer will prefer existing calibration detail in the
     * store if one exist over the one in the Action's `payload` as the `payload` is likely coming from Service Worker's cache
     * and the cache is likely older than the one in the store.
     *
     * In the case when this action is not dispatch as a result of an API call (e.g. the one initiate by `selectTemplate()` drawer),
     * this flag should be set to `true` so the Action's `payload` will guarantee to override and update the store.
     */
    constructor(public payload: CalibrationDetails, public forceOverride = false) { }
}

export class RemoveCachedCalibrationAction implements Action {
    readonly type = ActionType.RemoveCachedCalibration

    constructor(
        public workOrderNumber: string,
        public equipmentId: string
    ) { }
}

export class ResumeCalibration implements Action {
    readonly type = ActionType.ResumeCalibration

    constructor(
        public calibrationDetails: CalibrationDetails,
        public hasMultipleTemplates: boolean
    ) { }
}

export class ReSyncCalibrationAction implements Action {
    readonly type = ActionType.ReSyncCalibration

    constructor(
        public workOrderNumber = '',
        public notificationNumber = '',
        public equipmentId = ''
    ) { }
}

export class GetHyperlinkInfoAction implements Action {
    readonly type = ActionType.GetHyperlinkInfo

    constructor(public equipmentId: string) {
    }
}

export class GetHyperlinkInfoSuccessAction implements Action {
    readonly type = ActionType.GetHyperlinkInfoSuccess

    constructor(public hyperlinkState: HyperlinkState) {
    }
}

export class GetHyperlinkInfoFailedAction implements Action {
    readonly type = ActionType.GetHyperlinkInfoFailed

    constructor(public equipmentId: string) {
    }
}

export type CalibrationAction = GetCalibrationAction
    | LoadCalibrationSuccessAction
    | CreateCalibrationDetailsAction
    | UpdateCalibrationDetailsAction
    | UpdateCalibrationFailureAction
    | UpdateCurrentCalibrationToStore
    | RemoveCachedCalibrationAction
    | ResumeCalibration
    | ReSyncCalibrationAction
    | ReopenNotification
    | ReopenNotificationResult
    | GetHyperlinkInfoAction
    | GetHyperlinkInfoSuccessAction
    | GetHyperlinkInfoFailedAction

