import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { findOptionWithKey } from '@app/utils/app-utils.function'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { NgFor, NgIf } from '@angular/common'
import { InlineLabelComponent } from '@app/modules/mrma-ui-components/layouts/inline-label/inline-label.component'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'
import { SetpointSelectorRowComponent } from './setpoint-selector-row/setpoint-selector-row.component'
import { PaginationComponent } from '@app/modules/shared/components/pagination/pagination.component'

@Component({
    selector: 'app-tabular-setpoints-selector',
    templateUrl: './tabular-setpoints-selector.component.html',
    styleUrls: [
        './tabular-setpoints-selector.component.scss',
        './setpoint-selector-row/setpoint-selector-row.component.scss'
    ],
    standalone: true,
    imports: [
        NgIf,
        InlineLabelComponent,
        OptionsComponent,
        NgFor,
        SetpointSelectorRowComponent,
        ReactiveFormsModule,
        PaginationComponent
    ]
})
export class TabularSetpointsSelectorComponent extends SafeUnsubscriberComponent implements OnInit {

    @Input() isSetPointAdjustable = false
    @Input() showExpectedReadingColumn = false

    @Input() disabled = false

    @Input() formArray: UntypedFormArray
    @Input() defaultSetPointNames: (string[])[]

    public getOption = findOptionWithKey
    public yesNoOption = TemplateOptions.yesNoOption

    private _numberOfPoints = 1
    private shareSetPoint = 'true'
    private setpointSelectorGroup: UntypedFormGroup

    public currentPage: number = 1
    public itemsPerPage: number = 30

    constructor(
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {
        super()
    }
    @Input() set numberOfPoints(v: number) {
        this._numberOfPoints = v
        if (this.formArray) {
            this.matchRowWithNumberOfPoints()
            this.currentPage = 1
        }
    }

    get numberOfPoints(): number {
        return this._numberOfPoints
    }

    get isEqualOrLessThanPerPage(): boolean {
        return this.numberOfPoints <= this.itemsPerPage
    }

    get firstItem(): number {
        return (this.currentPage - 1) * this.itemsPerPage + 1;
    }

    get firstItemIndex(): number {
        return (this.currentPage - 1) * this.itemsPerPage
    }

    ngOnInit(): void {
        this.matchRowWithNumberOfPoints()

        this.setpointSelectorGroup = new UntypedFormGroup({
            shareSetPoint: new UntypedFormControl({ value: null, disabled: this.disabled }),
            setpointUOM: new UntypedFormControl({ value: null, disabled: this.disabled }),
            expectedReadingUOM: new UntypedFormControl({ value: null, disabled: this.disabled }),
            toleranceUOM: new UntypedFormControl({ value: null, disabled: this.disabled })
        })

        // Setup when first opening the pages
        const firstSetPointUOM = this.formArray.value[0]?.setPoint?.unitOfMeasurement?.uomCode
        const firstExpectedUOM = this.formArray.value[0]?.expectedReading?.unitOfMeasurement?.uomCode
        const firstToleranceUOM = this.formArray.value[0]?.tolerance?.unitOfMeasurement?.uomCode

        this.subscribeShareSetPoint()

        this.setpointSelectorGroup.patchValue({
            shareSetPoint: this.formArray.value.every(value => value.setPoint.unitOfMeasurement?.uomCode === firstSetPointUOM &&
                value.expectedReading.unitOfMeasurement?.uomCode === firstExpectedUOM &&
                value.tolerance.unitOfMeasurement?.uomCode === firstToleranceUOM).toString()
        })

        this.addSubscriptions([
            this.formArray.valueChanges.pipe(
                filter(() => this.setpointSelectorGroup.get('shareSetPoint').value === 'true'),
                map(setpoints => setpoints[0]),
                distinctUntilChanged((prevSetPoint, currSetPoint) => {
                    return prevSetPoint?.setPoint?.unitOfMeasurement?.uomCode === currSetPoint?.setPoint?.unitOfMeasurement?.uomCode &&
                        prevSetPoint?.expectedReading?.unitOfMeasurement?.uomCode === currSetPoint?.expectedReading?.unitOfMeasurement?.uomCode &&
                        prevSetPoint?.tolerance?.unitOfMeasurement?.uomCode === currSetPoint?.tolerance?.unitOfMeasurement?.uomCode
                })
            ).subscribe((setpoint) => {
                this.setpointSelectorGroup.patchValue({
                    setpointUOM: setpoint.setPoint.unitOfMeasurement,
                    expectedReadingUOM: setpoint.expectedReading.unitOfMeasurement,
                    toleranceUOM: setpoint.tolerance.unitOfMeasurement
                })
            })
        ])
    }

    public pageChanged(page: number): void {
        this.currentPage = page
    }

    public getDefaultSetPointName(index: number): string[] {
        return  this.defaultSetPointNames[index]
    }

    private matchRowWithNumberOfPoints(): void {
        // We need for the CD that triggered by change in numberOfPoint
        // to finish first before mutating the array to avoid ChangeAfterCheck error.
        // Thus, we are wrapping it in the setTimeout()
        // We don't want setTimeout() to trigger another CD nor we want the CD to
        // run on every iteration, so we disable CD temporary and run the setTimeout()
        // outside of Angular.
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.formArray.controls.length === this.numberOfPoints) {
                    return
                }

                // Pause CD before looping
                this.cdRef.detach()

                while (this.formArray.controls.length < this.numberOfPoints - 1) {
                    this.addRow()
                }

                // Send confirmation?
                while (this.formArray.controls.length > this.numberOfPoints + 1) {
                    this.removeRowAt(this.formArray.controls.length - 1)
                }

                // Perform some actions inside Angular to populate the changes to other components
                // otherwise, there could be a bug such as
                // the save button is not enabled/disabled when it should be
                this.ngZone.run(() => {
                    // run the last iteration to add/remove row
                    if (this.formArray.controls.length < this.numberOfPoints) {
                        this.addRow()
                    }

                    if (this.formArray.controls.length > this.numberOfPoints) {
                        this.removeRowAt(this.formArray.controls.length - 1)
                    }

                    // resume CD
                    this.cdRef.reattach()
                    this.cdRef.detectChanges()
                })
            })
        })
    }

    private addRow(): void {
        if (this.disabled) {
            return
        }
        if (this.setpointSelectorGroup.get('shareSetPoint').value === 'false') {
            this.formArray.push(
                new UntypedFormControl({
                    setPoint: {
                        unitOfMeasurement: null
                    },
                    expectedReading: {
                        unitOfMeasurement: null
                    },
                    tolerance: {
                        unitOfMeasurement: null
                    }
                })
            )
        }
        if (this.setpointSelectorGroup.get('shareSetPoint').value === 'true') {
            this.formArray.push(
                new UntypedFormControl({
                    setPoint: {
                        unitOfMeasurement: this.setpointSelectorGroup.get('setpointUOM').value
                    },
                    expectedReading: {
                        unitOfMeasurement: this.setpointSelectorGroup.get('expectedReadingUOM').value
                    },
                    tolerance: {
                        unitOfMeasurement: this.setpointSelectorGroup.get('toleranceUOM').value
                    }
                })
            )
        }
    }

    private removeRowAt(index: number): void {
        if (this.disabled) {
            return
        }
        this.formArray.removeAt(index)
        this.formArray.markAsDirty()
        this.formArray.markAsTouched()
    }

    private subscribeShareSetPoint(): void {
        this.setpointSelectorGroup.get('shareSetPoint').valueChanges.pipe(
            filter(value => value === 'true')
        ).subscribe(() => {
            this.setpointSelectorGroup.patchValue({
                setpointUOM: this.formArray.value[0]?.setPoint.unitOfMeasurement,
                expectedReadingUOM: this.formArray.value[0]?.expectedReading.unitOfMeasurement,
                toleranceUOM: this.formArray.value[0]?.tolerance.unitOfMeasurement
            })
        })
    }
}
