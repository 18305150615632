import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { EquipmentHistory } from '../models/equipment-history.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentHistoryService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Calibrations/Histories`

    constructor(private queryBuilderService: QueryBuilderService) { }

    getEquipmentHistory(equipmentId: string): Observable<EquipmentHistory> {
        return this.queryBuilderService
            .get<EquipmentHistory>(`${this.url}?equipmentId=${equipmentId}`)
            .pipe(map(response => response.body))
    }
}
