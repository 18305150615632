import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { EquipmentPMOService } from '@app/modules/equipment/services/equipment-pmo.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import {
    ActionType, GetMaintenancePlansStartAction, GetMaintenancePlansSuccessAction, GetTestIntervalSettingStartAction,
    GetTestIntervalSettingSuccessAction, UpdateTestIntervalSettingStartAction, UpdateTestIntervalSettingSuccessAction
} from '../actions/equipment-pmo.actions'

@Injectable()
export class EquipmentPMOSettingEffects {

    
    getTestIntervalSetting: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetTestIntervalSettingStart),
        switchMap((action: GetTestIntervalSettingStartAction) => {
            const equipmentId = action.equipmentId
            return this.responseHandlerService.query(() =>
                this.equipmentPMOService.getTestIntervalSetting(equipmentId),
                this.customStrategy)
        }),
        map((testIntervalSetting) =>
            new GetTestIntervalSettingSuccessAction(testIntervalSetting)
        )
    ))

    
    getMaintenancePlans: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetMaintenancePlansStart),
        switchMap((action: GetMaintenancePlansStartAction) => {
            const equipmentId = action.equipmentId
            return this.responseHandlerService.query(() =>
                this.equipmentPMOService.getMaintenancePlans(equipmentId),
                this.customStrategy)
        }),
        map((maintenancePlans) =>
            new GetMaintenancePlansSuccessAction(maintenancePlans)
        )
    ))

    
    updateTestIntervalSetting: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.UpdateTestIntervalSettingStart),
        switchMap((action: UpdateTestIntervalSettingStartAction) => {
            const { equipmentId, testIntervalSetting } = action.payload
            return this.responseHandlerService.query(() =>
                this.equipmentPMOService.updateTestIntervalSetting(equipmentId, testIntervalSetting),
                this.customStrategy)
        }),
        map((testIntervalSetting) => {
            this.toastr.success('Test Interval Setting Updated')
            return new UpdateTestIntervalSettingSuccessAction()
        }
        )
    ))
    private customStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private toastr: ToastrService,
        private equipmentPMOService: EquipmentPMOService,
        private responseHandlerService: ResponseHandlerService
    ) {
        this.customStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy
    }

}
