import { createSelector } from '@ngrx/store'

import { LoaderState } from './loader.reducer'

export const selectLoaderState = (state): LoaderState => state.loader

export const isLoading = createSelector(selectLoaderState, loader => loader.visible)
export const sectionLoadersSelector = createSelector(selectLoaderState, loader => loader.sectionLoaders)
export const isAnyLoading = createSelector(selectLoaderState, loader => loader.visible || loader.sectionLoaders.length > 0)
export const getProgressBarStatus = createSelector(selectLoaderState, loader => loader.progressBar)
export const isHomeComponentInitialized = createSelector(selectLoaderState, loader => loader.isHomeComponentInitialized)
