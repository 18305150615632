import { Injectable } from '@angular/core'

import { TestEquipment } from '../models/test-equipment.model'

@Injectable({
    providedIn: 'root'
})
export class TestEquipmentFilteringService {

    public filterTestEquipment(
        testEquipments: TestEquipment[],
        searchText: string = ''
    ): TestEquipment[] {
        searchText = searchText.toLowerCase()
        return testEquipments.filter((testEQ: TestEquipment) => {
            return testEQ.equipmentId.toLowerCase().includes(searchText)
                || testEQ.equipmentTag.toLowerCase().includes(searchText)
                || testEQ.description.toLowerCase().includes(searchText)
                || testEQ.techIdentificationNumber.toLowerCase().includes(searchText)
                || testEQ.functionalLocation.toLowerCase().includes(searchText)
        })
    }

    public filterExistingTestEquipments(
        inputTestEquipments: TestEquipment[],
        existingTestEquipments: TestEquipment[]
    ): TestEquipment[] {
        return inputTestEquipments.filter(testEquipment => {
            return !existingTestEquipments.find(existingTestEquipment => {
                return existingTestEquipment
                    && testEquipment.equipmentId
                    === existingTestEquipment.equipmentId
            })

        })
    }
}
