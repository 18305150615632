export enum SectionLoaderEnum {
    EquipmentAdminTable,
    ModalBody,
    List,
    PagedList,
    Attachment,
    ReportDetails,
    EquipmentAdminTemplate,
    EquipmentHistory
}
