import { Injectable } from '@angular/core'

import { breakpointView, ResponsiveClass } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {
    public isOnMobileOrTabletDevice(): boolean {
        return (
            breakpointView() === ResponsiveClass.md ||
            breakpointView() === ResponsiveClass.sm ||
            breakpointView() === ResponsiveClass.xs
        )
    }
}
