import { Injectable } from '@angular/core'

import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { equipmentSortStackColumn } from '../constants/equipment-sort-stack-options.constant'
import { EquipmentStackSortingCaret } from '../models/equipment-stack-sorting-state.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentSortStackService {
    public refreshSortingCaret(currentSortingCaret: EquipmentStackSortingCaret, column = ''): EquipmentStackSortingCaret {
        const currentEquipmentSort = equipmentSortStackColumn.find(c => c === column)
        if (!currentEquipmentSort || column === '') {
            return { isSorted: false, isDescending: false, sortBy: '' }
        }

        return currentSortingCaret
    }

    public applyMainEquipmentToWorkOrders(workOrderItems: WorkOrderListItem[]): void {
        for (const wo of workOrderItems) {
            if (wo.equipments.length === 0) {
                continue
            }

            wo.equipmentId = wo.equipments[0].equipmentId
            wo.equipmentTag = wo.equipments[0].equipmentTag
            wo.techIdentificationNumber = wo.equipments[0].techIdentificationNumber
            wo.functionalLocation = wo.equipments[0].functionalLocation
        }
    }
}
