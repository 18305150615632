import { Modal } from '@app/modules/modal-container/models/modal.model'
import { ActionType, ModalAction } from './modal.actions'

export interface ModalState {
    modal: Modal
    visibility: boolean
}

const initialState: ModalState = {
    modal: null,
    visibility: false
}

export function reducer(state = initialState, action: ModalAction): ModalState {
    switch (action.type) {
        case ActionType.HideModal:
            return { ...state, visibility: false }
        case ActionType.DestroyModal:
            return { ...state, visibility: false, modal: undefined }
        case ActionType.ShowModal:
            return { ...state, visibility: true, modal: action.payload }
        default:
            return state
    }
}
