import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { SapEquipment } from '../models/sap-equipment.model'

@Injectable({
    providedIn: 'root'
})
export class SapEquipmentService {
    private readonly sapEquipmentBaseUrl =
        `${environment.baseUrl}/api/${AppSettings.apiVersion}/SAPEquipments`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getSAPEquipments(
        equipmentTag: string = null,
        planningPlant: string = null,
        usrStatus: string = null,
        isTestEquipment: boolean = false
    ): Observable<HttpResponse<SapEquipment[]>> {
        return this.queryBuilderService
            .get<SapEquipment[]>(
                this.sapEquipmentBaseUrl,
                {
                    equipmentTag,
                    planningPlant,
                    usrStatus,
                    isTestEquipment
                })
            .pipe(map(response => {
                return response
            }))
    }
}
