import { HttpErrorResponse } from '@angular/common/http'
import { Store } from '@ngrx/store'

import { createErrorModal, getErrorMessage } from '@app/models/error-message.builder'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'

export class ShowModalOnErrorStrategy extends ResponseHandlingStrategy {
    constructor(next: ResponseHandlingStrategy, private store: Store<AppState>) {
        super(next)
    }

    public catchError(error: HttpErrorResponse): any {
        this.displayErrorMessage(error)
        return super.catchError(error)
    }

    public displayErrorMessage(error: HttpErrorResponse): void {
        const errorMessage = getErrorMessage(error)
        const modal = createErrorModal(errorMessage)
        this.store.dispatch(new ShowModalAction(modal))
    }
}
