<app-admin-form-row [formGroup]="zeroPointTemplateForm"
                    label="PROCESS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="zeroPointTemplateForm"
                      [formControlPath]="'process'"
                      [options]="(processes$ | async)"
                      [key]="'id'"
                      [value]="'name'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="zeroPointTemplateForm"
                    label="TEMPLATE ALIAS">
    <div class="em-l-grid__item admin-input-width">
        <input formControlName="alias"
               type="text"
               class="em-c-input em-u-font-style-regular"
               maxlength="20">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="zeroPointTemplateForm"
                    label="PROCEDURE NUMBER">
    <div class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': zeroPointTemplateForm.disabled}">
        <input formControlName="procedure"
               type="text"
               placeholder="B4P-RXG-XXX.doc"
               class="em-c-input em-u-font-style-regular">
    </div>
</app-admin-form-row>