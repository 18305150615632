import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, Renderer2 } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { isArray, isEmpty } from 'lodash'

type CopyInfo = string | string[]
type ListenCallback = () => void

@Directive({
    selector: '[appCopyToInput]',
    standalone: true
})
export class CopyToInputDirective implements AfterViewInit, OnDestroy {

    @Input() formControl: UntypedFormControl

    private _copyInfo: CopyInfo = []

    private _isVisible = true

    private copyContainerElement: HTMLDivElement
    private unbindFunctions: ListenCallback[] = []

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
    }
    @Input('appCopyToInput')
    set copyInfo(value: CopyInfo) {
        this._copyInfo = value
        this.renderCopyToInputElement()
    }
    @Input()
    set isVisible(value: boolean) {
        this._isVisible = value
        this.renderCopyToInputElement()
    }

    ngAfterViewInit(): void {
        this.copyContainerElement = this.createCopyElementContainer()
        this.addCopyToUI()

        this.renderCopyToInputElement()
    }

    ngOnDestroy(): void {
        this.unbindFunctions.forEach(func => {
            func()
        })
    }

    private createCopyElementContainer(): HTMLDivElement {
        if (isEmpty(this._copyInfo)) {
            return this.document.createElement('div')
        }

        const container = this.document.createElement('div')
        container.className = 'copy-to-input-container'
        this.renderer.setStyle(container, 'color', '#235695')
        this.renderer.setStyle(container, 'cursor', 'pointer')
        this.renderer.setStyle(container, 'margin', '0.5rem 0')

        const needAddToElementTexts = isArray(this._copyInfo) ? this._copyInfo : [this._copyInfo]

        needAddToElementTexts.forEach((text, index) => {
            const copyElement = this.createCopyToInputElement(text)
            this.renderer.appendChild(container, copyElement)
            if (index !== 0) {
                this.renderer.setStyle(copyElement, 'margin-left', '1rem')
            }
            this.unbindFunctions.push(this.getClickListener(copyElement, text))
        })
        return container
    }

    private createCopyToInputElement(text: string): HTMLDivElement {
        const copyElement = this.document.createElement('div')
        const iconElement = this.document.createElement('img')
        this.renderer.setStyle(iconElement, 'margin-right', '0.3rem')
        const txt = this.document.createTextNode(text)

        copyElement.className = 'copy-to-input-fragment'
        iconElement.setAttribute('src', './assets/images/copy_icon.svg')
        iconElement.className = 'em-c-icon'
        this.renderer.setStyle(copyElement, 'display', 'flex')
        this.renderer.setStyle(copyElement, 'align-items', 'center')

        this.renderer.appendChild(copyElement, iconElement)
        this.renderer.appendChild(copyElement, txt)
        return copyElement
    }

    private addCopyToUI(): void {
        const parentNode = this.renderer.parentNode(this.elementRef.nativeElement)
        this.renderer.appendChild(parentNode, this.copyContainerElement)
    }

    private getClickListener(toBeClickedElement: HTMLDivElement, text: string): ReturnType<Renderer2['listen']> {
        const handler = () => {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', text)
            this.formControl?.setValue(text)
        }
        return this.renderer.listen(toBeClickedElement, 'click', handler)
    }

    private renderCopyToInputElement(): void {
        if (this.copyContainerElement === undefined) {
            return
        }
        const needRender = !isEmpty(this._copyInfo) && this._isVisible
        needRender ? this.showCopyToInputElement() : this.hideCopyToInputElement()
    }

    private showCopyToInputElement(): void {
        this.changeCopyToInputElementDisplay('flex')
    }

    private hideCopyToInputElement(): void {
        this.changeCopyToInputElementDisplay('none')
    }

    private changeCopyToInputElementDisplay(displayStyle: string): void {
        this.renderer.setStyle(this.copyContainerElement, 'display', displayStyle)
    }
}
