import 'zone.js/plugins/zone-error'

import { environment as base } from './environment.base'

export const environment = {
    ...base,
    development: false,
    serviceWorker: true,
    name: 'MRMA Acceptance',
    baseUrl: 'https://apia.mrma.exxonmobil.com',
    apiVersion: 'v2',
    auth: {
        clientId: '574181bc-77a4-4d7d-90c5-ee46259a8394',
        authority: 'https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/',
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        redirectUri: 'https://a.mrma.exxonmobil.com',
        postLogoutRedirectUri: 'https://a.mrma.exxonmobil.com',
        scopes: [
            'api://8428bdc1-4729-457c-b0d4-639cd070bab6/MRMAAcc/Read',
            'api://8428bdc1-4729-457c-b0d4-639cd070bab6/MRMAAcc/Write'
        ]
    },
    flamingoForm: {
        baseUrl: 'https://api.acc.flamingo.xom.cloud/api',
        scopes: [
            'api://904c6755-f5fd-4ab2-9376-13e89fbcd32a/flamingo-acc/User'
        ],
        environment: 'acc'
    },
    features: {
        'email-subscription': true
    },
    debug: {
        logLevel: '',
        logCDTime: false,
        enableClassProfiler: false,
        enableFnProfiler: false
    },
    functionAppUrl: 'https://mrma-funct-acc.azurewebsites.net'
}
