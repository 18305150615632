import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map, switchMap, tap } from 'rxjs/operators'

import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { SapEquipmentService } from '@app/modules/equipment/services/sap-equipment.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { TestEquipmentService } from '@app/modules/test-equipment/services/test-equipment.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { UpdateTestEquipmentListCacheStatusAction } from '../offline/offline.actions'
import {
    ActionType, CreateTestEquipmentAction, CreateTestEquipmentSuccessAction, GetTestEquipmentFailureAction, GetTestEquipmentSuccessAction,
    SearchNewTestEquipmentAction, SearchNewTestEquipmentSuccessAction, UpdateTestEquipmentAction, UpdateTestEquipmentFailureAction,
    UpdateTestEquipmentSuccessAction
} from './test-equipment.actions'

@Injectable()
export class TestEquipmentEffects {

    
    public createTestEquipment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CreateTestEquipmentAction>(ActionType.CreateTestEquipment),
        concatMap((action: CreateTestEquipmentAction) =>
            this.responseHandlerService.query(() =>
                this.testEquipmentService.createTestEquipment(action.payload),
                this.customStrategyPageList
            ).pipe(
                map(result => new CreateTestEquipmentSuccessAction(result)),
                catchError(error => of(new UpdateTestEquipmentFailureAction(error)))
            )
        )
    ))

    
    public getTestEquipments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetTestEquipment),
        exhaustMap(() =>
            this.responseHandlerService.query(() =>
                this.testEquipmentService.getTestEquipment(),
                this.customStrategyGetTestEquipment
            ).pipe(
                tap(() => this.store.dispatch(new UpdateTestEquipmentListCacheStatusAction({ cacheStatus: CacheStatusEnum.CACHED }))),
                map(testEquipments => new GetTestEquipmentSuccessAction(testEquipments)),
                catchError(error => of(new GetTestEquipmentFailureAction(error)))
            )
        )
    ))

    
    public updateTestEquipment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<UpdateTestEquipmentAction>(ActionType.UpdateTestEquipment),
        concatMap((action: UpdateTestEquipmentAction) =>
            this.responseHandlerService.query(() =>
                this.testEquipmentService.updateTestEquipment(action.payload),
                this.customStrategyPageList
            ).pipe(
                map(result => new UpdateTestEquipmentSuccessAction(result)),
                catchError(error => of(new UpdateTestEquipmentFailureAction(error)))
            )
        )
    ))

    
    public searchNewTestEquipment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<SearchNewTestEquipmentAction>(ActionType.SearchNewTestEquipment),
        switchMap((action) =>
            this.responseHandlerService.query(() =>
                this.sapEquipmentService.getSAPEquipments(
                    action.payload.equipmentTag,
                    action.payload.planningPlant,
                    action.payload.usrStatus,
                    action.payload.isTestEquipment), this.customStrategyModal
            ).pipe(
                map(response => {
                    const startingNumber =
                        Number(action.payload.perPage) * (Number(action.payload.page) - 1)
                    return new SearchNewTestEquipmentSuccessAction({
                        querySummary: {
                            queryParameters: {
                                equipmentTag: action.payload.equipmentTag,
                                planningPlant: action.payload.planningPlant,
                                usrStatus: action.payload.usrStatus,
                                isTestEquipment: action.payload.isTestEquipment,
                                sortBy: action.payload.sortBy,
                                page: action.payload.page.toString(),
                                perPage: action.payload.perPage.toString()
                            },
                            currentPage: Number(action.payload.page),
                            itemPerPage: String(action.payload.perPage),
                            totalItemCount: response.body.length
                        },
                        result: response.body.slice(
                            startingNumber,
                            startingNumber + Number(action.payload.perPage))
                    })
                })
            )
        )
    ))

    private customStrategyModal: ResponseHandlingStrategy
    private customStrategyPageList: ResponseHandlingStrategy
    private customStrategyGetTestEquipment: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private testEquipmentService: TestEquipmentService,
        private responseHandlerService: ResponseHandlerService,
        private store: Store<AppState>,
        private sapEquipmentService: SapEquipmentService,
        private toastr: ToastrService
    ) {
        this.customStrategyModal = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.ModalBody)
            .responseStrategy

        this.customStrategyPageList = new ResponseHandlingStrategyBuilder()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.PagedList)
            .responseStrategy

        this.customStrategyGetTestEquipment = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy
    }
}
