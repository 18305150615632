import { HttpErrorResponse } from '@angular/common/http'

import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { of } from 'rxjs'

export class Handle404Strategy extends ResponseHandlingStrategy {
    constructor(next: ResponseHandlingStrategy, private returnValue: any) {
        super(next)
    }

    public init(): void {
        super.init()
    }

    public finalize(): void {
        super.finalize()
    }

    public catchError(error: HttpErrorResponse): any {
        if (error.status === 404) {
            return of(this.returnValue)
        }

        return super.catchError(error)
    }
}
