<ng-container [formGroup]="form">

    <section class="admin-form-section">
        <app-inline-label label="PROCESS *">
            <div class="em-l-grid__item admin-input-width">
                <app-dropdown [form]="form"
                              [formControlPath]="'process'"
                              [options]="(processes$ | async)"
                              [key]="'id'"
                              [value]="'name'">
                </app-dropdown>
            </div>
        </app-inline-label>
        <app-inline-label label="TEMPLATE ALIAS">
            <div class="em-l-grid__item admin-input-width">
                <input formControlName="alias"
                       type="text"
                       class="em-c-input em-u-font-style-regular"
                       maxlength="24">
            </div>
        </app-inline-label>
        <app-inline-label label="PROCEDURE NUMBER">
            <div class="em-l-grid__item admin-input-width em-c-field">
                <input formControlName="procedure"
                       type="text"
                       placeholder="B4P-RXG-XXX.doc"
                       class="em-c-input em-u-font-style-regular">
            </div>
        </app-inline-label>
    </section>

    <section class="admin-form-section">
        <app-inline-label label="TEST EQUIPMENT"
                          description="(Choose 'Required' if you want technician to select test equipment before completing the work)">
            <div class="em-l-grid__item admin-input-width">
                <app-options [formGroup]="form"
                             [formControlPath]="'isTestEquipmentRequired'"
                             [defaultOption]="getOption(form.get('isTestEquipmentRequired').value, isRequiredOption)"
                             [labelStyle]="'em-u-width-50'"
                             [disabled]="form.get('isTestEquipmentRequired').disabled"
                             [options]="isRequiredOption"
                             [key]="'key'"
                             [value]="'value'">
                </app-options>
            </div>
        </app-inline-label>
        <app-inline-label label="ADJUSTABLE SET POINT"
                          description="(Allow Technician to change test value)">
            <div class="em-l-grid__item admin-input-width">
                <app-options [formGroup]="form"
                             [formControlPath]="'isSetPointAdjustable'"
                             [defaultOption]="getOption(form.get('isSetPointAdjustable').value, yesNoOption)"
                             [labelStyle]="'em-u-width-50'"
                             [options]="yesNoOption"
                             [key]="'key'"
                             [value]="'value'"
                             [disabled]="this.form.get('isSetPointAdjustable').disabled">
                </app-options>
            </div>
        </app-inline-label>
        <app-inline-label label="ADJUSTABLE EXPECTED READING"
                          description="(To customize the expected reading)">
            <div class="em-l-grid__item admin-input-width">
                <app-options [formGroup]="form"
                             [formControlPath]="'isExpectedReadingEdited'"
                             [defaultOption]="getOption(form.get('isExpectedReadingEdited').value, yesNoOption)"
                             [labelStyle]="'em-u-width-50'"
                             [options]="yesNoOption"
                             [key]="'key'"
                             [value]="'value'"
                             [disabled]="this.form.get('isExpectedReadingEdited').disabled">
                </app-options>
            </div>
        </app-inline-label>
    </section>

    <section class="admin-form-section">
        <app-inline-label label="NUMBER OF POINTS">
            <div class="em-l-grid__item admin-input-width">
                <app-dropdown [form]="form.get('numberOfPoints')"
                              [formControlPath]="'point'"
                              [options]="numberOfPointOptions"
                              [key]="'key'"
                              [value]="'value'">
                </app-dropdown>
            </div>
        </app-inline-label>
        <hr>
        <!-- TODO: Switch to getting points and values from NGRX -->
        <app-tabular-setpoints-selector [formArray]="form.get('setPoints')"
                                        [disabled]="form.disabled"
                                        [defaultSetPointNames]="defaultSetPointNames"
                                        [isSetPointAdjustable]="form.get('isSetPointAdjustable')?.value === 'true'"
                                        [showExpectedReadingColumn]="form.get('isExpectedReadingEdited')?.value === 'true'"
                                        [numberOfPoints]="form.get('numberOfPoints.point')?.value?.key">
        </app-tabular-setpoints-selector>
    </section>
</ng-container>
