<div class="modal-body">
    <sub>{{ title }}</sub>
    <div *ngIf="isBrowserSupportedLink; else linkNotSupported">
        You are about to open
        <div class="wrapped">
            {{ externalUrl }}
            <app-unity-icon unitySvgName="copy"
                            size="small"
                            (click)="copyClipboardLink()"
                            class="em-u-clickable em-c-icon--blue"></app-unity-icon>
        </div>
        in another tab.
        <p>Would you like to proceed?</p>
    </div>
    <ng-template #linkNotSupported>
        <div>
            The following external link is not supported by internet browser:
            <div class="wrapped">
                {{ externalUrl }}
                <app-unity-icon unitySvgName="copy"
                                size="small"
                                (click)="copyClipboardLink()"
                                class="em-u-clickable em-c-icon--blue"></app-unity-icon>
            </div>
            <p>You can copy link and open in other program instead (i.e. file explorer).</p>
        </div>
    </ng-template>
</div>