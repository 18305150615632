import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import {
    calibrationTemplateSelectionModalObject
} from '@app/modules/calibration/components/modals/calibration-template-selection/calibration-template-selection.modal-object'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { CalibrationAlertService } from '@app/modules/calibration/services/calibration-alert.service'
import { CalibrationCacheManager } from '@app/modules/calibration/services/calibration-cache-manager.service'
import { CalibrationService } from '@app/modules/calibration/services/calibration.service'
import { UploadQueue } from '@app/modules/shared/models/upload-queue.model'
import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { OfflineService } from '@app/services/offline.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { deepCopy, isNotAValue } from '@app/utils/app-utils.function'
import { ShowModalAction } from '../modal/modal.actions'
import {
    AddPMToUploadQueueAction,
    UpdatePMSyncStatusAction,
    UpdatePMUploadQueueAction,
    UpdateWorkOrderEquipmentCalibrationStatusAction
} from '../offline/offline.actions'
import { pmUploadQueue } from '../offline/offline.selectors'
import { UpdateWorkOrderStatusInTodoListAction } from '../to-do/to-do.actions'
import {
    GetNotificationAction,
    LoadWorkOrderDetailsAction,
    LoadWorkOrderDetailsSuccessAction
} from '../work-order/work-order.actions'
import * as CalibrationAction from './calibration.actions'
import { cacheCalibrations, calibrationDetails } from './calibration.selectors'
import { HyperlinkService } from '@app/modules/calibration/services/hyperlink.service'
import { workOrderDetails } from '@app/store/work-order/work-order.selectors'

@Injectable()
export class CalibrationEffects {

    
    public loadCalibrationSuccess: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.LoadCalibrationSuccess),
        exhaustMap((action: CalibrationAction.LoadCalibrationSuccessAction) => {
            const calibrationDetail = (action?.payload ?? {}) as CalibrationDetails
            const syncStatus = this.offlineService.getCalibrationBackgroundSyncStatus(
                calibrationDetail.workOrderNumber,
                calibrationDetail.equipmentId
            )

            this.calibrationAlertService.repairWoRequiredAlert(calibrationDetail)
            const alerted = this.calibrationAlertService.offlineAlert(calibrationDetail, syncStatus)

            if (alerted && navigator.onLine) {
                this.store.dispatch(new UpdatePMSyncStatusAction({
                    workOrderNumber: calibrationDetail.workOrderNumber,
                    equipmentId: calibrationDetail.equipmentId,
                    status: BackgroundSyncStatusEnum.NOT_QUEUED
                }))
            }

            return of({ type: 'NO_ACTION' })
        })
    ))

    
    public getCalbibrationDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.GetCalibrationDetails),
        switchMap((action: CalibrationAction.GetCalibrationAction) =>
            this.responseHandlerService.query(() =>
                    this.calibrationService.getCalibrationDetails(action.workOrderNumber, action.equipmentId),
                this.customStrategyLoader
            ).pipe(
                withLatestFrom(this.store.select(cacheCalibrations)),
                map(([calibrationDetail, storeCalibrations]) => {
                    if (navigator.onLine) {
                        return new CalibrationAction.LoadCalibrationSuccessAction(calibrationDetail)
                    }

                    const latestCalibration = storeCalibrations.find(c =>
                        c.workOrderNumber === action.workOrderNumber &&
                        c.equipmentId === action.equipmentId
                    ) || calibrationDetail

                    return new CalibrationAction.LoadCalibrationSuccessAction(latestCalibration)
                }),
                catchError(() => of({ type: 'NO_ACTION' }))
            )
        )
    ))

    
    public createCalibrationDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.CreateCalibrationDetails),
        switchMap((action: CalibrationAction.CreateCalibrationDetailsAction) =>
            this.responseHandlerService.query(() =>
                    // Our backend relies on origin's timezone information, so we need to send
                    // the calibration in ISO format of local time along with offset.
                    this.calibrationService.createCalibrationDetails(action.calibrationDetails, true),
                this.calibrationStrategyHandler(action.calibrationDetails)
            ).pipe(
                tap(details => this.calibrationAlertService.saveAndCompleteAlert(details, null)),
                switchMap((calibrationDetail: CalibrationDetails) => {
                    this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(calibrationDetail))
                    return this.responseHandlerService.query(() =>
                            this.workOrderService.getWorkOrderDetails(calibrationDetail.workOrderNumber, false),
                        this.calibrationStrategyHandler(action.calibrationDetails)
                    ).pipe(
                        map(workOrderDetails => {
                            this.store.dispatch(LoadWorkOrderDetailsSuccessAction({ workOrderDetails }))
                            this.store.dispatch(GetNotificationAction({
                                workOrderNumber: calibrationDetail.workOrderNumber,
                                notificationNumber: action.calibrationDetails.notificationNumber
                            }))
                            this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(action.calibrationDetails))
                            this.store.dispatch(new UpdateWorkOrderEquipmentCalibrationStatusAction(action.calibrationDetails))
                            return new CalibrationAction.LoadCalibrationSuccessAction(calibrationDetail)
                        })
                    )
                }),
                catchError((error: HttpErrorResponse) => {
                    this.calibrationAlertService.handleOfflineErrorAlert(error, action.calibrationDetails)
                    if (error.status === 0) {
                        this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(action.calibrationDetails))
                        this.store.dispatch(new UpdateWorkOrderEquipmentCalibrationStatusAction(action.calibrationDetails))
                        this.store.dispatch(new AddPMToUploadQueueAction({
                            workOrderNumber: action.calibrationDetails.workOrderNumber,
                            equipmentId: action.calibrationDetails.equipmentId,
                            notificationNumber: action.calibrationDetails.notificationNumber,
                            status: BackgroundSyncStatusEnum.QUEUED
                        }))
                    }
                    return of(new CalibrationAction.UpdateCalibrationFailureAction(action.calibrationDetails, error.status))
                })
            )
        )
    ))

    
    public updateCalibrationDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CalibrationAction.UpdateCalibrationDetailsAction>(CalibrationAction.ActionType.UpdateCalibrationDetails),
        withLatestFrom(this.store.select(calibrationDetails), this.store.select(workOrderDetails)),
        concatMap(([action, currentCalibrationDetail, currentWODetails]) =>
            this.responseHandlerService.query(() =>
                    // Our backend relies on origin's timezone information, so we need to send
                    // the calibration in ISO format of local time along with offset.
                    this.calibrationService.updateCalibrationDetails(
                        action.workOrderNumber,
                        action.equipmentId,
                        action.calibrationDetails,
                        true),
                this.calibrationStrategyHandler(action.calibrationDetails)
            ).pipe(
                tap(details => this.calibrationAlertService.saveAndCompleteAlert(details, currentCalibrationDetail, currentWODetails)),
                switchMap((calibrationDetail: CalibrationDetails) => {
                    this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(calibrationDetail))
                    return this.responseHandlerService.query(() =>
                            this.workOrderService.getWorkOrderDetails(calibrationDetail.workOrderNumber, false),
                        this.calibrationStrategyHandler(action.calibrationDetails)
                    ).pipe(
                        map(workOrderDetails => {
                            this.store.dispatch(LoadWorkOrderDetailsSuccessAction({ workOrderDetails }))
                            this.store.dispatch(GetNotificationAction({
                                workOrderNumber: calibrationDetail.workOrderNumber,
                                notificationNumber: action.calibrationDetails.notificationNumber
                            }))
                            this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(action.calibrationDetails))
                            this.store.dispatch(new UpdateWorkOrderEquipmentCalibrationStatusAction(action.calibrationDetails))
                            return new CalibrationAction.LoadCalibrationSuccessAction(calibrationDetail)
                        }))
                }),
                catchError((error: HttpErrorResponse) => {
                    this.calibrationAlertService.handleOfflineErrorAlert(error, action.calibrationDetails)
                    if (error.status === 0) {
                        this.store.dispatch(new UpdateWorkOrderStatusInTodoListAction(action.calibrationDetails))
                        this.store.dispatch(new UpdateWorkOrderEquipmentCalibrationStatusAction(action.calibrationDetails))
                        this.store.dispatch(new AddPMToUploadQueueAction({
                            workOrderNumber: action.calibrationDetails.workOrderNumber,
                            equipmentId: action.calibrationDetails.equipmentId,
                            notificationNumber: action.calibrationDetails.notificationNumber,
                            status: BackgroundSyncStatusEnum.QUEUED
                        }))
                    }
                    return of(new CalibrationAction.UpdateCalibrationFailureAction(action.calibrationDetails, error.status))
                })
            )
        )
    ))

    
    public reopenCalibration: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.ReopenCalibration),
        switchMap((action: CalibrationAction.ReopenCalibration) =>
            this.responseHandlerService.query(() =>
                    this.calibrationService.reopenCalibration(action.workOrderNumber, action.equipmentId),
                this.calibrationStrategyHandler(action.calibrationDetails)
            ).pipe(
                tap(() => this.calibrationAlertService.removeAllAlert()),
                switchMap(status => {
                    if (status === 204) {
                        const _calibrationDetails = deepCopy(action.calibrationDetails)
                        _calibrationDetails.calibrationStatus = {
                            id: CalibrationStatusEnum.Draft,
                            name: CalibrationStatusEnum[CalibrationStatusEnum.Draft].toString()
                        }
                        return [
                            new CalibrationAction.GetCalibrationAction(action.workOrderNumber, action.equipmentId),
                            new UpdateWorkOrderStatusInTodoListAction(_calibrationDetails),
                            new UpdateWorkOrderEquipmentCalibrationStatusAction(_calibrationDetails),
                            new CalibrationAction.RemoveCachedCalibrationAction(action.workOrderNumber, action.equipmentId),
                            GetNotificationAction({
                                workOrderNumber: action.workOrderNumber,
                                notificationNumber: _calibrationDetails.notificationNumber
                            }),
                            LoadWorkOrderDetailsAction({ id: action.workOrderNumber, fetchNotification: false })
                        ]
                    } else {
                        return of({ type: 'NO_ACTION' })
                    }
                }),
                catchError(() => of({ type: 'NO_ACTION' }))
            )
        )
    ))

    
    public reopenNotification: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.ReopenNotification),
        switchMap((action: CalibrationAction.ReopenNotification) =>
            this.responseHandlerService.query(() =>
                this.calibrationService.reopenCalibration(action.workOrderNumber, action.equipmentId)
                .pipe(
                    switchMap(() => {
                        return [
                            new CalibrationAction.GetCalibrationAction(action.workOrderNumber, action.equipmentId),
                            GetNotificationAction({
                                workOrderNumber: action.workOrderNumber,
                                notificationNumber: action.calibrationDetails.notificationNumber
                            }),
                            LoadWorkOrderDetailsAction({ id: action.workOrderNumber, fetchNotification: false }),
                            new CalibrationAction.ReopenNotificationResult(true)
                        ]
                    }),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
            )
        )
    ))

    
    public resumeCalibration: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.ResumeCalibration),
        switchMap((action: CalibrationAction.ResumeCalibration) =>
            this.responseHandlerService.query(() =>
                this.calibrationService.resumeCalibration(action.calibrationDetails.workOrderNumber, action.calibrationDetails.equipmentId)
                .pipe(
                    switchMap(status => {
                        if (status === 204) {
                            const _calibrationDetails = deepCopy(action.calibrationDetails)
                            const { workOrderNumber, equipmentId } = _calibrationDetails
                            _calibrationDetails.finalPMResultStatus = null
                            _calibrationDetails.calibrationStatus = {
                                id: CalibrationStatusEnum.Draft,
                                name: CalibrationStatusEnum[CalibrationStatusEnum.Draft].toString()
                            }
                            const actions = [
                                new CalibrationAction.GetCalibrationAction(workOrderNumber, equipmentId),
                                new UpdateWorkOrderStatusInTodoListAction(_calibrationDetails),
                                new UpdateWorkOrderEquipmentCalibrationStatusAction(_calibrationDetails),
                                new CalibrationAction.RemoveCachedCalibrationAction(workOrderNumber, equipmentId),
                                GetNotificationAction({
                                    workOrderNumber: action.calibrationDetails.workOrderNumber,
                                    notificationNumber: action.calibrationDetails.notificationNumber
                                }),
                                LoadWorkOrderDetailsAction({ id: workOrderNumber, fetchNotification: false })
                            ] as any[]

                            if (action.hasMultipleTemplates && isNotAValue(_calibrationDetails.templateTypeId)) {
                                actions.push(new ShowModalAction(calibrationTemplateSelectionModalObject))
                            }

                            return actions
                        } else {
                            return of({ type: 'NO_ACTION' })
                        }
                    }),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
            )
        )
    ))

    
    public resyncCalibration: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.ReSyncCalibration),
        withLatestFrom(this.store.select(cacheCalibrations), this.store.select(pmUploadQueue)),
        switchMap(([action, cachedPMs, uploadQueue]: [CalibrationAction.ReSyncCalibrationAction, CalibrationDetails[], UploadQueue[]]) => {
            CalibrationCacheManager.removeUnwantedCache(this.store, cachedPMs, uploadQueue)

            let query: Observable<CalibrationDetails>
            /* eslint-disable-next-line */
            let { workOrderNumber, notificationNumber, equipmentId } = action
            const currentPM = CalibrationCacheManager.returnCurrentCacheCalibration(action, cachedPMs, uploadQueue)
            const isMultipleCalibrationSync = !workOrderNumber || !equipmentId

            workOrderNumber = currentPM?.workOrderNumber || workOrderNumber
            equipmentId = currentPM?.equipmentId || equipmentId

            if (currentPM.id) {
                query = this.responseHandlerService.query(() =>
                    this.calibrationService.updateCalibrationDetails(workOrderNumber, equipmentId, currentPM, true)
                )
            } else {
                query = this.responseHandlerService.query(() =>
                    this.calibrationService.createCalibrationDetails(currentPM, true)
                )
            }

            this.store.dispatch(new UpdatePMUploadQueueAction({
                workOrderNumber,
                notificationNumber,
                equipmentId,
                status: BackgroundSyncStatusEnum.SYNCING
            }))

            return query.pipe(
                tap(() => {
                    const remainingCachedCalibration = cachedPMs.filter(c =>
                        c.workOrderNumber !== workOrderNumber ||
                        c.equipmentId !== equipmentId
                    )

                    if (isMultipleCalibrationSync && remainingCachedCalibration.length > 0) {
                        this.store.dispatch(new CalibrationAction.ReSyncCalibrationAction())
                    }
                }),
                map((pmDetail) => new CalibrationAction.LoadCalibrationSuccessAction(pmDetail)),
                catchError((error: HttpErrorResponse) => {
                    this.calibrationAlertService.handleOfflineErrorAlert(error, currentPM)
                    this.store.dispatch(new UpdatePMUploadQueueAction({
                        workOrderNumber,
                        notificationNumber,
                        equipmentId,
                        status: BackgroundSyncStatusEnum.FAILED
                    }))
                    return of(new CalibrationAction.UpdateCalibrationFailureAction(currentPM, 0))
                })
            )
        })
    ))

    
    public getHyperlinkInfo: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(CalibrationAction.ActionType.GetHyperlinkInfo),
        switchMap((action: CalibrationAction.GetHyperlinkInfoAction) =>
            this.responseHandlerService.query(
                () => this.hyperlinkService.getHyperlinkByEquipmentId(action.equipmentId),
                new ResponseHandlingStrategyBuilder()
                .useRethrowError()
                .useShowToastrOnError(this.toastr)
                .useHandle404(null)
                    .responseStrategy
            )
            .pipe(
                map((hyperlinkInfo) => {
                    if (navigator.onLine) {
                        return new CalibrationAction.GetHyperlinkInfoSuccessAction({
                            hyperlinkInfo, hasError: false, equipmentId: action.equipmentId
                        })
                    } else {
                        return { type: 'NO_ACTION' }
                    }
                }),
                catchError(() => of(new CalibrationAction.GetHyperlinkInfoFailedAction(action.equipmentId)))
            )
        )
    ))

    private customStrategyLoader = new ResponseHandlingStrategyBuilder()
    .useRethrowError()
    .useShowToastrOnError(this.toastr)
    .useHandle404(null)
    .useShowLoader(this.store)
        .responseStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private calibrationAlertService: CalibrationAlertService,
        private workOrderService: WorkOrderService,
        private calibrationService: CalibrationService,
        private offlineService: OfflineService,
        private hyperlinkService: HyperlinkService,
        private toastr: ToastrService
    ) { }

    private calibrationStrategyHandler(calibrationDetail: CalibrationDetails): ResponseHandlingStrategy {
        return new ResponseHandlingStrategyBuilder()
        .useRethrowError()
        .useShowToastrOnCalibrationError(
            this.store,
            this.toastr,
            this.offlineService,
            calibrationDetail
        )
        .useShowLoader(this.store)
            .responseStrategy
    }
}
