import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms'

import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { FeetInchesInch16 } from '@app/modules/shared/models/engineering-units/feet-inches-inch16.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { FeetInch16CalculationService } from '../../services/feet-inch16-calculation.service'
import { FeetInch16FormatterService } from '../../services/feet-inch16-formatter.service'
import { NumericInputComponent } from '../../../mrma-ui-components/controls/numeric-input/numeric-input.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-feet-inches-inch16',
    templateUrl: './feet-inches-inch16.component.html',
    styleUrls: ['./feet-inches-inch16.component.scss'],
    standalone: true,
    imports: [NgIf, NumericInputComponent, ReactiveFormsModule, FormsModule, NgClass]
})
export class FeetInchesInch16Component extends SafeUnsubscriberComponent implements OnInit {

    @Input() form: UntypedFormControl
    @Input() isInch16 = false
    @Input() startingTabIndex: number
    @Input() onBlur: any
    @Input() displayAsText = false

    @Output() allInch16UnitHaveValue = new EventEmitter<boolean>()
    @Output() inchChange = new EventEmitter<number>()


    // This is the CSS class that should be applied
    // based on the current validationStatus.
    // IMPORTANT: This value will be set by validationStatus's setter
    public validationStatusCssClass = ''

    public calculatedValue: FeetInchesInch16
    public inch16Value: number = null


    constructor(
        private feetInch16CalculationService: FeetInch16CalculationService,
        private feetInch16FormatterService: FeetInch16FormatterService
    ) {
        super()
    }

    @Input() set validationStatus(v: CalibrationValidationStatus) {
        switch (v) {
            case CalibrationValidationStatus.Initialize:
                this.validationStatusCssClass = ''
                return
            case CalibrationValidationStatus.Valid:
                this.validationStatusCssClass = 'input-range-valid'
                return
            case CalibrationValidationStatus.Invalid:
                this.validationStatusCssClass = 'input-range-error'
                return
            default:
                this.validationStatusCssClass = ''
                return
        }
    }

    public get formattedValue(): string {
        if (this.inch16Value === null) {
            return ''
        }

        if (this.isInch16) {
            return this.feetInch16FormatterService.toShortString(this.inch16Value)
        }

        return this.feetInch16FormatterService.toLongString(this.inch16Value)
    }


    ngOnInit(): void {
        this.addSubscription(this.form.valueChanges.subscribe(_form => this.calcValue()))
        this.calcValue()
    }

    public valueChange(): void {
        if (this.isInch16) {
            this.form.setValue(this.inch16Value)
            this.inchChange.emit(this.inch16Value)
        } else {
            const inch16Value = this.feetInch16CalculationService.convertToInch16(this.calculatedValue)
            this.form.setValue(inch16Value)
            this.inchChange.emit(inch16Value)
            this.allInch16UnitHaveValue.next(this.feetInch16CalculationService.allInch16UnitHaveValue(this.calculatedValue))
        }
    }

    public _onBlur(): void {
        if (this.onBlur) {
            this.onBlur()
        }
    }

    private calcValue(): void {
        this.calculatedValue = this.feetInch16CalculationService.convertToFeetInchesInch16WhenValueValid(
            this.form.value,
            this.calculatedValue
        )
        this.inch16Value = this.form.value
    }
}
