import { createSelector } from '@ngrx/store'

import { EquipmentQueryParameter } from '@app/modules/equipment/models/equipment-query-parameter.model'
import { EquipmentTemplateMapping } from '@app/modules/equipment/models/equipment-template-mapping.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { deepCopy, sortArray } from '@app/utils/app-utils.function'
import { EquipmentTemplateMappingState, selectAll } from '../reducers/equipment-list.reducer'

export const selectEquipmentList = (state): EquipmentTemplateMappingState => state.equipmentState.equipmentList

export const equipmentList = createSelector(selectEquipmentList, selectAll)
export const equipmentListQuery = createSelector(
    equipmentList, (eqList: EquipmentTemplateMapping[], query: QuerySummary<EquipmentQueryParameter>) => {
        const copyOfEqList = deepCopy(eqList) as EquipmentTemplateMapping[]
        const sortedList = sortArray(copyOfEqList, query.queryParameters.sortBy, query.queryParameters.isDesc)
        const itemPerPage = parseInt(query.itemPerPage, 10)
        const startIndex = (query.currentPage - 1) * itemPerPage
        const endIndex = startIndex + itemPerPage

        return sortedList.slice(startIndex, endIndex)
    })

export const totalEquipmentCount = createSelector(selectEquipmentList, state => state.eqQueryParameter.totalItemCount)
export const equipmentListLoading = createSelector(selectEquipmentList, state => state.isListLoading)
export const equipmentListQueryParams = createSelector(selectEquipmentList, state => state.eqQueryParameter)
export const equipmentTemplateFilterOptions = createSelector(selectEquipmentList, state => state.templateFilterState)
export const equipmentLocationFilterOptions = createSelector(selectEquipmentList, state => state.locationFilterState)
export const equipmentCategoryFilterOptions = createSelector(selectEquipmentList, state => state.categoryFilterState)
export const equipmentPlantSectionFilterOptions = createSelector(selectEquipmentList, state => state.plantSectionFilterState)
export const equipmentABCIndicatorFilterOptions = createSelector(selectEquipmentList, state => state.abcIndicatorFilterState)
export const equipmentPlannerGroupFilterOptions = createSelector(selectEquipmentList, state => state.plannerGroupFilterState)
export const equipmentClassificationFilterOptions = createSelector(selectEquipmentList, state => state.classificationFilterState)
export const equipmentMainWorkCenterFilterOptions = createSelector(selectEquipmentList, state => state.mainWorkCenterFilterState)
export const equipmentMaintenancePlantFilterOptions = createSelector(selectEquipmentList, state => state.maintenancePlantFilterState)
export const serviceFluidOptions = createSelector(selectEquipmentList, state => state.serviceFluidListState)
