

import { environment } from '@environments/environment'

export enum LogLevel {
    Error = 0,
    Warning = 1,
    Info = 2,
    Verbose = 3
}

export class LoggerService {
    constructor(private prefix?: string) { }

    public warn(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Warning, message, ...optionalParams)
    }

    public info(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Info, message, ...optionalParams)
    }

    public error(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Error, message, ...optionalParams)
    }

    public log(level: LogLevel, message?: any, ...optionalParams: any[]): void {
        if (!environment.development) {
            return
        }

        let loggerFunc = console.log
        switch (level) {
            case LogLevel.Info:
                loggerFunc = console.log
                break

            case LogLevel.Warning:
                loggerFunc = console.warn
                break

            case LogLevel.Error:
                loggerFunc = console.error
                break

            default:
                break
        }

        if (this.prefix) {
            loggerFunc(`${this.prefix}:`, message, ...optionalParams)
        } else {
            loggerFunc(message, ...optionalParams)
        }
    }
}
