import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { combineLatest, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, retry, switchMap } from 'rxjs/operators'

import { EquipmentService } from '@app/modules/equipment/services/equipment.service'
import { EquipmentFilterUrl } from '@app/modules/shared/models/equipment-filter-url.model'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import {
    ActionType,
    GetCategoryListAction,
    GetCategoryListSuccessAction,
    GetClassificationListAction,
    GetClassificationListSuccessAction,
    GetCriticalityListAction,
    GetCriticalityListSuccessAction,
    GetEquipmentListAction,
    GetEquipmentListFailureAction,
    GetEquipmentListSuccessAction,
    GetLocationListAction,
    GetLocationListSuccessAction,
    GetMaintenancePlantListAction,
    GetMaintenancePlantListSuccessAction,
    GetMainWorkCenterListAction,
    GetMainWorkCenterListSuccessAction,
    GetPlannerGroupListAction,
    GetPlannerGroupListSuccessAction,
    GetPlantSectionListAction,
    GetPlantSectionListSuccessAction, GetServiceFluidListAction, GetServiceFluidListSuccessAction
} from '../actions/equipment-list.actions'

@Injectable()
export class EquipmentListEffects {
    
    getEquipments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetEquipmentListAction>(ActionType.GetEquipmentList),
        switchMap((action) => {
            const { queryParameters: q, currentPage, itemPerPage } = action.payload

            return combineLatest([of(action), this.responseHandlerService.query(() => this.equipmentService.getEquipments(
                q.search,
                q.plantCode,
                q.expandTemplate,
                q.criticality,
                q.plannerGroup,
                q.mainWorkCenter,
                q.maintenancePlant,
                q.plantSection,
                q.location,
                q.category,
                q.classification,
                q.templateTypeIds,
                q.sortBy,
                currentPage,
                itemPerPage as string),
                this.useSimpleStrategy
            )])
        }),
        map(([action, response]) => new GetEquipmentListSuccessAction({
            querySummary: JSON.parse(response.headers.get('Query-Summary')),
            result: response.body,
            replace: action.replace
        })),
        retry(3),
        catchError(() => {
            this.store.dispatch(new GetEquipmentListFailureAction())
            return of({ type: 'NO_ACTION' })
        })
    ))

    
    getCriticalityList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetCriticalityListAction>(ActionType.GetCriticalityList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.Criticality), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetCriticalityListSuccessAction(response.body))
                ))
    ))

    
    getPlannerGroupList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetPlannerGroupListAction>(ActionType.GetPlannerGroupList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.PlannerGroup),
                    this.useSimpleStrategy)
                .pipe(
                    map(response => new GetPlannerGroupListSuccessAction(response.body))
                ))
    ))

    
    getMainWorkCenterList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetMainWorkCenterListAction>(ActionType.GetMainWorkCenterList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.MainWorkCenter), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetMainWorkCenterListSuccessAction(response.body))
                ))
    ))

    
    getMaintenancePlantList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetMaintenancePlantListAction>(ActionType.GetMaintenancePlantList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.MaintenancePlant), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetMaintenancePlantListSuccessAction(response.body))
                ))
    ))

    
    getPlantSectionList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetPlantSectionListAction>(ActionType.GetPlantSectionList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.PlantSection), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetPlantSectionListSuccessAction(response.body))
                ))
    ))

    
    getLocationList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetLocationListAction>(ActionType.GetLocationList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.Location), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetLocationListSuccessAction(response.body))
                ))
    ))

    
    getCategoryList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetCategoryListAction>(ActionType.GetCategoryList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.Category), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetCategoryListSuccessAction(response.body))
                ))
    ))

    
    getClassificationList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetClassificationListAction>(ActionType.GetClassificationList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getEquipmentFilter(EquipmentFilterUrl.Classification), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetClassificationListSuccessAction(response.body))
                ))
    ))

    
    getServiceFluidList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetServiceFluidListAction>(ActionType.GetServiceFluidList),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.equipmentService.getServiceFluidOptionsList(), this.useSimpleStrategy)
                .pipe(
                    map(response => new GetServiceFluidListSuccessAction(response.body))
                ))
    ))

    private useSimpleStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private equipmentService: EquipmentService,
        private responseHandlerService: ResponseHandlerService
    ) {
        this.useSimpleStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy
    }
}
