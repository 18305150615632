import { Injectable } from '@angular/core'

import { FeetInchesInch16 } from '@app/modules/shared/models/engineering-units/feet-inches-inch16.model'
import { FeetInch16CalculationService } from './feet-inch16-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class FeetInch16FormatterService {

    constructor(private calculationService: FeetInch16CalculationService) { }

    public toShortString(value: number | FeetInchesInch16): string {
        let numeric: number = null
        if (typeof (value) === 'number') {
            numeric = value
        } else {
            numeric = this.calculationService.convertToInch16(value)
        }

        return `${numeric}/16`
    }

    public toLongString(value: number | FeetInchesInch16): string {
        let converted: FeetInchesInch16 = null
        if (typeof (value) === 'number') {
            converted = this.calculationService.convertToFeetInchesInch16(value)
        } else {
            converted = value
        }

        return `${converted.feet}' ${converted.inches}" ${converted.inch16}/16`
    }
}
