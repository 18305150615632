import { createAction, props, union } from '@ngrx/store'

import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

const actionPrefix = '[Equipment-Flamingo Form]'

export const GetEquipmentFlamingoFormMappingAction =
    createAction(`${actionPrefix} Get Equipment-Flamingo Forms Mapping`, props<{ equipmentId: string }>())
export const GetEquipmentFlamingoFormMappingSuccessAction =
    createAction(`${actionPrefix} Get Equipment-Flamingo Forms Mapping Success`, props<{ forms: FlamingoForm[] }>())
export const GetEquipmentFlamingoFormMappingFailAction =
    createAction(`${actionPrefix} Get Equipment-Flamingo Forms Mapping Fail`)

export const LinkFlamingoFormToEquipmentAction =
    createAction(`${actionPrefix} Link a Flamingo form to Equipment`, props<{ flamingoFormId: string, equipmentId: string }>())
export const LinkFlamingoFormToEquipmentSuccessAction =
    createAction(`${actionPrefix} Link a Flamingo form to Equipment Success`)
export const LinkFlamingoFormToEquipmentFailAction =
    createAction(`${actionPrefix} Link a Flamingo form to Equipment Fail`)

export const UnlinkFlamingoFormToEquipmentAction =
    createAction(`${actionPrefix} Unlink a Flamingo form to Equipment`, props<{ flamingoFormId: string, equipmentId: string }>())
export const UnlinkFlamingoFormToEquipmentSuccessAction =
    createAction(`${actionPrefix} Unlink a Flamingo form to Equipment Success`)
export const UnlinkFlamingoFormToEquipmentFailAction =
    createAction(`${actionPrefix} Unlink a Flamingo form to Equipment Fail`)


const all = union({
    GetEquipmentFlamingoFormMappingAction,
    GetEquipmentFlamingoFormMappingSuccessAction,
    GetEquipmentFlamingoFormMappingFailAction,
    LinkFlamingoFormToEquipmentAction,
    LinkFlamingoFormToEquipmentSuccessAction,
    LinkFlamingoFormToEquipmentFailAction,
    UnlinkFlamingoFormToEquipmentAction,
    UnlinkFlamingoFormToEquipmentSuccessAction,
    UnlinkFlamingoFormToEquipmentFailAction
})
export type EquipmentFlamingoAction = typeof all
