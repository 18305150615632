import { Injectable } from '@angular/core'

import { isNotAValue } from '@app/utils/app-utils.function'
import { OncePointAdminFormRawData } from '../../models/one-point-admin-form-raw-data.model'
import { SafetyValveAdminFormRawDataModel } from '@app/modules/equipment/models/safety-valve-admin-form-raw-data.model'

@Injectable({
    providedIn: 'root'
})
export class ToleranceFormValidationsService {

    public isOnePointFormValid(model: OncePointAdminFormRawData): boolean {
        if (!model.process ||
            !this.isToleranceValid(model.tolerance) ||
            !this.isSetPointValid(model.setPoint) ||
            !this.isExpectedReadingValid(model.expectedReading)
        ) {
            return false
        }

        if (this.isSetPointHasDifferentValueAndSameUoMsWithExpectedReading(model.setPoint, model.expectedReading)) {
            return false
        }

        return (model.tolerance.unitOfMeasurement.uomCode === model.expectedReading.unitOfMeasurement.uomCode)
    }

    public isSafetyValveFormValid(model: SafetyValveAdminFormRawDataModel): boolean {
        if (!model.process ||
            !this.isSetPointValid(model.setPoint) ||
            !this.isToleranceValid(model.tolerance) ||
            !this.isToleranceValid(model.asLeftTolerance) ||
            !this.isExpectedReadingValid(model.expectedReading) ||
            !this.isExpectedReadingValid(model.asLeftExpectedReading)
        ) {
            return false
        }

        return !(this.isSetPointHasDifferentValueAndSameUoMsWithExpectedReading(model.setPoint, model.expectedReading) ||
            this.isSetPointHasDifferentValueAndSameUoMsWithExpectedReading(model.setPoint, model.asLeftExpectedReading))
    }

    private isToleranceValid(tolerance: any): boolean {
        return tolerance && tolerance.unitOfMeasurement && !isNotAValue(tolerance.lowerRange) && !isNotAValue(tolerance.higherRange)
            && tolerance.lowerRange <= 0 && tolerance.higherRange >= 0
    }

    private isExpectedReadingValid(expectedReading: any): boolean {
        return expectedReading.unitOfMeasurement && !isNotAValue(expectedReading.value)
    }

    private isSetPointValid(setPoint: any): boolean {
        return setPoint.unitOfMeasurement && !isNotAValue(setPoint.value)
    }

    private isSetPointHasDifferentValueAndSameUoMsWithExpectedReading(setPoint: any, expectedReading: any): boolean {
        return setPoint.unitOfMeasurement.uomCode === expectedReading.unitOfMeasurement.uomCode &&
            setPoint.value !== expectedReading.value
    }
}
