

import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { ActionType, EquipmentAction } from '../actions/equipment.actions'

export interface EquipmentState {
    currentEquipment: Equipment
}

export const initialEquipmentState: EquipmentState = {
    currentEquipment: null
}

export function reducer(state = initialEquipmentState, action: EquipmentAction): EquipmentState {
    switch (action.type) {
        case ActionType.GetEquipmentDetailsSuccess:
            return {
                ...state,
                currentEquipment: action.payload
            }
        case ActionType.UpdateEquipmentNoteSuccess:
            return {
                ...state,
                currentEquipment: action.payload
            }
        default:
            return state
    }
}
