import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { CalibrationActionRequired } from '@app/modules/calibration/services/calibration-action-required.service'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { deepCopy } from '@app/utils/app-utils.function'
import { ActionType, ToDoAction } from './to-do.actions'

export interface ToDoState extends EntityState<WorkOrderListItem> {
    isLoading: boolean
}

export const adapter: EntityAdapter<WorkOrderListItem>
    = createEntityAdapter<WorkOrderListItem>({
        selectId: workOrder => workOrder.workOrderNumber
    })

const initialState: ToDoState = adapter.getInitialState({
    isLoading: false
})

export function reducer(state = initialState, action: ToDoAction): ToDoState {
    switch (action.type) {
        case ActionType.GetToDo:
            return {
                ...state,
                isLoading: true
            }
        case ActionType.GetToDoSuccess:
            if (navigator.onLine) {
                return adapter.setAll(
                    action.payload, {
                    ...state,
                    isLoading: false
                }
                )
            }
            return {
                ...state,
                isLoading: false
            }
        case ActionType.DeleteToDoSuccess:
            return adapter.removeOne(action.payload.workOrderNumber, state)
        case ActionType.DeleteAllToDosSuccess:
            const workOrderNumbers = action.payload.map(workOrder => workOrder.workOrderNumber)
            return adapter.removeMany(workOrderNumbers, state)
        case ActionType.UpdateToDoWorkOrderStatus:
            const { workOrderNumber, equipmentId, calibrationStatus, finalPMResultStatus } = action.calibrationDetails
            const wo = adapter.getSelectors()
                .selectAll(state)
                .find(e => e.workOrderNumber === workOrderNumber)

            const copyOfWo = deepCopy(wo) as WorkOrderListItem
            const equipment = copyOfWo?.equipments.find(e => e.equipmentId === equipmentId)

            if (!equipment) {
                return {
                    ...state
                }
            }

            equipment.calibrationStatus = calibrationStatus.name
            equipment.finalPMResultStatus = finalPMResultStatus
            equipment.actionRequired = CalibrationActionRequired.getRequiredAction(action.calibrationDetails)

            return adapter.updateOne({
                id: workOrderNumber,
                changes: copyOfWo
            }, state)
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()
