import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { EquipmentHistoryService } from '@app/modules/calibration/services/equipment-history.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { ActionType, GetEquipmentHistoryAction, GetEquipmentHistorySuccessAction } from './equipment-history.actions'

@Injectable()
export class EquipmentHistoryEffects {

    
    public getEquipmentHistory: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetEquipmentHistory),
        switchMap((action: GetEquipmentHistoryAction) => {
            return this.responseHandlerService.query(() =>
                this.equipmentHistoryService.getEquipmentHistory(action.payload), this.customStrategy)
                .pipe(map(response => new GetEquipmentHistorySuccessAction(response)))
        })
    ))
    private customStrategy = new ResponseHandlingStrategyBuilder()
        .useRethrowError()
        .useShowToastrOnError(this.toastr)
        .useShowSectionLoader(this.store, SectionLoaderEnum.EquipmentHistory)
        .responseStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private equipmentHistoryService: EquipmentHistoryService,
        private toastr: ToastrService
    ) { }
}
