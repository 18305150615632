<div *ngIf="!displayAsText else textOnly">
    <div *ngIf="isInch16 else inch16">
        <app-numeric-input [(ngModel)]="inch16Value"
                           (emitWhenInputLosingFocus)="valueChange()"
                           (inputBlur)="_onBlur()"
                           placeholder="/16"
                           [disabled]="form.disabled"
                           [allowNegative]="false"
                           [decimalPlaces]="0"></app-numeric-input>
    </div>
    <ng-template #inch16>
        <div class="feet-inch16--wrapper">
            <app-numeric-input class="feet-inch16"
                               [(ngModel)]="calculatedValue.feet"
                               (emitWhenInputLosingFocus)="valueChange()"
                               (inputBlur)="_onBlur()"
                               placeholder="'"
                               [ngClass]="validationStatusCssClass"
                               [disabled]="form.disabled"
                               [allowNegative]="false"
                               [decimalPlaces]="0"
                               textAlign="center"
                               suffix="'"
                               [tabIndex]="startingTabIndex"></app-numeric-input>

            <app-numeric-input class="feet-inch16"
                               [max]="11"
                               [(ngModel)]="calculatedValue.inches"
                               (emitWhenInputLosingFocus)="valueChange()"
                               (inputBlur)="_onBlur()"
                               placeholder='"'
                               [ngClass]="validationStatusCssClass"
                               [disabled]="form.disabled"
                               [allowNegative]="false"
                               [decimalPlaces]="0"
                               textAlign="center"
                               suffix='"'
                               [tabIndex]="startingTabIndex + 1"></app-numeric-input>

            <app-numeric-input class="feet-inch16"
                               [max]="15"
                               [(ngModel)]="calculatedValue.inch16"
                               (emitWhenInputLosingFocus)="valueChange()"
                               (inputBlur)="_onBlur()"
                               placeholder="/16"
                               [ngClass]="validationStatusCssClass"
                               [disabled]="form.disabled"
                               [allowNegative]="false"
                               [decimalPlaces]="0"
                               textAlign="center"
                               suffix="/16"
                               [tabIndex]="startingTabIndex + 2"></app-numeric-input>
        </div>
    </ng-template>
</div>
<ng-template #textOnly>
    {{ formattedValue }}
</ng-template>
