import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { DropdownOption } from '@app/modules/shared/components/dropdown/dropdown-no-form.component'

export const mapUomObjToUomDisplay = (uom: UOM) => {
    return `${uom.uomGroupName} - ${uom.uomName} (${uom.uomCodeForTech})`
}

export const uomListToUomOptions = (uomLs: UOM[]): DropdownOption<UOM>[] => uomLs.map(uom => {
    return {
        displayValue: mapUomObjToUomDisplay(uom),
        value: uom
    }
})
