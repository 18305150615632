import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { CalibrationPresets } from '@app/modules/report/constants/report-filter-list.constant'
import { presetListMapApiData } from '@app/modules/report/utils/utils'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { ReportService } from '@app/modules/report/services/report.service'
import {
    ActionType, AddFilterPresetAction, AddFilterPresetSuccessAction, ApproveCalibrationAction, DeleteFilterPresetAction,
    DeleteFilterPresetSuccessAction, EditFilterPresetAction, EditFilterPresetSuccessAction, GetFilterPresetListAction,
    GetFilterPresetListSuccessAction, GetReportDetailAction, GetReportDetailSuccessAction, GetReportListAction, GetReportListFailureAction,
    GetReportListSuccessAction, RejectCalibrationAction, RequestWorkManagementReportAction, SelectedFilterPresetOptionAction
} from './report.actions'

@Injectable()
export class ReportEffects {

    
    getReportListEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetReportListAction>(ActionType.GetReportList),
        mergeMap((action) => {
            const { queryParameters: q, currentPage, itemPerPage } = action.payload
            const query = { ...q, currentPage, itemPerPage }
            return this.responseHandlerService.query(() =>
                this.reportService.getCalibrationReportList(query),
                this.useSimpleStrategy
            ).pipe(
                map(response => new GetReportListSuccessAction({
                    querySummary: JSON.parse(response.headers.get('Query-Summary')),
                    result: response.body,
                    replace: action.replace
                })),
                catchError(() => {
                    this.store.dispatch(new GetReportListFailureAction())
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))
    
    loadReportDetail: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetReportDetailAction>(ActionType.GetReportDetail),
        exhaustMap(action => this.responseHandlerService.query(() =>
            this.reportService.getCalibrationReportDetail(action.payload, false)
        ).pipe(
            map(report => new GetReportDetailSuccessAction(report))
        ))
    ))


    
    rejectCalibration: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<RejectCalibrationAction>(ActionType.RejectCalibration),
        exhaustMap(action => this.responseHandlerService.query(() =>
            this.reportService.rejectCalibration(action.payload.workOrderId, action.payload.equipmentId), this.customStrategyReportDetails
        ).pipe(
            tap(status => {
                if (status === 204) {
                    this.store.dispatch(new GetReportDetailAction(action.payload.workOrderId))
                }
            }),
            map((reponse) => ({
                type: 'NO_ACTION', payload: reponse
            }))
        ))
    ))

    
    approveCalibration: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApproveCalibrationAction>(ActionType.ApproveCalibration),
        exhaustMap(action => this.responseHandlerService.query(() =>
            this.reportService.approveCalibration(action.payload.workOrderId, action.payload.equipmentId), this.customStrategyReportDetails
        ).pipe(
            tap(status => {
                if (status === 204) {
                    this.store.dispatch(new GetReportDetailAction(action.payload.workOrderId))
                }
            }),
            map((reponse) => ({
                type: 'NO_ACTION', payload: reponse
            }))
        ))
    ))

    
    requestWorkManagementReport: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<RequestWorkManagementReportAction>(ActionType.RequestWorkManagementReport),
        switchMap(action => {
            return this.responseHandlerService.query(() =>
                this.reportService.requestWorkOrderManagement(action.query)
            )
        }),
        map(response => {
            if (response.status === 202) {
                this.toastr.success(
                    'Request received. It will take up to 15 minutes to generate the report. We will send it to you via email.',
                    null,
                    mrmaAlertConfigs.workflowInfo.configuration)
            }
            return { type: 'NO_ACTION' }
        })

    ))

    
    getFiltersList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetFilterPresetListAction>(ActionType.GetFilterPresetList),
        mergeMap(() => {
            return this.responseHandlerService.query(() =>
                this.reportService.getReportFiltersList(),
                this.useSimpleStrategy
            ).pipe(
                map(response => new GetFilterPresetListSuccessAction(response.body)),
                catchError(() => {
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))

    
    addFilters: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<AddFilterPresetAction>(ActionType.AddFilterPreset),
        withLatestFrom(this.store),
        mergeMap(([action, storeState]) => {
            const { reportFilterList, query } = storeState.report
            return this.responseHandlerService.query(
                () => this.reportService.saveReportFilters(
                    presetListMapApiData({
                        name: action.payload.name,
                        childFilter: {
                            queryParams: query.queryParameters,
                            reportFilters: reportFilterList
                        },
                    }, true)
                )
            ).pipe(
                map((response) => {
                    this.toastr.success('Filter preset saved successfully!')
                    return new AddFilterPresetSuccessAction({
                        id: response.body.id,
                        name: response.body.presetName,
                        childFilter: {
                            queryParams: query.queryParameters,
                            reportFilters: reportFilterList
                        },
                        selected: response.body.isDefault
                    })
                }),
                catchError(() => {
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))

    
    deleteFilters: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<DeleteFilterPresetAction>(ActionType.DeleteFilterPreset),
        mergeMap((action) => {
            return this.responseHandlerService.query(() =>
                this.reportService.deleteReportFilters(action.payload.id),
            ).pipe(
                map(() => {
                    this.toastr.success('Filter preset deleted successfully!')
                    return new DeleteFilterPresetSuccessAction({
                        id: action.payload.id,
                    })
                }),
                catchError(() => {
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))

    
    editFilters: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<EditFilterPresetAction>(ActionType.EditFilterPreset),
        withLatestFrom(this.store),
        mergeMap(([action, storeState]) => {
            const { filterPresetList, reportFilterList, query } = storeState.report
            const isOnlyChangeName = !!action.payload.name
            const editItem: CalibrationPresets = filterPresetList.find(item => item.id === action.payload.id)
            let requestParams = {
                ...presetListMapApiData({
                    ...editItem,
                    childFilter: {
                        queryParams: query.queryParameters,
                        reportFilters: reportFilterList
                    }
                }, true)
            }
            if (isOnlyChangeName) {
                const isUpdateCurrentSelectedItem = filterPresetList.find(item => item.selected)?.id === action.payload.id
                requestParams = {
                    ...presetListMapApiData(editItem, isUpdateCurrentSelectedItem),
                    presetName: action.payload.name
                }
            }
            return this.responseHandlerService.query(
                () => this.reportService.editReportFilters(requestParams)
            ).pipe(
                map((response) => {
                    this.toastr.success('Filter preset saved successfully!')
                    let successCallBackData: CalibrationPresets = {
                        id: response.body.id,
                        name: response.body.presetName,
                        childFilter: {
                            queryParams: query.queryParameters,
                            reportFilters: reportFilterList
                        }
                    }

                    if (isOnlyChangeName) {
                        successCallBackData = {
                            id: response.body.id,
                            name: response.body.presetName,
                        }
                    }
                    return new EditFilterPresetSuccessAction(successCallBackData)
                }),
                catchError(() => {
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))

    
    selectedPreset: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<SelectedFilterPresetOptionAction>(ActionType.SelectedFilterPresetOption),
        withLatestFrom(this.store),
        mergeMap(([, storeState]) => {
            const { filterPresetList } = storeState.report
            const editItem: CalibrationPresets = filterPresetList.find(item => item.selected)
            return this.responseHandlerService.query(
                () => this.reportService.editReportFilters(presetListMapApiData(editItem, true)),
                this.useSimpleStrategy
            ).pipe(
                map(() => {
                    return { type: 'NO_ACTION' }
                }),
                catchError(() => {
                    return of({ type: 'NO_ACTION' })
                })
            )
        })
    ))

    private useSimpleStrategy: ResponseHandlingStrategy
    private customStrategyReportDetails: ResponseHandlingStrategy

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private reportService: ReportService,
        private responseHandlerService: ResponseHandlerService,
        private toastr: ToastrService
    ) {
        this.useSimpleStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy

        this.customStrategyReportDetails = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.ReportDetails)
            .responseStrategy
    }
}
