import { ActionReducerMap } from '@ngrx/store'

import * as calibration from '@app/store/calibration/calibration.reducer'
import * as connection from '@app/store/connection/connection.reducer'
import * as customForm from '@app/store/custom-form/custom-form.reducer'
import * as equipmentHistory from '@app/store/equipment-history/equipment-history.reducer'
import * as equipment from '@app/store/equipment/equipment.store'
import * as identity from '@app/store/identity/identity.reducer'
import * as loader from '@app/store/loader/loader.reducer'
import * as maintenancePlan from '@app/store/maintenance-plan/maintenance-plan.reducer'
import * as modal from '@app/store/modal/modal.reducer'
import * as offline from '@app/store/offline/offline.reducer'
import * as overlay from '@app/store/overlay/overlay.reducer'
import * as pageHistory from '@app/store/page-history/page-history.reducer'
import * as report from '@app/store/report/report.reducer'
import * as sapdataSync from '@app/store/sap-data-sync/sap-data-sync.reducer'
import * as selectionDrawer from '@app/store/selection-drawer/selection-drawer.reducer'
import * as testEquipment from '@app/store/test-equipment/test-equipment.reducer'
import * as toDo from '@app/store/to-do/to-do.reducer'
import * as user from '@app/store/user/user.reducer'
import * as workOrderSearch from '@app/store/work-order-search/search.reducer'
import * as workOrder from '@app/store/work-order/work-order.reducer'
import { AppStoreMonitoringMiddleware } from './app-store-monitoring-middleware'
import { AttachmentEffects } from './attachment/attachment.effects'
import * as attachment from './attachment/attachment.reducer'
import { CalibrationEffects } from './calibration/calibration.effects'
import { CustomFormEffects } from './custom-form/custom-form.effects'
import { EquipmentHistoryEffects } from './equipment-history/equipment-history.effects'
import { FlamingoEffects } from './flamingo/flamingo.effects'
import * as flamingo from './flamingo/flamingo.reducer'
import { IdentityEffects } from './identity/identity.effects'
import { MaintenancePlanEffects } from './maintenance-plan/maintenance-plan.effects'
import { OfflineEffects } from './offline/offline.effects'
import { ReportEffects } from './report/report.effects'
import { SapDataSyncEffects } from './sap-data-sync/sap-data-sync.effects'
import { TestEquipmentEffects } from './test-equipment/test-equipment.effects'
import { ToDoEffects } from './to-do/to-do.effects'
import * as tooltip from './tooltip/tooltip.reducer'
import { UsersEffects } from './user/user.effects'
import { SearchEffects } from './work-order-search/search.effects'
import { WorkOrderEffects } from './work-order/work-order.effects'

export interface AppState {
    identity: identity.IdentityState
    connection: connection.ConnectionState
    loader: loader.LoaderState
    calibration: calibration.CalibrationState
    equipmentState: equipment.EquipmentIndexState
    equipmentHistory: equipmentHistory.EquipmentHistoryState
    modalContainer: modal.ModalState
    report: report.ReportState
    testEquipment: testEquipment.TestEquipmentState
    user: user.UserState
    workOrder: workOrder.WorkOrderState
    workOrderSearch: workOrderSearch.SearchState
    offline: offline.OfflineState
    toDo: toDo.ToDoState
    pageHistory: pageHistory.PageHistoryState,
    overlay: overlay.OverlayToggleState,
    selectionDrawer: selectionDrawer.SelectionDrawerCollectionState,
    customForm: customForm.CustomFormState,
    maintenancePlan: maintenancePlan.MaintenancePlanState,
    attachment: attachment.AttachmentState,
    flamingo: flamingo.FlamingoState,
    sapDataSync: sapdataSync.SapDataSyncState,
    tooltip: tooltip.TooltipAcknowledgeState
}

export const appReducers: ActionReducerMap<AppState> = {
    identity: identity.reducer,
    connection: connection.reducer,
    loader: loader.reducer,
    calibration: calibration.reducer,
    equipmentState: equipment.equipmentIndexReducers,
    equipmentHistory: equipmentHistory.reducer,
    modalContainer: modal.reducer,
    report: report.reducer,
    testEquipment: testEquipment.reducer,
    user: user.reducer,
    workOrder: workOrder.reducer,
    workOrderSearch: workOrderSearch.reducer,
    offline: offline.reducer,
    toDo: toDo.reducer,
    pageHistory: pageHistory.reducer,
    overlay: overlay.reducer,
    selectionDrawer: selectionDrawer.reducer,
    customForm: customForm.reducer,
    maintenancePlan: maintenancePlan.reducer,
    attachment: attachment.reducer,
    flamingo: flamingo.reducer,
    sapDataSync: sapdataSync.reducer,
    tooltip: tooltip.reducer
}

export const appEffects = [
    ...equipment.equipmentIndexEffects,
    CalibrationEffects,
    IdentityEffects,
    EquipmentHistoryEffects,
    ReportEffects,
    TestEquipmentEffects,
    UsersEffects,
    WorkOrderEffects,
    SearchEffects,
    OfflineEffects,
    ToDoEffects,
    CustomFormEffects,
    MaintenancePlanEffects,
    AttachmentEffects,
    AppStoreMonitoringMiddleware,
    FlamingoEffects,
    SapDataSyncEffects
]
