import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { combineLatest, of } from 'rxjs'
import { map, mergeMap, switchMap } from 'rxjs/operators'

import { RouteName } from '@app/models/route-name.enum'
import { MaintenancePlanQuery } from '@app/modules/maintenance-plan/models/maintenance-plan-query.model'
import { MaintenancePlanService } from '@app/modules/maintenance-plan/services/maintenance-plan.service'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppSettings } from '@settings/app.settings'
import { AppState } from '../app.store'
import {
    GetMaintenanceItemLongText, GetMaintenanceItemLongTextSuccess, GetMaintenancePlanDetailAction, GetMaintenancePlanDetailSuccessAction,
    GetMaintenancePlanListAction, GetMaintenancePlanListSuccessAction, GetMaintenancePlanLongText, GetMaintenancePlanLongTextSuccess
} from './maintenance-plan.actions'

@Injectable()
export class MaintenancePlanEffects {

    getMaintenancePlanListEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetMaintenancePlanListAction),
            mergeMap((query) => {
                return combineLatest([
                    of(query),
                    this.responseHandlerService.query(
                        () => this.maintenancePlanService.getMaintenancePlanList({
                            ...query,
                            // Also fetch next few pages in advance so things feel a bit faster
                            itemPerPage: Math.max(query.itemPerPage, AppSettings.maintenancePlanListTotalRowPerPage)
                        }),
                        this.customStrategy
                    )
                ])
            }),
            map(([query, response]) => {
                const querySummary = JSON.parse(response.headers.get('query-summary')) as QuerySummary<MaintenancePlanQuery>
                const maintenancePlanList = response.body

                // Send new data to the store
                return GetMaintenancePlanListSuccessAction({
                    summary: querySummary,
                    content: maintenancePlanList,
                })
            })
        )
    )

    getMaintenancePlanDetailEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetMaintenancePlanDetailAction),
            switchMap(({ maintenancePlanNumber }) => {
                return this.responseHandlerService.query(() => this.maintenancePlanService.getMaintenancePlanDetail(maintenancePlanNumber))
            }),
            map(response => {
                const maintenancePlanDetail = response.body
                return GetMaintenancePlanDetailSuccessAction({
                    maintenancePlan: maintenancePlanDetail
                })
            })
        )
    )

    getMaintenancePlanLongTextEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetMaintenancePlanLongText),
            switchMap(({ maintenancePlanNumber }) => {
                return combineLatest([
                    of(maintenancePlanNumber),
                    this.responseHandlerService.query(
                        () => this.maintenancePlanService.getMaintenancePlanLongText(maintenancePlanNumber),
                        this.customStrategy
                    )
                ])
            }),
            map(([maintenancePlanNumber, response]) => {
                const longText = response.body
                return GetMaintenancePlanLongTextSuccess({ maintenancePlanNumber, longText })
            })
        )
    )

    getMaintenanceItemLongTextEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetMaintenanceItemLongText),
            switchMap(({ maintenanceItemNumber }) => {
                return combineLatest([
                    of(maintenanceItemNumber),
                    this.responseHandlerService.query(
                        () => this.maintenancePlanService.getMaintenanceItemLongText(maintenanceItemNumber),
                        this.customStrategy
                    )
                ])
            }),
            map(([maintenanceItemNumber, response]) => {
                const longText = response.body
                return GetMaintenanceItemLongTextSuccess({ maintenanceItemNumber, longText })
            })
        )
    )

    private readonly loaderBarVisbileOnRoutes = [RouteName.MaintenancePlan]

    private customStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private maintenancePlanService: MaintenancePlanService,
        private responseHandlerService: ResponseHandlerService,
        private toastrService: ToastrService,
        private store: Store<AppState>
    ) {
        this.customStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastrService)
            .responseStrategy
    }
}
