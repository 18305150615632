import { combineReducers } from '@ngrx/store'

import { EquipmentFlamingoFormEffects } from './effects/equipment-flamingo-form.effects'
import { EquipmentListEffects } from './effects/equipment-list.effects'
import { EquipmentPMOSettingEffects } from './effects/equipment-pmo.effects'
import { EquipmentTemplateEffects } from './effects/equipment-template.effects'
import { EquipmentEffects } from './effects/equipment.effects'
import { ProcessEffect } from './effects/process.effects'
import { SapEquipmentEffects } from './effects/sap-equipment.effects'
import { TemplateTypeEffects } from './effects/template-type.effects'
import { UOMEffects } from './effects/uom.effects'
import * as equipmentFlamingoForms from './reducers/equipment-flamingo-form.reducer'
import * as equipmentList from './reducers/equipment-list.reducer'
import * as fromEquipmentPMO from './reducers/equipment-pmo.reducer'
import * as equipmentTemplate from './reducers/equipment-template.reducer'
import * as equipment from './reducers/equipment.reducer'
import * as process from './reducers/process.reducer'
import * as sapEquipment from './reducers/sap-equipment.reducer'
import * as templateForm from './reducers/template-form.reducer'
import * as templateType from './reducers/template-type.reducer'
import * as uom from './reducers/uom.reducer'

export interface EquipmentIndexState {
    equipment: equipment.EquipmentState,
    sapEquipment: sapEquipment.SapEquipmentState,
    equipmentList: equipmentList.EquipmentTemplateMappingState,
    templateType: templateType.TemplateTypeState,
    process: process.ProcessState,
    uom: uom.UOMState,
    equipmentTemplate: equipmentTemplate.EquipmentTemplateState,
    templateForm: templateForm.TemplateFormState,
    pmo: fromEquipmentPMO.EquipmentPMOState,
    flamingoForms: equipmentFlamingoForms.EquipmentFlamingoFormMappingState
}

export const equipmentIndexReducers = combineReducers({
    equipment: equipment.reducer,
    sapEquipment: sapEquipment.reducer,
    equipmentList: equipmentList.reducer,
    templateType: templateType.reducer,
    process: process.reducer,
    uom: uom.reducer,
    equipmentTemplate: equipmentTemplate.reducer,
    templateForm: templateForm.reducer,
    pmo: fromEquipmentPMO.reducer,
    flamingoForms: equipmentFlamingoForms.reducer
})

export const equipmentIndexEffects = [
    EquipmentTemplateEffects,
    EquipmentEffects,
    SapEquipmentEffects,
    TemplateTypeEffects,
    EquipmentListEffects,
    UOMEffects,
    ProcessEffect,
    EquipmentPMOSettingEffects,
    EquipmentFlamingoFormEffects
]
