import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ProgressRef } from '@app/modules/shared/services/section-loader.service'
import { Store } from '@ngrx/store'
import { AppState } from '@app/store/app.store'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { AddSectionLoaderAction, RemoveSectionLoaderAction } from '@app/store/loader/loader.actions'

export class ShowSectionLoaderStrategy extends ResponseHandlingStrategy {
    private progressRef: ProgressRef

    constructor(
        next: ResponseHandlingStrategy,
        private store: Store<AppState>,
        private sectionName: SectionLoaderEnum
    ) {
        super(next)
    }

    public init(): void {
        this.store.dispatch(new AddSectionLoaderAction(this.sectionName))
        super.init()
    }

    public finalize(): void {
        this.store.dispatch(new RemoveSectionLoaderAction(this.sectionName))
        super.finalize()
    }
}
