import { createSelector } from '@ngrx/store'

import { User } from '@app/models/user.model'
import { RoleEnum } from '@app/modules/shared/services/permission.service'
import { selectedPlant } from '@app/store/identity/identity.selectors'
import { sortArray } from '@app/utils/app-utils.function'
import { selectAll, UserState } from './user.reducer'

export const userState = (state): UserState => {
    return state.user
}

export const allUser = createSelector(userState, selectAll)
export const selectedUser = createSelector(userState, user => user.selectedUser)
export const querySummary = createSelector(userState, user => user.querySummary)
export const techniciansInCurrentPlant = createSelector(allUser, selectedPlant, (users, plantCode) => {
    return users
        .filter(user =>
            user.roles.some(role =>
                role.plantCode === plantCode && role.roleId === RoleEnum.Technician
            )
        )
})
export const techniciansInCurrentPlantExceptPrimary = createSelector(techniciansInCurrentPlant,
    (users: User[], props: User) => users.filter(user => user.guid !== props.guid))
export const usersInCurrentPlant = createSelector(allUser, selectedPlant, querySummary, (users, plantCode, query) => {
    if (query.queryParameters.roleId) {
        const roles = query.queryParameters.roleId.replace(' ', '').split(',')
        users = users.filter(user =>
            user.roles.some(role =>
                role.plantCode === plantCode
                && roles.includes(role.roleId.toString())))
    }

    if (query.queryParameters.keyword) {
        users = users.filter(user => user.displayName.toLowerCase().includes(
            query.queryParameters.keyword.toLowerCase()))
    }

    if (query.queryParameters.sortBy) {
        const isDescending = query.queryParameters.sortBy.charAt(0) === '-'
        const sortColumn = isDescending ? query.queryParameters.sortBy.substring(1) : query.queryParameters.sortBy
        users = sortArray(users, sortColumn, isDescending)
    } else {
        users = sortArray(users, 'displayName', false)
    }

    return users
})
export const getUserListFetchingProgress = createSelector(userState, user => user.userListFetchingInProgress)
export const getUserListLastSyncDate = createSelector(userState, user => user.userListLastSyncDate)
