import { Pipe, PipeTransform } from '@angular/core'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { mapUomObjToUomDisplay } from '@app/utils/uom/uom.util'


export type UOMPresentation = UOM & {
    uomDisplayValue: string
}

@Pipe({
    name: 'uomPresentationPipe',
    standalone: true
})
export class UomPresentationPipePipe implements PipeTransform {

    private static toUomPresentation(uom: UOM): UOMPresentation {
        return {
            ...uom,
            uomDisplayValue: mapUomObjToUomDisplay(uom)
        }
    }

    transform(value: UOM[]): UOMPresentation[] {
        return value.map(UomPresentationPipePipe.toUomPresentation)
    }

}
