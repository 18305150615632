import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { FilterFields } from '@app/modules/equipment/models/equipment-filter-fields.model'
import { equipmentFilterSetting } from '@app/modules/equipment/models/equipment-list-sort.contant'
import { EquipmentQueryParameter } from '@app/modules/equipment/models/equipment-query-parameter.model'
import { EquipmentTemplateMapping } from '@app/modules/equipment/models/equipment-template-mapping.model'
import { TemplateTypeFilter } from '@app/modules/equipment/models/template-type-filter.model'
import { EquipmentTemplateType } from '@app/modules/equipment/models/template-type.model'
import { SortedState } from '@app/modules/shared/models/filter.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { deepCopy, isNotAValue } from '@app/utils/app-utils.function'
import { ActionType, EquipmentListAction } from '../actions/equipment-list.actions'
import { ServiceFluid } from '@app/modules/equipment/models/equipment-list-api-response.model'

export interface EquipmentTemplateMappingState extends EntityState<EquipmentTemplateMapping> {
    sortedState: SortedState[]
    templateFilterState: TemplateTypeFilter[]
    abcIndicatorFilterState: FilterFields[]
    plannerGroupFilterState: FilterFields[]
    mainWorkCenterFilterState: FilterFields[]
    maintenancePlantFilterState: FilterFields[]
    plantSectionFilterState: FilterFields[]
    locationFilterState: FilterFields[]
    categoryFilterState: FilterFields[]
    classificationFilterState: FilterFields[]
    eqQueryParameter: QuerySummary<EquipmentQueryParameter>
    serviceFluidListState: ServiceFluid[],
    isListLoading: boolean
}

export const adapter: EntityAdapter<EquipmentTemplateMapping> = createEntityAdapter<EquipmentTemplateMapping>({
    selectId: equipmentList => equipmentList.equipmentId
})

export const initialEquipmentState: EquipmentTemplateMappingState = adapter.getInitialState({
    sortedState: equipmentFilterSetting,
    templateFilterState: [],
    abcIndicatorFilterState: [],
    plannerGroupFilterState: [],
    mainWorkCenterFilterState: [],
    maintenancePlantFilterState: [],
    plantSectionFilterState: [],
    locationFilterState: [],
    categoryFilterState: [],
    classificationFilterState: [],
    serviceFluidListState: [],
    isListLoading: false,
    eqQueryParameter: {
        queryParameters: {
            sortBy: 'equipmentId',
            isDesc: false
        },
        currentPage: 0,
        itemPerPage: '',
        totalItemCount: 0
    }
})

export function reducer(state = initialEquipmentState, action: EquipmentListAction): EquipmentTemplateMappingState {
    switch (action.type) {
        case ActionType.GetEquipmentList:
            return {
                ...state,
                isListLoading: true
            }
        case ActionType.GetEquipmentListSuccess:
            const payload = deepCopy(action.payload.result) as EquipmentTemplateMapping[]
            payload.forEach(eq => {
                eq.equipmentId = isNotAValue(eq?.equipmentId) ? '-' : eq?.equipmentId
                eq.equipmentTag = isNotAValue(eq?.equipmentTag) ? '-' : eq?.equipmentTag
                eq.techIdentificationNumber = isNotAValue(eq?.techIdentificationNumber) ? '-' : eq.techIdentificationNumber
                eq.functionalLocation = isNotAValue(eq?.functionalLocation) ? '-' : eq?.functionalLocation
                eq.description = isNotAValue(eq?.description) ? '-' : eq?.description
                eq.abcIndicator = isNotAValue(eq?.abcIndicator) ? '-' : eq?.abcIndicator
                eq.plannerGroup = isNotAValue(eq?.plannerGroup) ? '-' : eq?.plannerGroup
                eq.mainWorkCenter = isNotAValue(eq?.mainWorkCenter) ? '-' : eq?.mainWorkCenter
                eq.maintenancePlant = isNotAValue(eq?.maintenancePlant) ? '-' : eq?.maintenancePlant
                eq.location = isNotAValue(eq?.location) ? '-' : eq?.location
                eq.category = isNotAValue(eq?.category) ? '-' : eq?.category
                eq.classification = isNotAValue(eq?.classification) ? '-' : eq?.classification
            })

            // We do not want the text to replace the user's query parameter
            const querySummary = deepCopy(action.payload.querySummary)
            delete querySummary.queryParameters?.search

            if (action.payload.replace) {
                return {
                    ...adapter.setAll(payload, state),
                    isListLoading: false,
                    eqQueryParameter: querySummary
                }
            }

            return {
                ...adapter.addMany(payload, state),
                isListLoading: false,
                eqQueryParameter: querySummary
            }
        case ActionType.GetCriticalityListSuccess:
            const criticality = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                abcIndicatorFilterState: criticality
            }
        case ActionType.GetPlannerGroupListSuccess:
            const plannerGroup = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                plannerGroupFilterState: plannerGroup
            }
        case ActionType.GetMainWorkCenterListSuccess:
            const mainWorkCenter = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                mainWorkCenterFilterState: mainWorkCenter
            }
        case ActionType.GetMaintenancePlantListSuccess:
            const maintenancePlant = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                maintenancePlantFilterState: maintenancePlant
            }
        case ActionType.GetPlantSectionListSuccess:
            const plantSection = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                plantSectionFilterState: plantSection
            }
        case ActionType.GetLocationListSuccess:
            const location = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                locationFilterState: location
            }
        case ActionType.GetCategoryListSuccess:
            const category = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                categoryFilterState: category
            }
        case ActionType.GetServiceFluidListSuccess:
            return {
                ...state,
                serviceFluidListState: action.payload
            }
        case ActionType.GetClassificationListSuccess:
            const classification = action.payload
                .map(c => ({ name: c ?? '-', selected: false }))
            return {
                ...state,
                classificationFilterState: classification
            }
        case ActionType.GetEquipmentListFailure:
            return {
                ...state,
                isListLoading: false
            }
        case ActionType.CreateEquipmentSuccess:
            const newEquipmentTemplate: EquipmentTemplateMapping = {
                equipmentId: action.payload.equipmentId,
                equipmentTag: action.payload.equipmentTag,
                description: action.payload.description,
                abcIndicator: action.payload.abcIndicator,
                isActive: true,
                plantCode: action.payload.plantCode,
                templateType: [],
                isNew: true,
                note: '',
                techIdentificationNumber: '',
                functionalLocation: ''
            }
            return adapter.addOne(newEquipmentTemplate, state)
        case ActionType.UpdateTemplateToEquipmentListSuccess:
            const { equipmentId } = action.payload
            const newTemplate = {
                id: action.payload.id,
                typeId: action.payload.templateTypeId,
                name: action.payload.templateTypeName,
                numberOfPoint: action.payload.detail.numberOfPoint,
                isActive: action.payload.isActive
            } as EquipmentTemplateType

            const eqToUpdate = adapter.getSelectors()
                .selectAll(state)
                .find(eq => eq.equipmentId === equipmentId)

            if (!eqToUpdate) {
                return state
            }

            const updateEq = deepCopy(eqToUpdate) as EquipmentTemplateMapping
            updateEq.templateType = updateEq.templateType.filter(t => t.id !== action.payload.id)
            updateEq.templateType.push(newTemplate)
            return adapter.updateOne({ id: equipmentId, changes: updateEq }, state)
        case ActionType.RemoveTemplateFromEquipmentListSuccess:
            const _eqToUpdate = adapter.getSelectors()
                .selectAll(state)
                .find(eq => eq.equipmentId === action.equipmentId)
            if (!_eqToUpdate) {
                return state
            }
            const _updateEq = deepCopy(_eqToUpdate) as EquipmentTemplateMapping
            _updateEq.templateType = _updateEq.templateType.filter(t => t.id !== action.templateId)
            return adapter.updateOne({ id: action.equipmentId, changes: _updateEq }, state)
        case ActionType.TouchNewEquipment:
            return adapter.updateMany(action.payload.map(newEquipment => {
                return { id: newEquipment.equipmentId, changes: { ...newEquipment, isNew: false } }
            }), state)
        case ActionType.ApplyEquipmentListSort:
            return {
                ...state,
                sortedState: action.payload
            }
        case ActionType.ApplyEquipmentListTemplateFilter:
            return {
                ...state,
                templateFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListABCIndicatorFilter:
            return {
                ...state,
                abcIndicatorFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListPlannerGroupFilter:
            return {
                ...state,
                plannerGroupFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListMainWorkCenterFilter:
            return {
                ...state,
                mainWorkCenterFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListMaintenancePlantFilter:
            return {
                ...state,
                maintenancePlantFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListPlantSectionFilter:
            return {
                ...state,
                plantSectionFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListLocationFilter:
            return {
                ...state,
                locationFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListCategoryFilter:
            return {
                ...state,
                categoryFilterState: action.payload
            }
        case ActionType.ApplyEquipmentListClassificationFilter:
            return {
                ...state,
                classificationFilterState: action.payload
            }
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()
