import { Action } from '@ngrx/store'

import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { SapEquipmentQueryParameter } from '@app/modules/equipment/models/sap-equipment-query-parameter.model'
import { TestEquipment, TestEquipmentQueryParameter, TestEquipmentListItem } from '@app/modules/test-equipment/models/test-equipment.model'

export enum ActionType {
    CreateTestEquipment = '[Test Equipment] Create Test Equipment',
    CreateTestEquipmentSuccess = '[Test Equipment] Create Test Equipment Success',
    CreateTestEquipmentFailure = '[Test Equipment] Create Test Equipment Failure',
    FilterTestEquipment = '[Test Equipment] Filter Test Equipment',
    GetTestEquipment = '[Test Equipment] Get',
    GetTestEquipmentSuccess = '[Test Equipment] Get Success',
    GetTestEquipmentFailure = '[Test Equipment] Get Failure',
    UpdateTestEquipment = '[Test Equipment] Update Test Equipment',
    UpdateTestEquipmentSuccess = '[Test Equipment] Update Test Equipment Success',
    UpdateTestEquipmentFailure = '[Test Equipment] Update Test Equipment Failure',
    SearchNewTestEquipment = '[Test Equipment] Search New Test Equipment',
    SearchNewTestEquipmentSuccess = '[Test Equipment] Search New Test Equipment Success',
    TouchNewTestEquipment = '[Test Equipment] Touch New Test Equipment',
}

export class CreateTestEquipmentAction implements Action {
    readonly type = ActionType.CreateTestEquipment

    constructor(public payload: TestEquipment) { }
}

export class CreateTestEquipmentSuccessAction implements Action {
    readonly type = ActionType.CreateTestEquipmentSuccess

    constructor(public payload: TestEquipment) { }
}

export class CreateTestEquipmentFailureAction implements Action {
    readonly type = ActionType.CreateTestEquipmentFailure

    constructor(public payload: Error) { }
}

export class GetTestEquipmentAction implements Action {
    readonly type = ActionType.GetTestEquipment
}

export class GetTestEquipmentSuccessAction implements Action {
    readonly type = ActionType.GetTestEquipmentSuccess

    constructor(public payload: TestEquipment[]) { }
}

export class GetTestEquipmentFailureAction implements Action {
    readonly type = ActionType.GetTestEquipmentFailure

    constructor(public payload: Error) { }
}

export class UpdateTestEquipmentAction implements Action {
    readonly type = ActionType.UpdateTestEquipment

    constructor(public payload: TestEquipment) { }
}

export class UpdateTestEquipmentSuccessAction implements Action {
    readonly type = ActionType.UpdateTestEquipmentSuccess

    constructor(public payload: TestEquipment) { }
}

export class UpdateTestEquipmentFailureAction implements Action {
    readonly type = ActionType.UpdateTestEquipmentFailure

    constructor(public payload: Error) { }
}

export class FilterTestEquipmentAction implements Action {
    readonly type = ActionType.FilterTestEquipment

    constructor(public payload: TestEquipmentQueryParameter) { }
}

export class SearchNewTestEquipmentAction implements Action {
    readonly type = ActionType.SearchNewTestEquipment

    constructor(public payload: SapEquipmentQueryParameter) { }
}

export class SearchNewTestEquipmentSuccessAction implements Action {
    readonly type = ActionType.SearchNewTestEquipmentSuccess

    constructor(public payload: {
        querySummary: QuerySummary<SapEquipmentQueryParameter>,
        result: SapEquipment[]
    }) { }
}

export class TouchNewTestEquipmentAction implements Action {
    readonly type = ActionType.TouchNewTestEquipment

    constructor(public payload: TestEquipmentListItem[]) { }
}

export type TestEquipmentActionUnion = CreateTestEquipmentAction
    | CreateTestEquipmentSuccessAction
    | GetTestEquipmentAction
    | GetTestEquipmentSuccessAction
    | GetTestEquipmentFailureAction
    | UpdateTestEquipmentAction
    | UpdateTestEquipmentSuccessAction
    | UpdateTestEquipmentFailureAction
    | FilterTestEquipmentAction
    | SearchNewTestEquipmentAction
    | SearchNewTestEquipmentSuccessAction
    | TouchNewTestEquipmentAction
