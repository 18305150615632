import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { defaultReportQuery } from '@app/modules/report/constants/report-default-query.constant'
import { reportSortingState } from '@app/modules/report/constants/report-sorting-state.constant'
import { CalibrationReportDetails } from '@app/modules/report/models/calibration-report-details.model'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { ReportWorkOrderDetail } from '@app/modules/report/models/report-work-order-detail.model'
import { SortedState } from '@app/modules/shared/models/filter.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { deepCopy } from '@app/utils/app-utils.function'
import { ActionType, ReportAction } from './report.actions'
import { ReportFilterFields, defaultReportFilters, CalibrationPresets } from '@app/modules/report/constants/report-filter-list.constant'
import { apiDataMapPresetList, clearReportFilterList } from '@app/modules/report/utils/utils'
import { DateRange } from '@app/modules/report/models/date-range.model'
import { defaultDateRange } from '@app/modules/report/constants/report-default-date-range.constant'

export interface ReportState extends EntityState<CalibrationReportDetails> {
    isListLoading: boolean
    sortedState: SortedState[]
    workOrderId: string
    reportDetail: ReportWorkOrderDetail
    query: QuerySummary<ReportListQueryParameter>
    filterPresetList: CalibrationPresets[]
    reportFilterList: ReportFilterFields
    pmPerformedDate: DateRange
}

export const adapter: EntityAdapter<CalibrationReportDetails> = createEntityAdapter<CalibrationReportDetails>({
    selectId: report => report.id
})

export const initialState: ReportState = adapter.getInitialState({
    isListLoading: true,
    sortedState: reportSortingState,
    workOrderId: undefined,
    reportDetail: undefined,
    query: {
        currentPage: 1,
        itemPerPage: '40',
        queryParameters: deepCopy(defaultReportQuery)
    },
    filterPresetList: [],
    reportFilterList: deepCopy(defaultReportFilters),
    pmPerformedDate: defaultDateRange,
})

export function reducer(state = initialState, action: ReportAction): ReportState {
    switch (action.type) {
        case ActionType.GetReportList:
            return {
                ...state,
                isListLoading: true,
                query: action.payload
            }
        case ActionType.GetReportListSuccess:
            // Date gets converted to dateToLocalISOString when sent to BE
            // So when BE returns it back, it will be in dateToLocalISOString which date picker cannot read
            // This code at the bottom will retain the Date() format
            const query = deepCopy(action.payload.querySummary)
            query.queryParameters.dateRange = state.query.queryParameters.dateRange

            if (action.payload.replace) {
                return adapter.setAll(action.payload.result, {
                    ...state,
                    isListLoading: false,
                    query
                })
            }

            return adapter.addMany(action.payload.result, {
                ...state,
                isListLoading: false,
                query
            })
        case ActionType.GetReportListFailure:
            return {
                ...state,
                isListLoading: false
            }
        case ActionType.GetReportDetail:
            return {
                ...state,
                workOrderId: undefined,
                reportDetail: undefined
            }
        case ActionType.GetReportDetailSuccess:
            return {
                ...state,
                reportDetail: action.payload,
                workOrderId: action.payload.workOrderNumber
            }
        case ActionType.ApplySearch:
            return {
                ...state,
                query: {
                    queryParameters: {
                        search: action.payload
                    },
                    currentPage: state.query.currentPage,
                    itemPerPage: state.query.itemPerPage
                }
            }
        case ActionType.GetFilterPresetListSuccess:
            return {
                ...state,
                filterPresetList: apiDataMapPresetList(action.payload, state.reportFilterList)
            }
        case ActionType.AddFilterPresetSuccess:
            return {
                ...state,
                filterPresetList: [...state.filterPresetList, action.payload].map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            selected: true
                        }
                    }
                    return {
                        ...item,
                        selected: false
                    }
                })
            }
        case ActionType.EditFilterPresetSuccess:
            return {
                ...state,
                filterPresetList: state.filterPresetList.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            ...action.payload
                        }

                    }
                    return item
                })
            }
        case ActionType.DeleteFilterPresetSuccess:
            return {
                ...state,
                filterPresetList: state.filterPresetList.filter(item => item.id !== action.payload.id)
            }
        case ActionType.SaveReportFilterList:
            return {
                ...state,
                reportFilterList: action.payload
            }
        case ActionType.SelectedFilterPresetOption:
            return {
                ...state,
                filterPresetList: state.filterPresetList.map((item, index) => {
                    if (index === action.payload) {
                        return {
                            ...item,
                            selected: true
                        }
                    }
                    return {
                        ...item,
                        selected: false
                    }
                })
            }
        case ActionType.DeselectAllFilters:
            return {
                ...state,
                filterPresetList: state.filterPresetList.map(item => {
                    return {
                        ...item,
                        selected: false
                    }
                }),
                reportFilterList: clearReportFilterList(state.reportFilterList)
            }
        case ActionType.ModifyPmPerformedDate:
            return {
                ...state,
                pmPerformedDate: action.payload
            }
        case ActionType.SaveTemplateTypeFilterOptions:
            return {
                ...state,
                reportFilterList: {
                    ...state.reportFilterList,
                    templateTypes: {
                        name: state.reportFilterList.templateTypes.name,
                        value: action.payload
                    }
                }
            }
        case ActionType.SavePmPerformedByFilterOptions:
            return {
                ...state,
                reportFilterList: {
                    ...state.reportFilterList,
                    pmPerformedBy: {
                        name: state.reportFilterList.pmPerformedBy.name,
                        value: action.payload
                    }
                }
            }
        case ActionType.SaveWorkOrderTypesFilterOptions:
            return {
                ...state,
                reportFilterList: {
                    ...state.reportFilterList,
                    workOrderType: {
                        name: state.reportFilterList.workOrderType.name,
                        value: action.payload
                    }
                }
            }
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors()
