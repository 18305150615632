import { Store } from '@ngrx/store'

import { UploadQueue } from '@app/modules/shared/models/upload-queue.model'
import { AppState } from '@app/store/app.store'
import { LoadCalibrationSuccessAction, RemoveCachedCalibrationAction, ReSyncCalibrationAction } from '@app/store/calibration/calibration.actions'
import { deepCopy, isEmptyObject } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'

export class CalibrationCacheManager {
    public static addOrUpdateCalibration(competingCalibration: CalibrationDetails, cacheCalibrations: CalibrationDetails[]): CalibrationDetails[] {
        const calibrations = deepCopy(cacheCalibrations)
        const cIndex = calibrations.findIndex(i =>
            i.workOrderNumber === competingCalibration.workOrderNumber &&
            i.equipmentId === competingCalibration.equipmentId
        )

        const validCalibrationObj = !(!competingCalibration || isEmptyObject(competingCalibration))
        if (cIndex >= 0 && calibrations.length > 0) {
            calibrations[cIndex] = competingCalibration
        } else if (validCalibrationObj) {
            calibrations.push(competingCalibration)
        }

        return calibrations
    }

    public static loadCalibrationSuccess(action: LoadCalibrationSuccessAction, cacheCalibrations: CalibrationDetails[]): CalibrationDetails {
        if (action.forceOverride) {
            return action.payload
        }

        const latestCalibration = cacheCalibrations.find(c =>
            c.workOrderNumber === action.payload.workOrderNumber &&
            c.equipmentId === action.payload.equipmentId
        )

        if (latestCalibration && !navigator.onLine) {
            return latestCalibration
        }

        return action.payload
    }

    public static returnCurrentCacheCalibration(
        action: ReSyncCalibrationAction,
        cachedPMs: CalibrationDetails[],
        uploadQueue: UploadQueue[]
    ): CalibrationDetails {
        let currentPM: CalibrationDetails
        const { workOrderNumber, equipmentId } = action
        const isMultipleCalibrationSync = !workOrderNumber || !equipmentId

        const queuePMs = cachedPMs.filter(pm =>
            uploadQueue.some(q =>
                q.workOrderNumber === pm.workOrderNumber &&
                q.equipmentId === pm.equipmentId))

        if (isMultipleCalibrationSync) {
            currentPM = queuePMs[0]
        } else {
            currentPM = queuePMs.find(c => c.workOrderNumber === workOrderNumber && c.equipmentId === equipmentId)
        }

        return currentPM
    }

    public static removeUnwantedCache(
        store: Store<AppState>,
        cachedPMs: CalibrationDetails[],
        uploadQueue: UploadQueue[]
    ): void {
        const cachePMToBeRemove = cachedPMs.filter(pm =>
            !uploadQueue.some(q =>
                q.workOrderNumber === pm.workOrderNumber &&
                q.equipmentId === pm.equipmentId))

        cachePMToBeRemove.forEach(pm =>
            store.dispatch(new RemoveCachedCalibrationAction(pm.workOrderNumber, pm.equipmentId))
        )
    }
}
