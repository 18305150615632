import { CalibrationStatus } from '@app/modules/calibration/models/calibration-status.model'

export class LabelStatus extends CalibrationStatus {
    classLabel: string
    classResponsive: string
}

export const mrmaStatus = {
    calibrationStatus: [
        {
            id: 0,
            name: 'Not Started',
            classLabel: 'undefined',
            classResponsive: ''
        },
        {
            id: 1,
            name: 'Draft',
            classLabel: 'label label-warning',
            classResponsive: 'status-text-warning'
        },
        {
            id: 2,
            name: 'Pending SAP Notification Close',
            classLabel: 'label label-info',
            classResponsive: ''
        },
        {
            id: 3,
            name: 'Completed',
            classLabel: 'label label-success',
            classResponsive: 'status-text-success'
        }
    ],
    reviewStatus: [
        {
            id: 1,
            name: 'Not Ready',
            classLabel: '',
            classResponsive: ''
        },
        {
            id: 2,
            name: 'Ready for Review',
            classLabel: 'label label-warning',
            classResponsive: 'status-text-warning'
        },
        {
            id: 3,
            name: 'Reviewed',
            classLabel: 'label label-success',
            classResponsive: 'status-text-success'
        },
        {
            id: 4,
            name: 'Rejected',
            classLabel: 'label label-error',
            classResponsive: 'status-text-error'
        },
        {
            id: 5,
            name: '-',
            classLabel: 'center',
            classResponsive: ''
        }
    ],
    resultStatus: {
        none: {
            id: -1,
            name: 'None',
            classLabel: '',
            classResponsive: ''
        },
        passed: {
            id: 1,
            name: 'Passed',
            classLabel: 'label label-success',
            classResponsive: 'status-text-success'
        },
        failed: {
            id: 2,
            name: 'Failed',
            classLabel: 'label label-error',
            classResponsive: 'status-text-error'
        },
        failedAdjustedPassed: {
            id: 3,
            name: 'Failed Adjusted Passed',
            classLabel: 'label label-warning',
            classResponsive: 'status-text-warning'
        },
        deferred: {
            id: 4,
            name: 'Deferred',
            classLabel: 'label label-error',
            classResponsive: 'status-text-error'
        },
        notTested: {
            id: 5,
            name: 'Not Tested',
            classLabel: 'label label-error',
            classResponsive: 'status-text-error'
        },
        passedAdjustedPassed: {
            id: 6,
            name: 'Passed Adjusted Passed',
            classLabel: 'label label-success',
            classResponsive: 'status-text-success'
        }
    }
} as {
    calibrationStatus: LabelStatus[],
    reviewStatus: LabelStatus[],
    resultStatus: {
        none: LabelStatus,
        passed: LabelStatus,
        passedAdjustedPassed: LabelStatus,
        failed: LabelStatus,
        failedAdjustedPassed: LabelStatus,
        deferred: LabelStatus,
        notTested: LabelStatus
    }
}
