import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { EquipmentSortStackService } from '@app/modules/shared/services/equipment-sort-stack.service'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { SearchService } from '@app/modules/work-order-search/services/search.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { ActionType, SearchAction, SearchSuccessAction } from './search.actions'
import { SearchType } from './search.reducer'

@Injectable()
export class SearchEffects {

    
    public searchWorkOrder: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.Search),
        switchMap((action: SearchAction) => {
            const strategy = new ResponseHandlingStrategyBuilder()
                .useRethrowError()
                .useShowToastrOnError(this.toastr)
                .useHandle404([])
                .useShowSectionLoader(this.store, SectionLoaderEnum.List)
                .responseStrategy

            let apiCall: () => any
            if (action.payload.searchType === SearchType.WO) {
                apiCall = () => this.searchService.searchWorkOrdersByNumbers(action.payload.query)
            }

            if (action.payload.searchType === SearchType.EQ) {
                apiCall = () => this.searchService.searchWorkOrdersByEquipmentTags(action.payload.query)
            }
            return this.responseHandlerService.query<WorkOrderListItem[]>(apiCall, strategy)
                .pipe(
                    map(workOrderDetails => {
                        this.equipmentSortStackService.applyMainEquipmentToWorkOrders(workOrderDetails)
                        return new SearchSuccessAction(workOrderDetails)
                    }),
                    catchError(_ => of(new SearchSuccessAction([])))
                )
        })
    ))

    constructor(
        private actions$: Actions,
        private responseHandlerService: ResponseHandlerService,
        private store: Store<AppState>,
        private searchService: SearchService,
        private toastr: ToastrService,
        private equipmentSortStackService: EquipmentSortStackService
    ) { }
}
