import { CustomForm } from '@app/modules/calibration/components/custom-form/models/custom-form.model'

export enum ActionType {
    GetTemplates = '[CustomForm] Get Template',
    GetTemplatesSuccess = '[CustomForm] Get Templates Success',
}

export class GetCustomFormTemplatesAction {
    readonly type = ActionType.GetTemplates
}

export class GetCustomFormTemplatesSuccessAction {
    readonly type = ActionType.GetTemplatesSuccess
    constructor(public payload: CustomForm[]) { }
}

export type CustomFormAction = GetCustomFormTemplatesAction
    | GetCustomFormTemplatesSuccessAction
