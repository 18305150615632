import { TemplateTypeAction, ActionType } from '../actions/template-type.actions'
import { TemplateType } from '@app/modules/equipment/models/template-type.model'

export interface TemplateTypeState {
    templateTypes: TemplateType[]
}

export const initialState: TemplateTypeState = {
    templateTypes: []
}

export function reducer(state = initialState, action: TemplateTypeAction): TemplateTypeState {
    switch (action.type) {
        case ActionType.GetTemplateTypesSuccess:
            return {
                ...state,
                templateTypes: action.payload
            }
        default:
            return state
    }
}
