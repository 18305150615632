import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { WorkOrderEquipment } from '@app/modules/calibration/models/work-order-equipment.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { OfflineService } from '@app/services/offline.service'
import { selectAutoDownloadEnabled } from '@app/store/offline/offline.selectors'
import { GetToDoAction } from '@app/store/to-do/to-do.actions'
import { GetAllUserAction } from '@app/store/user/user.actions'
import { environment } from '@environments/environment'
import { AppState } from '../app.store'
import {
    ActionType, CacheEqCylinderHistory, CacheUserEqCalibrationsItems, CacheUserEqTemplateItems, CacheUserFlamingoFormMappingItems,
    CacheUserFlamingoFormSchemaItems, CacheUserNotificationItems, CacheUserOfflineItems, CacheUserTodoItemsFailure, CacheUserTodoItemsSuccess,
    CacheUserWoItems, ClearOfflineCache, InitAPICaching, LoadAutoDownloadEnabledSuccessAction, UpdateAutoDownloadEnabledAction,
    UpdateEquipmentCalibrationCacheStatusAction, UpdateEquipmentFlamingoCacheStatusAction, UpdateEquipmentTemplateCacheStatusAction
} from './offline.actions'

@Injectable()
export class OfflineEffects {

    
    public initAPICaching: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<InitAPICaching>(ActionType.InitAPICaching),
        withLatestFrom(this.store.select(selectAutoDownloadEnabled)),
        switchMap(([action, isAutoDownloadEnabled]) => {
            if (environment.serviceWorker && isAutoDownloadEnabled) {
                return [
                    new CacheUserOfflineItems(action.payload)
                ]
            }

            return of({ type: 'NO_ACTION' })
        })
    ))

    
    public cacheUserOfflineItemsEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserOfflineItems>(ActionType.CacheUserOfflineItems),
        exhaustMap((action) => {
            return this.offlineService.cacheWOListItems(action.payload)
                .pipe(
                    tap((woListDetails: WorkOrderDetails[]) => {
                        const equipmentIdList: string[] = []
                        const woEqList: WorkOrderEquipment[] = []

                        let flamingoFormIds: string[] = []
                        let flamingoEquipmentIds: string[] = []

                        woListDetails.forEach(
                            wo => {
                                if (wo.equipmentCalibrations[0].equipmentNotificationNumber) {
                                    const notificationNumbers = wo.equipmentCalibrations.map(equipment => equipment.equipmentNotificationNumber)
                                    this.store.dispatch(new CacheUserNotificationItems(notificationNumbers, wo.workOrderNumber))
                                } else {
                                    this.store.dispatch(new CacheUserNotificationItems([wo.notification.number], wo.workOrderNumber))
                                }

                                wo.equipmentCalibrations.forEach(eq => {
                                    if (eq.isTemplateMapped) {
                                        equipmentIdList.push(eq.equipmentId)
                                    }

                                    if (eq.status) {
                                        woEqList.push({
                                            workOrderNumber: wo.workOrderNumber,
                                            equipmentId: eq.equipmentId
                                        })
                                    }

                                    if (eq.flamingoFormIds.length > 0) {
                                        flamingoFormIds = flamingoFormIds.concat(eq.flamingoFormIds)
                                        flamingoEquipmentIds.push(eq.equipmentId)
                                    }
                                })
                            }
                        )

                        flamingoFormIds = [...new Set(flamingoFormIds)]
                        flamingoEquipmentIds = [...new Set(flamingoEquipmentIds)]

                        this.store.dispatch(new CacheUserWoItems(woListDetails))
                        this.store.dispatch(new CacheUserEqTemplateItems(equipmentIdList))
                        this.store.dispatch(new CacheUserEqCalibrationsItems(woEqList))
                        this.store.dispatch(new CacheUserFlamingoFormSchemaItems(flamingoFormIds))
                        this.store.dispatch(new CacheUserFlamingoFormMappingItems(flamingoEquipmentIds))
                        this.store.dispatch(new CacheEqCylinderHistory(equipmentIdList))
                    }),
                    map(() => new CacheUserTodoItemsSuccess()),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        })
    ))

    
    public cacheUserEqTemplateItemsEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserEqTemplateItems>(ActionType.CacheUserEqTemplateItems),
        exhaustMap((action) => {
            return this.offlineService.cacheEqTemplateItems(action.equipmentIdList)
                .pipe(
                    map((res) => new UpdateEquipmentTemplateCacheStatusAction({ eqTemplates: res })),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        })
    ))

    
    public cacheEqCylinderHistoryEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheEqCylinderHistory>(ActionType.CacheEqCylinderHistory),
        exhaustMap((action) => {
            return this.offlineService.cacheEqsCylinderHistory(action.equipmentIdList)
                .pipe(
                    map(() => new CacheUserTodoItemsSuccess()),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        })
    ))

    
    public cacheUserEqCalibrationsEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserEqCalibrationsItems>(ActionType.CacheUserEqCalibrationItems),
        exhaustMap((action) => {
            return this.offlineService.cacheEqCalibrationItems(action.woEq)
                .pipe(
                    map((res) => new UpdateEquipmentCalibrationCacheStatusAction({ eqCalibrations: res })),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        })
    ))

    
    public cacheUserNotificationsEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserNotificationItems>(ActionType.CacheUserNotificationItems),
        exhaustMap((action) => {
            return this.offlineService.cacheNotificationItems(action.notificationNumbers, action.workOrderNumber)
                .pipe(
                    map(() => new CacheUserTodoItemsSuccess()),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        })
    ))

    
    public cacheUserFlamingoFormMappingEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserFlamingoFormMappingItems>(ActionType.CacheUserFlamingoFormMappingItems),
        exhaustMap((action) =>
            this.offlineService.cacheFlamingoFormMappingItems(action.formIds)
                .pipe(
                    map(() => new CacheUserTodoItemsSuccess()),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        )
    ))

    
    public cacheUserFlamingoFormSchemaEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CacheUserFlamingoFormSchemaItems>(ActionType.CacheUserFlamingoFormSchemaItems),
        switchMap((action) =>
            this.offlineService.cacheFlamingoFormSchemaItems(action.formIds)
                .pipe(
                    map((res) => new UpdateEquipmentFlamingoCacheStatusAction({ eqFlamingos: res })),
                    catchError(() => of(new CacheUserTodoItemsFailure()))
                )
        )
    ))

    
    public getIsAutoDownloadEnabledEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetAutoDownloadEnabled),
        switchMap((action) =>
            this.offlineService.getIsAutoDownloadEnabled()
                .pipe(
                    map((res) => {
                        return new LoadAutoDownloadEnabledSuccessAction(res?.enable)
                    }),
                )
        )
    ))

    
    public updateIsAutoDownloadEnabledEffect = createEffect(() => this.actions$.pipe(
        ofType<UpdateAutoDownloadEnabledAction>(ActionType.UpdateAutoDownloadEnabled),
        switchMap((action) =>
            this.offlineService.setIsAutoDownloadEnabled(action.isAutoDownloadEnabled)
                .pipe(
                    map((_) => {
                        if (action.isAutoDownloadEnabled) {
                            this.store.dispatch(new GetToDoAction())
                            this.store.dispatch(new GetAllUserAction())
                        } else {
                            this.store.dispatch(new ClearOfflineCache())
                        }
                        return new LoadAutoDownloadEnabledSuccessAction(action.isAutoDownloadEnabled)
                    }),
                )
        )
    ))



    constructor(
        private actions$: Actions,
        private offlineService: OfflineService,
        private store: Store<AppState>
    ) { }
}
