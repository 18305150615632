import { RouteName } from '@app/models/route-name.enum'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'

export enum ActionType {
    SetLoader = '[Loader] Set',
    RemoveLoader = '[Loader] Remove',
    AddSectionLoader = '[SectionLoader] Add',
    RemoveSectionLoader = '[SectionLoader] Remove',
    SetProgressBar = '[ProgressBar] Set progress bar',
    AppHasFinishedInitializing = '[ProgressBar] App has finished initializing'
}

export class SetLoaderAction {
    readonly type = ActionType.SetLoader
}

export class RemoveLoaderAction {
    readonly type = ActionType.RemoveLoader
}

export class AddSectionLoaderAction {
    readonly type = ActionType.AddSectionLoader
    constructor(public sectionName: SectionLoaderEnum) { }
}

export class RemoveSectionLoaderAction {
    readonly type = ActionType.RemoveSectionLoader
    constructor(public sectionName: SectionLoaderEnum) { }
}

export class SetProgressBarLoaderAction {
    readonly type = ActionType.SetProgressBar
    constructor(
        public percentageComplete: number,
        public visibleInRoute: RouteName[]
    ) { }
}

export class AppHasFinishedInitializingAction {
    readonly type = ActionType.AppHasFinishedInitializing
}

export type LoaderAction = SetLoaderAction
    | RemoveLoaderAction
    | AddSectionLoaderAction
    | RemoveSectionLoaderAction
    | SetProgressBarLoaderAction
    | AppHasFinishedInitializingAction
