import { UserProfile, User } from '@app/models/user.model'
import { Action } from '@ngrx/store'

export enum ActionType {
    GetCurrentUser = '[User] Get Current User',
    GetCurrentUserSuccess = '[User] Get Current User Success',
    GetCurrentUserFailure = '[User] Get Current User Failure',
    UpdateUser = '[User] Update',
    UpdateUserFailure = '[User] Update Failure',
}

export class GetCurrentUserAction {
    readonly type = ActionType.GetCurrentUser
}

export class GetCurrentUserSuccessAction {
    readonly type = ActionType.GetCurrentUserSuccess

    constructor(public payload: UserProfile) { }
}

export class UpdateUserAction implements Action {
    readonly type = ActionType.UpdateUser

    constructor(public payload: User) { }
}

export class UpdateUserFailureAction implements Action {
    readonly type = ActionType.UpdateUserFailure
}

export class GetCurrentUserFailureAction {
    readonly type = ActionType.GetCurrentUserFailure
}

export type IdentityAction = GetCurrentUserAction
    | GetCurrentUserSuccessAction
    | GetCurrentUserFailureAction
    | UpdateUserAction
    | UpdateUserFailureAction
