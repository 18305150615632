import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MsalInterceptor } from '@azure/msal-angular'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'

@Injectable()
export class OauthInterceptor implements HttpInterceptor {
    private isOnline = true

    constructor(
        private msalInterceptor: MsalInterceptor,
        private store: Store<AppState>
    ) {
        this.store.select(isOnline).subscribe(online => {
            this.isOnline = online
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isOnline) {
            return this.msalInterceptor.intercept(req, next)
        }

        return next.handle(req)
    }
}
