import { OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { ElementRef, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Subscription, timer } from 'rxjs'

import { AppState } from '@app/store/app.store'
import { sectionLoadersSelector } from '@app/store/loader/loader.selectors'
import { SectionLoaderComponent } from '../components/section-loader/section-loader.component'
import { SectionLoaderEnum } from '../models/section-loader.enum'
import { DynamicOverlay } from './dynamic-overlay.service'

class SectionLoader {
    elementRef: ElementRef
    progressRef: ProgressRef
}

interface ISectionLoaderMapping {
    [sectionName: string]: SectionLoader[]
}

@Injectable({
    providedIn: 'root'
})
export class SectionLoaderService {
    private sectionLoaderMappings: ISectionLoaderMapping = {}
    private activeSectionNames: string[]

    constructor(private dynamicOverlay: DynamicOverlay, private store: Store<AppState>) {
        this.store.pipe(select(sectionLoadersSelector)).subscribe(sectionLoaders => {
            const castedSectionLoaders = sectionLoaders.map(section => section.toString())
            this.activeSectionNames = castedSectionLoaders
            this.updateSectionLoader()
        })
    }

    public registerSectionLoader(sectionName: SectionLoaderEnum, inputElementRef: ElementRef): void {
        if (!this.sectionLoaderMappings[sectionName]) {
            this.sectionLoaderMappings[sectionName] = []
        }
        this.sectionLoaderMappings[sectionName].push({ elementRef: inputElementRef, progressRef: undefined })
        this.updateSectionLoader()
    }

    public unregisterSectionLoader(sectionName: SectionLoaderEnum, inputElementRef: ElementRef): void {
        let existingSectionLoaders = this.sectionLoaderMappings[sectionName]
        if (existingSectionLoaders) {
            const targetSectionLoader = existingSectionLoaders.find(sectionLoader => {
                return sectionLoader.elementRef === inputElementRef
            })
            if (targetSectionLoader) {
                if (targetSectionLoader.progressRef) {
                    this.detach(targetSectionLoader.progressRef)
                }
                existingSectionLoaders = existingSectionLoaders.filter(sectionLoader => {
                    return sectionLoader !== targetSectionLoader
                })
            }
        }
        this.updateSectionLoader()
    }

    public isSectionLoading(sectionName: SectionLoaderEnum, inputElementRef: ElementRef): boolean {
        const existingSectionLoaders = this.sectionLoaderMappings[sectionName]
        if (existingSectionLoaders) {
            const targetSectionLoader = existingSectionLoaders.find(sectionLoader => {
                return sectionLoader.elementRef === inputElementRef
            })
            if (targetSectionLoader) {
                if (targetSectionLoader.progressRef) {
                    return true
                }
            }
        }

        return false
    }

    private updateSectionLoader(): void {
        for (const key in this.sectionLoaderMappings) {
            if (this.activeSectionNames.indexOf(key) === -1) {
                const existingSectionLoaders = this.sectionLoaderMappings[key]
                existingSectionLoaders.forEach((sectionLoader: SectionLoader) => {
                    if (sectionLoader.progressRef) {
                        this.detach(sectionLoader.progressRef)
                        sectionLoader.progressRef = undefined
                    }
                })
            } else {
                const existingSectionLoaders = this.sectionLoaderMappings[key]
                existingSectionLoaders.forEach((sectionLoader: SectionLoader) => {
                    if (!sectionLoader.progressRef) {
                        sectionLoader.progressRef = this.showProgress(sectionLoader.elementRef)
                    }
                })
            }
        }
    }

    private showProgress(elRef: ElementRef): ProgressRef {
        if (!elRef) {
            return
        }
        const result: ProgressRef = { subscription: null, overlayRef: null }
        result.subscription = timer(500)
            .subscribe(() => {
                this.dynamicOverlay.setContainerElement(elRef.nativeElement)
                result.overlayRef = this.dynamicOverlay.create()
                result.overlayRef.attach(new ComponentPortal(SectionLoaderComponent))
            })
        return result
    }

    private detach(result: ProgressRef): void {
        if (result) {
            result.subscription.unsubscribe()
            if (result.overlayRef) {
                result.overlayRef.detach()
            }
        }
    }
}

export declare interface ProgressRef { subscription: Subscription, overlayRef: OverlayRef }
