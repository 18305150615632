import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { PMStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { ReviewStatusEnum } from '@app/modules/calibration/models/review-status.enum'
import {
    equipmentABCIndicatorFilterOptions,
    equipmentCategoryFilterOptions,
    equipmentClassificationFilterOptions
} from '@app/store/equipment/selectors/equipment-list.selectors'
import { configuration as processConfigurations } from '@app/store/equipment/selectors/process.selectors'
import { techniciansInCurrentPlant } from '@app/store/user/user.selectors'
import {
    workOrderLocation,
    workOrderMaintenancePlant,
    workOrderMainWorkCenter,
    workOrderPlannerGroup,
    workOrderPlantSection
} from '@app/store/work-order/work-order.selectors'

export enum DateRangeType {
    LACD = 'LACD',
    BasicFinishDate = 'BasicFinishDate',
    PMPerformedDate = 'PMPerformedDate'
}

export const dateRangeRadioOptions = [
    { name: 'LACD', value: DateRangeType.LACD },
    { name: 'Basic Finish Date', value: DateRangeType.BasicFinishDate },
    { name: 'PM Performed Date', value: DateRangeType.PMPerformedDate }
]

export const reportFilterConfig = [
    {
        type: 'checklist',
        detail: {
            name: 'Deadline', fieldName: 'deadline', options: [
                // these id are only for checklist rendering
                { name: 'On-time', value: 'OnTime' },
                { name: 'Overdue', value: 'Overdue' }
            ]
        }
    },
    {
        type: 'autocomplete',
        detail: {
            name: 'Performed By', fieldName: 'performedBy', mappedFieldName: 'guid',
            displayedFieldName: 'displayName', selector: techniciansInCurrentPlant
        }
    },
    {
        type: 'checklist',
        detail: {
            name: 'PM Status', fieldName: 'pmStatus', options: [
                { name: 'Open', value: PMStatusEnum.Open },
                { name: 'Draft', value: PMStatusEnum.Draft },
                { name: 'Completed', value: PMStatusEnum.Completed }
            ]
        }
    },
    {
        type: 'checklist',
        detail: {
            name: 'PM Result', fieldName: 'pmResult', options: [
                { name: 'Failed', value: CalibrationResultStatusEnum.Failed },
                { name: 'Failed-Adjusted-Passed', value: CalibrationResultStatusEnum.FailedAdjustedPassed },
                { name: 'Deferred', value: CalibrationResultStatusEnum.Deferred },
                { name: 'Not Tested', value: CalibrationResultStatusEnum.NotTested },
                { name: 'Passed', value: CalibrationResultStatusEnum.Passed },
                { name: 'Passed-Adjusted-Passed', value: CalibrationResultStatusEnum.PassedAdjustedPassed }
            ]
        }
    },
    {
        type: 'checklist',
        detail: {
            name: 'Review Status', fieldName: 'reviewStatus', options: [
                { name: 'Reviewed', value: ReviewStatusEnum.Reviewed },
                { name: 'Rejected', value: ReviewStatusEnum.Rejected },
                { name: 'Ready for review', value: ReviewStatusEnum.Ready },
                { name: 'Not ready', value: ReviewStatusEnum.NotReady }
            ]
        }
    },

    {
        type: 'hr'
    },

    ...([
        { name: 'Planner Group', fieldName: 'plannerGroup', selector: workOrderPlannerGroup },
        { name: 'Main Work Center', fieldName: 'mainWorkCenter', selector: workOrderMainWorkCenter },
        { name: 'Maintenance Plant', fieldName: 'maintenancePlant', selector: workOrderMaintenancePlant },
        { name: 'Plant Section', fieldName: 'plantSection', selector: workOrderPlantSection },
        { name: 'Location', fieldName: 'location', selector: workOrderLocation }
    ]).map(filterDetail => ({ type: 'autocomplete', detail: { displayedFieldName: 'name', mappedFieldName: 'name', ...filterDetail } })),

    {
        type: 'hr'
    },

    ...([
        { name: 'Process', fieldName: 'process', selector: processConfigurations, displayedFieldName: 'processName', mappedFieldName: 'processId' },
        {
            name: 'EQ Criticality',
            fieldName: 'eqCriticality',
            selector: equipmentABCIndicatorFilterOptions,
            displayedFieldName: 'name',
            mappedFieldName: 'name'
        },
        {
            name: 'EQ Classification',
            fieldName: 'eqClassification',
            selector: equipmentClassificationFilterOptions,
            displayedFieldName: 'name',
            mappedFieldName: 'name'
        },
        {
            name: 'EQ Category',
            fieldName: 'eqCategory',
            selector: equipmentCategoryFilterOptions,
            displayedFieldName: 'name',
            mappedFieldName: 'name'
        }
    ]).map(filterDetail => ({ type: 'autocomplete', detail: { displayedFieldName: 'name', mappedFieldName: 'name', ...filterDetail } })),

    {
        type: 'hr'
    }
]

export const maxDaysLimit = 190
export const errorTip = 'Maximum date range allowed is 6-month.'
