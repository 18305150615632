import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { CalibrationCacheManager } from '@app/modules/calibration/services/calibration-cache-manager.service'
import { deepCopy } from '@app/utils/app-utils.function'
import { ActionType, CalibrationAction } from './calibration.actions'
import { HyperlinkInfo, HyperlinkState } from '@app/modules/calibration/models/hyperlink.model'

export interface CalibrationState {
    currentCalibration: CalibrationDetails
    cacheCalibrations: CalibrationDetails[]
    isReopenNotificationSuccess: boolean,
    hyperlinkState: HyperlinkState
}

const initialState: CalibrationState = {
    currentCalibration: undefined,
    cacheCalibrations: [],
    isReopenNotificationSuccess: undefined,
    hyperlinkState: {
        hasError: false
    }
}

export function reducer(state = initialState, action: CalibrationAction): CalibrationState {
    switch (action.type) {
        case ActionType.GetCalibrationDetails:
            return {
                ...state,
                currentCalibration: state.currentCalibration
            }
        case ActionType.LoadCalibrationSuccess:
            return {
                ...state,
                currentCalibration: CalibrationCacheManager.loadCalibrationSuccess(action, state.cacheCalibrations),
            }
        case ActionType.CreateCalibrationDetails:
            return {
                ...state,
                currentCalibration: action.calibrationDetails,
                cacheCalibrations: CalibrationCacheManager.addOrUpdateCalibration(action.calibrationDetails, state.cacheCalibrations)
            }
        case ActionType.UpdateCalibrationDetails:
            return {
                ...state,
                currentCalibration: action.calibrationDetails,
                cacheCalibrations: CalibrationCacheManager.addOrUpdateCalibration(action.calibrationDetails, state.cacheCalibrations)
            }
        case ActionType.UpdateCalibrationFailure:
            const calibration = deepCopy(action.calibrationDetail) as CalibrationDetails
            if (action.statusCode !== 0) {
                calibration.calibrationStatus.id = CalibrationStatusEnum.Draft
            }

            return {
                ...state,
                currentCalibration: calibration,
                cacheCalibrations: CalibrationCacheManager.addOrUpdateCalibration(calibration, state.cacheCalibrations)
            }
        case ActionType.RemoveCachedCalibration:
            return {
                ...state,
                cacheCalibrations: state.cacheCalibrations.filter(c =>
                    !(c.workOrderNumber === action.workOrderNumber && c.equipmentId === action.equipmentId)
                )
            }
        case ActionType.UpdateCurrentCalibrationToStore:
            return {
                ...state,
                currentCalibration: action.calibrationDetails
            }
        case ActionType.ReopenNotification:
            return {
                ...state,
                isReopenNotificationSuccess: undefined
            }
        case ActionType.ReopenNotificationResult:
            return {
                ...state,
                isReopenNotificationSuccess: action.isReopenNotificationSuccess
            }
        case ActionType.GetHyperlinkInfoSuccess:
            return {
                ...state,
                hyperlinkState: action.hyperlinkState
            }
        case ActionType.GetHyperlinkInfoFailed:
            return {
                ...state,
                hyperlinkState: { hasError: true, equipmentId: action.equipmentId }
            }
        default:
            return state
    }
}
