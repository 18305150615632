<div [ngClass]="{'loading': isLoading}"
     class="root-app-wrapper">
    <section class="mrma__flex-wrapper">
        <div class="spinner"
             *ngIf="isLoading || !isAccountCheckedOut || !isHomeComponentRendered"
             [@zoomInOut]>
            <div class="spinner-inner">
                <img *ngIf="isHomeComponentRendered || !isAccountCheckedOut"
                     src="./assets/unity-1.2.0/images/icon-spinner.svg"
                     alt="Loading" />
                <ng-container *ngIf="!isAccountCheckedOut; else loadingHomeComponentMessage">
                    <p class="loading-message">Authenticating...</p>
                </ng-container>
                <ng-template #loadingHomeComponentMessage>
                    <ng-container *ngIf="!isHomeComponentRendered">
                        <img [attr.src]="loaderImgSrc"
                             class="drawing-loader"
                             alt="Loading" />
                        <p class="loading-message">Getting everything ready...</p>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <router-outlet></router-outlet>
    </section>
</div>
<div class="browser-not-supported-overlay"
     *ngIf="isBrowserNotSupported">
    <div class="overlay-message">
        <h1>
            Unfortunately, your browser is not supported.
        </h1>
        <div class="em-u-margin-top">
            To access MRMA, please use Google Chrome.
        </div>
        <br />
        <div>
            To install Chrome, please follow instructions from this
            <button class="em-c-btn em-js-btn-selectable"
                    (click)="navigateToChromeInstallation()">
                link.
            </button>
        </div>
        <img src="./assets/images/chrome.svg"
             class="em-u-margin-top" />
    </div>
</div>
<app-modal-container></app-modal-container>
