import { Component, ComponentRef, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Store } from '@ngrx/store'
import { isFunction } from 'lodash'
import { Observable } from 'rxjs'

import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { isLoading } from '@app/store/loader/loader.selectors'
import { ModalState } from '@app/store/modal/modal.reducer'
import { modal, modalVisibility } from '@app/store/modal/modal.selectors'
import { safeCallback } from '@app/utils/app-utils.function'
import { DestroyModalAction, HideModalAction } from '@app/store/modal/modal.actions'
import { EquipmentExternalLinkModalComponent } from '../shared/components/equipment-external-link-container/equipment-external-link-modal.component'
import { SectionLoaderHelperComponent } from '../shared/components/section-loader/section-loader-helper-component'
import { SectionLoaderEnum } from '../shared/models/section-loader.enum'
import { SectionLoaderService } from '../shared/services/section-loader.service'
import { ModalContainerService } from './services/modal-container.service'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@Component({
    selector: 'app-modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class ModalContainerComponent extends SectionLoaderHelperComponent implements OnInit {
    @ViewChild('modalContainer', {
        read: ViewContainerRef
    }) entry: ViewContainerRef

    public modal: Modal
    public visibility: boolean
    public isLoading$: Observable<boolean>
    public isOnMobileOrTabletDevice: boolean
    public genericType = ModalType.Generic

    private componentRef: ComponentRef<any>
    private innerWindowHeight = window.innerHeight
    private innerWindowWidth = window.innerWidth

    constructor(
        private store: Store<ModalState>,
        private screenSizeService: ScreenSizeService,
        private modalContainerService: ModalContainerService,
        sectionLoaderService: SectionLoaderService
    ) {

        super(sectionLoaderService)
        this.modalContainerService.registerComponent(EquipmentExternalLinkModalComponent, ModalType.ExternalLinkOpen)
    }

    @ViewChild('modalLowerReference') set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.ModalBody, ref)
        }
    }

    ngOnInit(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()
        this.isLoading$ = this.store.select(isLoading)

        this.addSubscription(
            this.store.select(modal).subscribe((modalState: Modal) => {
                if (modalState) {
                    this.modal = modalState
                    this.createComponent(modalState.type)
                } else {
                    this.initModalDefaultValue()
                }
            })
        )

        this.addSubscription(
            this.store.select(modalVisibility).subscribe(visibility => this.visibility = visibility)
        )
    }

    @HostListener('window:resize')
    onResize(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()

        // to avoid closing modal when virtual keyboard appears on mobile / tablet (it reduces screen height)
        const isOnlyHeightChanged = (this.innerWindowHeight !== window.innerHeight && this.innerWindowWidth === window.innerWidth)
        this.innerWindowHeight = window.innerHeight
        this.innerWindowWidth = window.innerWidth

        // Handle resizing edge case when modal is not a generic type
        if (!isOnlyHeightChanged && this.isOnMobileOrTabletDevice && this.modal.type !== ModalType.Generic) {
            this.destroyComponent()
        }
    }

    public confirmModal(): void {
        if (this.isValid()) {
            this.store.dispatch(new HideModalAction())
            safeCallback(this.modal.confirmCallback, this.componentRef?.instance?.confirmCallbackParams)
        }
    }
    public closeModal(): void {
        safeCallback(this.modal.closeCallback)
        this.destroyComponent()
    }

    public exitModal(): void {
        this.destroyComponent()
    }

    public isValid(): boolean {
        if (isFunction(this.modal.validator)) {
            return this.modal.validator()
        }

        return true
    }

    private createComponent(modalType: ModalType): void {
        this.entry.clear()

        if (modalType === ModalType.Generic) {
            return
        }

        const modalContent = this.modalContainerService.getComponent(modalType)
        this.componentRef = this.entry.createComponent(modalContent)
        if (this.modal.params) {
            this.componentRef.instance.params = this.modal.params
        }
    }

    private destroyComponent(): void {
        if (this.componentRef) {
            this.componentRef.destroy()
        }

        this.store.dispatch(new DestroyModalAction())
    }

    private initModalDefaultValue(): void {
        this.modal = {
            title: '',
            icon: 'circle-exclamation-mark',
            close: true,
            footer: true,
            type: null,
            closeCallback: () => { },
            confirmCallback: () => { }

        }
    }
}
