import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap } from 'rxjs/operators'

import { FlamingoAPIService } from '@app/modules/flamingo/services/flamingo-api.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import * as equipmentFlamingoFormActions from '../actions/equipment-flamingo-form.actions'

@Injectable()
export class EquipmentFlamingoFormEffects {

    getEquipmentFlamingoFormMappingEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(equipmentFlamingoFormActions.GetEquipmentFlamingoFormMappingAction),
            switchMap(({ equipmentId }) => {
                return this.responseHandlerService.query(() =>
                    this.flamingoApiService.getEquipmentFlamingoFormMapping(equipmentId),
                    this.sectionLoaderStrategy
                )
            }),
            map(response => {
                const equipmentFlamingoFormsMapping = response.body
                return equipmentFlamingoFormActions.GetEquipmentFlamingoFormMappingSuccessAction({
                    forms: equipmentFlamingoFormsMapping
                })
            })
        )
    )

    linkFlamingoFormToEquipmentEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(equipmentFlamingoFormActions.LinkFlamingoFormToEquipmentAction),
            concatMap(({ flamingoFormId, equipmentId }) => {
                return this.responseHandlerService.query(() =>
                    this.flamingoApiService.linkFlamingoFormToEquipment(flamingoFormId, equipmentId),
                    this.noLoaderStrategy
                ).pipe(
                    map(() => {
                        this.store.dispatch(equipmentFlamingoFormActions.LinkFlamingoFormToEquipmentSuccessAction())
                        // link form API returns no content. need to reload the data from get forms API for now
                        return equipmentFlamingoFormActions.GetEquipmentFlamingoFormMappingAction({ equipmentId })
                    }),
                    catchError(() => of(equipmentFlamingoFormActions.LinkFlamingoFormToEquipmentFailAction()))
                )
            })
        )
    )

    unlinkFlamingoFormToEquipmentEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(equipmentFlamingoFormActions.UnlinkFlamingoFormToEquipmentAction),
            concatMap(({ flamingoFormId, equipmentId }) => {
                return this.responseHandlerService.query(() =>
                    this.flamingoApiService.unlinkFlamingoFormFromEquipment(flamingoFormId, equipmentId),
                    this.noLoaderStrategy
                ).pipe(
                    map(() => {
                        this.store.dispatch(equipmentFlamingoFormActions.UnlinkFlamingoFormToEquipmentSuccessAction())
                        // unlink form API returns no content. need to reload the data from get forms API for now
                        return equipmentFlamingoFormActions.GetEquipmentFlamingoFormMappingAction({ equipmentId })
                    }),
                    catchError(() => of(equipmentFlamingoFormActions.UnlinkFlamingoFormToEquipmentFailAction()))
                )
            })
        )
    )

    private sectionLoaderStrategy: ResponseHandlingStrategy
    private noLoaderStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private flamingoApiService: FlamingoAPIService,
        private responseHandlerService: ResponseHandlerService,
        private toastrService: ToastrService,
    ) {
        this.sectionLoaderStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastrService)
            .useShowSectionLoader(this.store, SectionLoaderEnum.EquipmentAdminTemplate)
            .responseStrategy

        this.noLoaderStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastrService)
            .responseStrategy
    }

}
